import {
	Animated,
	Easing,
	Image,
	Text,
	TouchableOpacity,
	View,
} from 'react-native';
import React, {Component} from 'react';
import tw from '../tailwind';
import Close from '../../assets/icons/close.png';

export default class HeNotificationItem extends Component {
	title = 'HeNotificationList';
	message = 'HeNotificationList';
	type = 'success';
	duration = 10000;
	onClose = () => {};
	onClick = () => {};
	onTimeout = () => {};

	state = {
		yPosition: new Animated.Value(0),
		currentListIndex: 0,
	};

	constructor(props) {
		super(props);
		this.title = props.title || this.title;
		this.message = props.message || '';
		this.type = props.type || 'success';
		this.duration = props.duration || this.duration;
		this.onClose = props.onClose || (() => {});
		this.onClick = props.onClick || (() => {});
		this.onTimeout = props.onTimeout || (() => {});
		this.currentListIndex = props.currentListIndex;
	}

	componentDidMount() {
		setTimeout(() => {
			Animated.timing(this.state.yPosition, {
				toValue: 0,
				duration: 250,
				useNativeDriver: true,
				easing: Easing.inOut(Easing.ease),
			}).start(() => this.onTimeout());
		}, this.duration);

		Animated.timing(this.state.yPosition, {
			toValue: 1,
			duration: 250,
			useNativeDriver: true,
			easing: Easing.inOut(Easing.ease),
		}).start();
	}

	componentWillUnmount() {
		this.onClose();
	}

	getTypeColor() {
		switch (this.type) {
			case 'success':
				return '-green-500';
			case 'error':
				return '-red-500';
			case 'warning':
				return '-yellow-500';
			case 'info':
				return '-blue-500';
			default:
				return '-green-500';
		}
	}

	render() {
		return (
			<Animated.View
				style={[
					tw.style(
						'my-1 mx-6 py-3 px-5 bg-white shadow-2xl rounded-lg rounded-b-sm flex flex-row justify-between border-b-4 border' +
							this.getTypeColor()
					),
					{
						transform: [
							{
								translateY: this.state.yPosition.interpolate({
									inputRange: [0, 1],
									outputRange: [-125 * (this.props.currentListIndex + 1), 0],
								}),
							},
						],
					},
				]}
			>
				<View style={tw.style('')}>
					<Text style={tw.style('text-lg')}>{this.title}</Text>
					<Text style={tw.style('text-base')}>{this.message}</Text>
				</View>
				<TouchableOpacity onPress={this.onClose}>
					<Text style={tw.style('text-xl font-bold')}>X</Text>
				</TouchableOpacity>
			</Animated.View>
		);
	}
}
