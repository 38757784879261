import {
	View,
	Text,
	TouchableWithoutFeedback,
	Keyboard,
	TextInput,
	TouchableOpacity,
	Image,
	FlatList,
	Platform,
	ScrollView,
	KeyboardAvoidingView,
	ActivityIndicator,
} from 'react-native';
import React, {useEffect} from 'react';
import tw from '../lib/tailwind';
import User from '../assets/icons/user.png';
import Calender from '../assets/icons/calender.png';
import CloseIcon from '../assets/icons/close.png';
import CheckIcon from '../assets/icons/todo-active.png';

import dateFormat from 'dateformat';
import {useNavigation} from '@react-navigation/native';
import {BottomSheetModal} from '@gorhom/bottom-sheet';
import {useDispatch, useSelector} from 'react-redux';

import DateTimePicker from '@react-native-community/datetimepicker';
import socket from '../lib/socket';
import {updateAllUsers, updatePinboards} from '../redux/slices/appDataSlice';
import {addHeNotification} from '../lib/HeNotifications/HeNotificationList';
import axios from 'axios';

import * as ImagePicker from 'expo-image-picker';

import {useHeaderHeight} from '@react-navigation/elements';
import {KeyboardAwareScrollView} from 'react-native-keyboard-aware-scroll-view';
import {decrypt, encrypt} from '../lib/eTe';

export default function AddPinboard({route}) {
	const navigation = useNavigation();
	const {pinboard} = route.params || {pinboard: null};

	const headerHeight = useHeaderHeight();
	const userInfo = useSelector((state) => state.appData.userData);
	const token = useSelector((state) => state.appData.token);

	const [uploading, setUploading] = React.useState(false);

	const [title, setTitle] = React.useState(
		pinboard ? decrypt(pinboard.title, userInfo.workspace) : ''
	);
	const [description, setDescription] = React.useState(
		pinboard ? decrypt(pinboard.description, userInfo.workspace) : ''
	);
	const [users, setUsers] = React.useState(pinboard ? pinboard.users : []);

	const [uploadUri, setUploadUri] = React.useState(
		pinboard ? pinboard.headerImage : ''
	);

	const [update, setUpdate] = React.useState(0);

	const [imageURL, setImageURL] = React.useState(
		pinboard
			? process.env.API_URL + '/' + pinboard.headerImage + '?token=' + token
			: null
	);

	const dispatch = useDispatch();

	const sheetRef = React.useRef(null);

	function dataURLtoFile(dataurl, filename) {
		var arr = dataurl.split(','),
			mime = arr[0].match(/:(.*?);/)[1],
			bstr = atob(arr[1]),
			n = bstr.length,
			u8arr = new Uint8Array(n);

		while (n--) {
			u8arr[n] = bstr.charCodeAt(n);
		}

		return new File([u8arr], filename, {type: mime});
	}

	async function uploadImage(image) {
		setUploading(true);
		if (image.fileSize > 75000000 || image.size > 75000000) {
			addHeNotification({
				title: 'Medien hochladen',
				message:
					'Die Datei ist zu groß. Bitte wähle eine Datei aus, die kleiner als 75MB ist.',
				type: 'error',
				duration: 5000,
			});
			setUploadImageLoading(false);
			setImage(null);
			return;
		}
		try {
			console.log(image);
			let data = new FormData();
			data.append(
				'file',
				Platform.OS != 'web'
					? {
							uri: image.uri,
							type:
								image.mimeType ||
								(Platform.OS == 'ios'
									? image.type
									: `${image.type}/${image.uri
											.split('.')
											.pop()
											.toLowerCase()
											.replace('jpeg', 'jpg')}`),
							name: image.uri.split('/').pop(),
					  }
					: image.webkitRelativePath == ''
					? image
					: dataURLtoFile(image.uri, image.name)
			);

			console.log('uploading image', data);
			console.log(
				process.env.API_URL + '/' + userInfo.workspace + '/pinboard/upload'
			);

			const response = await axios.post(
				process.env.API_URL + '/' + userInfo.workspace + '/pinboard/upload',
				data,
				{
					headers: {
						Authorization: 'Bearer ' + token,
						'Content-Type': 'multipart/form-data',
					},
				}
			);

			console.log(response.data, 'response');

			setUploadUri(response.data.url);
			setImageURL(
				process.env.API_URL + '/' + response.data.url + '?token=' + token
			);
			setUploading(false);

			console.log(response.data.url);
		} catch (error) {
			setUploading(false);
			console.log(error.request);
		}
	}

	async function savePinboard() {
		if (title.trim() === '') {
			return;
		}

		console.log('uploadUri: ' + uploadUri);

		const newPinboard = {
			title: encrypt(title, userInfo.workspace),
			description: encrypt(description, userInfo.workspace),
			users,
			headerImage: uploadUri,
		};

		try {
			const response = await axios.post(
				process.env.API_URL + '/' + userInfo.workspace + '/pinboard',
				newPinboard,
				{
					headers: {
						Authorization: 'Bearer ' + token,
					},
				}
			);

			dispatch(updatePinboards(response.data.pinboards));

			navigation.goBack();

			addHeNotification({
				title: 'Pinnwand',
				message: response.data.message,
				type: 'success',
				duration: 5000,
			});
		} catch (error) {
			if (typeof error.response !== 'undefined') {
				addHeNotification({
					title: 'Pinnwand',
					message: error.response.data.message,
					type: 'error',
					duration: 5000,
				});
				console.log(error.response.data);
			} else {
				addHeNotification({
					title: 'Pinnwand',
					message: 'Verbindung zum Server konnte nicht hergestellt werden',
					type: 'error',
					duration: 5000,
				});
				console.log(error.response);
			}
		}
	}

	async function selectImage() {
		let result = await ImagePicker.launchImageLibraryAsync({
			mediaTypes: ImagePicker.MediaTypeOptions.Images,
			quality: 1,
		});

		if (!result.canceled) {
			uploadImage(result.assets[0]);
		}
	}

	async function updatePinboardByID() {
		if (title.trim() === '') {
			return;
		}

		try {
			const response = await axios.put(
				process.env.API_URL +
					'/' +
					userInfo.workspace +
					'/pinboard/' +
					pinboard._id,
				{
					...pinboard,
					title: encrypt(title, userInfo.workspace),
					description: encrypt(description, userInfo.workspace),
					users,
					headerImage: uploadUri,
				},
				{
					headers: {
						Authorization: 'Bearer ' + token,
					},
				}
			);

			//dispatch(updatePinboards(response.data.pinboards));

			navigation.goBack();

			addHeNotification({
				title: 'Pinnwand',
				message: response.data.message,
				type: 'success',
				duration: 5000,
			});
		} catch (error) {
			if (typeof error.response !== 'undefined') {
				addHeNotification({
					title: 'Pinnwand',
					message: error.response.data.message,
					type: 'error',
					duration: 5000,
				});
				console.log(error.response.data);
			} else {
				addHeNotification({
					title: 'Pinnwand',
					message: 'Verbindung zum Server konnte nicht hergestellt werden',
					type: 'error',
					duration: 5000,
				});
				console.log(error);
			}
		}
	}

	useEffect(() => {
		socket.emit('getUsers', {
			token,
			workspace: userInfo.workspace,
			userID: userInfo._id,
		});

		socket.on('allUser', (users) => {
			dispatch(updateAllUsers(users));
		});

		return () => {
			socket.off('allUser');
		};
	}, []);

	const allUsers = useSelector((state) => state.appData.allUsers);

	const handlePresentModalPress = React.useCallback(() => {
		if (Platform.OS != 'web') Keyboard.dismiss();
		sheetRef.current?.present();
	}, []);

	const handleCloseModalPress = React.useCallback(() => {
		sheetRef.current?.close();
	}, []);

	return (
		<KeyboardAvoidingView
			behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
			keyboardVerticalOffset={headerHeight}
			style={tw.style('flex-1 bg-white')}
		>
			<KeyboardAwareScrollView
				style={tw.style('flex-1 bg-white')}
				contentContainerStyle={tw.style('w-full max-w-[900px] mx-auto')}
				enableAutomaticScroll
				enableOnAndroid={true}
			>
				<TouchableWithoutFeedback
					onPress={() => {
						if (Platform.OS != 'web') Keyboard.dismiss();
					}}
				>
					<View style={tw.style('flex-1 bg-white')}>
						<TouchableOpacity onPress={selectImage}>
							<View
								style={tw.style(
									'w-full aspect-video mx-auto bg-gray-400 mt-8 flex items-center justify-center'
								)}
							>
								{!uploading && (
									<Image
										source={{uri: imageURL}}
										style={tw.style('flex-1 w-full h-64 ')}
										resizeMode={Platform.OS == 'web' ? 'contain' : 'cover'}
									/>
								)}
								{uploading && <ActivityIndicator size="large" color="#fff" />}
							</View>
						</TouchableOpacity>

						<View style={tw.style('bg-white p-8 flex justify-between')}>
							<View>
								<Text style={tw.style('text-accent text-2xl font-bold')}>
									{pinboard ? '' : 'neue'} Pinnwand{' '}
									{pinboard ? 'bearbeiten' : ''}
								</Text>
								<TextInput
									style={tw.style(
										'border-b leading-tight border-gray-300 text-base py-2 mt-8'
									)}
									placeholder={'Name'}
									onChangeText={setTitle}
									value={title}
								/>
								<TextInput
									style={tw.style(
										'text-base py-3 leading-tight border-b border-gray-300 mt-8'
									)}
									placeholder={'Beschreibung'}
									onChangeText={setDescription}
									value={description}
									multiline
									numberOfLines={4}
									scrollEnabled={false}
								/>
								<View style={tw.style('my-4 flex flex-row mt-8')}>
									<TouchableOpacity
										style={tw.style('w-full flex flex-row items-center')}
										onPress={() => handlePresentModalPress()}
									>
										<View
											style={tw.style(
												'flex-row items-center border border-gray-300 rounded-full p-3 mr-2 ' +
													(users.length > 0
														? 'bg-[#F2F2F2] border-[#F2F2F2]'
														: 'bg-transparent border-gray-300')
											)}
										>
											<Image
												source={User}
												style={[
													tw.style('w-6 h-6'),
													{
														tintColor: tw.color(
															!users.length > 0 ? 'gray-300' : 'accent'
														),
													},
												]}
												resizeMode="contain"
											/>
										</View>
										<View>
											<Text
												style={tw.style(
													'text-sm' +
														(users.length > 0
															? ' text-gray-400'
															: ' text-gray-300')
												)}
											>
												hinzugefügte Benutzer
											</Text>
											<Text
												style={tw.style(
													'text-base font-bold' +
														(users.length > 0 ? '' : ' text-gray-300')
												)}
											>
												{users.length > 0
													? `${users.length} Person(-en)`
													: 'keine Person(-en)'}
											</Text>
										</View>
									</TouchableOpacity>
								</View>
							</View>
							<View
								style={tw.style(
									'flex flex-row justify-end items-center mt-4 h-14'
								)}
							>
								<TouchableOpacity
									style={tw.style('bg-accent p-4 px-6 rounded-full')}
									onPress={() => {
										if (pinboard) {
											updatePinboardByID();
										} else {
											savePinboard();
										}
									}}
								>
									<Text style={tw.style('font-semibold text-base text-white')}>
										{pinboard ? 'Speichern' : 'Erstellen'}
									</Text>
								</TouchableOpacity>
							</View>

							<BottomSheetModal
								ref={sheetRef}
								index={0}
								snapPoints={['50%']}
								style={tw.style('shadow-lg')}
								backdropComponent={() => {
									return (
										<TouchableOpacity
											style={tw.style(
												'flex-1 absolute top-0 left-0 right-0 bottom-0'
											)}
											onPress={() => {
												sheetRef.current?.close();
											}}
										/>
									);
								}}
								enableContentPanningGesture={Platform.OS != 'android'}
								enableHandlePanningGesture={Platform.OS != 'web'}
							>
								<View style={tw.style('flex-1 w-full max-w-[900px] mx-auto')}>
									<View style={tw.style('flex-1 bg-white p-8')}>
										<Text
											style={tw.style('text-primary text-4xl font-semibold')}
										>
											Nutzer zur Pinnwand zuweisen
										</Text>
										<Text style={tw.style('text-gray-400 text-base mb-6')}>
											Wähle einen oder mehrere Nutzer aus, die mit der Pinnwand
											interagieren können.
										</Text>
										<ScrollView>
											{allUsers &&
												allUsers
													.filter(
														(
															user // filter out current user
														) => {
															return user._id !== userInfo._id;
														}
													)
													.map((user) => (
														<TouchableOpacity
															onPress={() => {
																// test if user is already in array and remove it

																let tempUser = [...users];
																if (tempUser.includes(user._id)) {
																	const index = tempUser.indexOf(user._id);
																	if (index > -1) {
																		tempUser.splice(index, 1);
																	}
																} else {
																	tempUser.push(user._id);
																}

																setUsers(tempUser);

																setUpdate(update + 1);
															}}
															style={tw.style('flex py-1')}
															key={user._id}
														>
															<View
																key={user._id}
																style={tw.style(
																	'flex flex-row items-center justify-between mt-2'
																)}
															>
																<View
																	style={tw.style('flex flex-row items-center')}
																>
																	<View
																		style={tw.style(
																			'flex-row items-center mr-4'
																		)}
																	>
																		<Image
																			source={
																				user.extendedData?.avatar
																					? {
																							uri:
																								process.env.API_URL +
																								'/' +
																								user.extendedData.avatar +
																								'?width=64&height=64&token=' +
																								token,
																					  }
																					: User
																			}
																			style={tw.style(
																				'w-7 h-7 bg-[#F2F2F2] rounded-full '
																			)}
																			resizeMode={
																				user.extendedData?.avatar
																					? 'cover'
																					: 'contain'
																			}
																		/>
																	</View>
																	<View>
																		<Text style={tw.style('ml-2 text-lg')}>
																			{user.extendedData.firstName === ''
																				? user.username
																				: user.extendedData.firstName +
																				  ' ' +
																				  user.extendedData.lastName}
																		</Text>
																	</View>
																</View>
																{users.includes(user._id) ? (
																	<Image
																		source={CheckIcon}
																		style={tw.style('w-5 h-5')}
																		resizeMode="contain"
																	/>
																) : (
																	<View style={tw.style('w-5 h-5')} />
																)}
															</View>
														</TouchableOpacity>
													))}
										</ScrollView>
									</View>
								</View>
							</BottomSheetModal>
						</View>
					</View>
				</TouchableWithoutFeedback>
			</KeyboardAwareScrollView>
		</KeyboardAvoidingView>
	);
}
