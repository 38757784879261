import {View, Text, TouchableOpacity, Image} from 'react-native';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {SafeAreaView} from 'react-native-safe-area-context';
import User from '../assets/icons/user.png';
import Arrow from '../assets/icons/arrow-right.png';
import tw from '../lib/tailwind';
import socket from '../lib/socket';
import {updateAllUsers} from '../redux/slices/appDataSlice';
import {useNavigation} from '@react-navigation/native';
import BackIcon from '../assets/icons/arrow-left.png';

export default function ChatHeader(props) {
	const userInfo = useSelector((state) => state.appData.userData);

	let connectionStatus = useSelector((state) => state.appData.serverConnected);

	const allUsers = useSelector((state) => state.appData.allUsers);
	const token = useSelector((state) => state.appData.token);

	const dispatch = useDispatch();

	const navigation = useNavigation();

	const {chatData: chat} = props;

	const [partnerData, setPartnerData] = useState(getUsers());

	useEffect(() => {
		setPartnerData(getUsers());
	}, [allUsers]);

	useEffect(() => {
		socket.emit('getUsers', {
			token,
			workspace: userInfo.workspace,
			userID: userInfo._id,
		});

		socket.on('allUser', (users) => {
			dispatch(updateAllUsers(users));
		});
	}, []);

	function getUsers() {
		let chatUsers = [];

		chat.users.forEach((user) => {
			chatUsers.push(
				allUsers.filter(
					(item) => item._id == user && item._id != userInfo._id
				)[0] || null
			);
		});

		chatUsers = chatUsers.filter((item) => item != null);

		return chatUsers;
	}

	return (
		<View>
			<SafeAreaView
				edges={['top']}
				style={tw.style(
					'shadow-lg bg-white z-60 pb-3 pt-3 px-6 flex-row items-center justify-between'
				)}
			>
				<TouchableOpacity
					style={[tw.style('p-2')]}
					onPress={() => props.onGoBack()}
				>
					<Image
						source={BackIcon}
						style={tw.style('w-5 h-5')}
						resizeMode="contain"
					/>
				</TouchableOpacity>
				<TouchableOpacity
					onPress={() => {
						if (chat?.users?.length == 0) {
							return;
						}
						props.onDetails();
					}}
					style={[
						tw.style(
							'flex flex-row items-center justify-center relative z-60 w-2/3'
						),
					]}
				>
					<View style={tw.style('flex-row items-center')}>
						{partnerData.length <= 1 && chat.type != 'group' ? (
							<Image
								source={
									partnerData.length == 0
										? User
										: partnerData[0]?.extendedData?.avatar != ''
										? {
												uri:
													process.env.API_URL +
													'/' +
													partnerData[0].extendedData.avatar +
													'?width=64&height=64&token=' +
													token,
										  }
										: User
								}
								style={tw.style('w-5 h-5 bg-[#F2F2F2] rounded-full ')}
								resizeMode={
									partnerData[0]?.extendedData?.avatar ? 'cover' : 'contain'
								}
							/>
						) : (
							<>
								<Image
									source={User}
									style={tw.style('w-7 h-7 bg-[#F2F2F2] rounded-full ')}
									resizeMode={'contain'}
								/>
								<Image
									source={User}
									style={tw.style('w-7 h-7 -ml-3 bg-[#F2F2F2] rounded-full ')}
									resizeMode={'contain'}
								/>
							</>
						)}
					</View>
					<Text style={tw.style('text-xl text-accent ml-3')} numberOfLines={1}>
						{chat && chat.title !== ''
							? chat.title
							: chat && partnerData.length == 0
							? 'DU'
							: chat && partnerData.length == 1
							? partnerData.find((user) => chat.users.includes(user._id))
									?.username || 'Gelöschter Benutzer'
							: (partnerData[0]?.username || 'Gelöschter Benutzer') +
							  ' und ' +
							  (chat.users.length - 2) +
							  ' weitere'}
					</Text>
				</TouchableOpacity>
				<View style={tw.style('w-7')}></View>
			</SafeAreaView>
			{connectionStatus ? <></> : <View style={tw.style('bg-red-500 h-1')} />}
		</View>
	);
}
