import {
	View,
	Text,
	TouchableOpacity,
	TextInput,
	TouchableWithoutFeedback,
	Image,
	Keyboard,
	ImageBackground,
	Platform,
	ScrollView,
} from 'react-native';
import React from 'react';
import tw from '../../lib/tailwind';

import LoginBGImage from '../../assets/images/auth/xd_vorlagen_login.png';
import {useNavigation} from '@react-navigation/native';

import VisibiltyIcon from '../../assets/icons/visibility.png';
import VisibiltyOffIcon from '../../assets/icons/visibility-off.png';
import axios from 'axios';
import {addHeNotification} from '../../lib/HeNotifications/HeNotificationList';
import {useDispatch, useSelector} from 'react-redux';
import {
	setMessageData,
	updateToken,
	updateUserData,
	updateWorkspaceData,
} from '../../redux/slices/appDataSlice';

import Checked from '../../assets/icons/checked.png';
import Unchecked from '../../assets/icons/unchecked.png';
import * as SecureStore from 'expo-secure-store';
import {KeyboardAwareScrollView} from 'react-native-keyboard-aware-scroll-view';

export default function ForgotPassword() {
	const navigation = useNavigation();

	const pushNotificationToken = useSelector(
		(state) => state.appData.pushNotificationToken
	);

	const [email, setEmail] = React.useState('');
	const [emailError, setEmailError] = React.useState(false);

	const dispatch = useDispatch();

	async function forgotPassword() {
		if (email == '') {
			setEmailError(true);
			return;
		}

		try {
			const response = await axios.post(
				`${process.env.API_URL}/user/lost-password`,
				{
					username: email,
				}
			);

			if (response.status == 200) {
				addHeNotification({
					title: 'Passwort zurücksetzen',
					message:
						'Wir haben dir eine E-Mail mit einem Link zum zurücksetzen deines Passworts gesendet.',
					type: 'success',
				});
			} else {
				addHeNotification({
					title: 'Passwort zurücksetzen',
					message:
						'Wir konnten dir keine E-Mail mit einem Link zum zurücksetzen deines Passworts senden.',
					type: 'error',
				});
			}
		} catch (error) {
			addHeNotification({
				title: 'Passwort zurücksetzen',
				message:
					'Wir konnten dir keine E-Mail mit einem Link zum zurücksetzen deines Passworts senden.',
				type: 'error',
			});
		}
	}

	return (
		<ImageBackground
			source={LoginBGImage}
			resizeMode={'contain'}
			style={tw.style('flex-1 items-center')}
		>
			<TouchableWithoutFeedback
				onPress={() => {
					if (Platform.OS != 'web') {
						Keyboard.dismiss();
					}
				}}
			>
				<View
					style={tw.style(
						'flex-1' +
							(Platform.OS == 'web'
								? ' max-w-[500px] w-2/3 min-w-[350px]'
								: ' w-full')
					)}
				>
					<View style={tw.style('w-full h-5/11')}></View>
					<KeyboardAwareScrollView
						contentContainerStyle={tw.style(
							'justify-start items-center px-12 pb-12'
						)}
						enableOnAndroid={true}
						enableAutomaticScroll={true}
						scrollEnabled={true}
						keyboardShouldPersistTaps="handled"
					>
						<Text
							style={tw.style('w-full mb-10 text-4xl text-accent font-medium')}
						>
							passwort vergessen
						</Text>

						<View style={tw.style('w-full')}>
							<View
								style={tw.style(
									' w-full border-b border-opacity-35 pb-3' +
										(emailError ? ' border-red-500' : ' border-gray-400')
								)}
							>
								<TextInput
									keyboardType="email-address"
									placeholder="email"
									style={tw.style('text-lg leading-tight text-gray-700')}
									onChangeText={(text) => {
										setEmail(text);
										setEmailError(false);
									}}
									value={email}
									placeholderTextColor={tw.color(
										emailError ? 'red-300' : 'gray-300'
									)}
									textContentType="username"
									spellCheck={false}
									autoCapitalize="none"
									clearButtonMode="while-editing"
									k
								/>
							</View>
						</View>
						<View style={tw.style('w-full')}>
							<TouchableOpacity
								activeOpacity={0.8}
								style={tw.style(
									'w-full mt-14 rounded-full py-3 bg-accent self-start'
								)}
								onPress={() => forgotPassword()}
							>
								<Text
									style={tw.style('text-primary text-center text-xl font-bold')}
								>
									link versenden
								</Text>
							</TouchableOpacity>

							<TouchableOpacity
								activeOpacity={0.5}
								style={tw.style(
									'w-full mt-7 rounded-full py-3 bg-transparent self-start'
								)}
								onPress={() => {
									navigation.goBack();
								}}
							>
								<Text
									style={tw.style('text-primary text-center text-xl font-bold')}
								>
									zurück
								</Text>
							</TouchableOpacity>
						</View>
					</KeyboardAwareScrollView>
				</View>
			</TouchableWithoutFeedback>
		</ImageBackground>
	);
}
