import {View, Text, TouchableOpacity, Image} from 'react-native';
import React from 'react';
import {SafeAreaView} from 'react-native-safe-area-context';
import tw from '../lib/tailwind';

import Bell from '../assets/icons/bell.png';
import User from '../assets/icons/user.png';
import Settings from '../assets/icons/settings.png';
import {useSelector} from 'react-redux';
import {useNavigation} from '@react-navigation/native';
import {useEffect} from 'react';

export default function HeaderBar(props) {
	let connectionStatus = useSelector((state) => state.appData.serverConnected);

	const userInfo = useSelector((state) => state.appData.userData);
	const token = useSelector((state) => state.appData.token);

	const navigation = useNavigation();

	const [update, setUpdate] = React.useState(0);

	useEffect(() => {
		setUpdate(update + 1);
	}, [userInfo]);

	return (
		<View>
			<SafeAreaView
				edges={['top']}
				style={tw.style(
					'shadow-lg bg-white z-60 pb-2 pt-2 px-6 flex-row items-center justify-between'
				)}
			>
				<View style={tw.style('flex-row')}>
					<TouchableOpacity
						style={tw.style('bg-[#F2F2F2] rounded-full p-2')}
						onPress={() => navigation.navigate('notifications')}
					>
						<Image
							source={Bell}
							style={tw.style('w-5 h-5')}
							resizeMode="contain"
						/>
					</TouchableOpacity>
					<TouchableOpacity
						style={tw.style('bg-[#F2F2F2] rounded-full p-2 ml-3')}
						onPress={() => navigation.navigate('settings')}
					>
						<Image
							source={Settings}
							style={tw.style('w-5 h-5')}
							resizeMode="contain"
						/>
					</TouchableOpacity>
				</View>
				<TouchableOpacity
					style={tw.style('')}
					onPress={() => {
						navigation.navigate('userProfile');
					}}
				>
					<View style={tw.style('flex-row items-center ')}>
						<Image
							source={
								userInfo.extendedData?.avatar
									? {
											uri:
												process.env.API_URL +
												'/' +
												userInfo.extendedData.avatar +
												'?width=64&height=64&token=' +
												token,
									  }
									: User
							}
							style={tw.style('w-8 h-8 bg-[#F2F2F2] rounded-full')}
							resizeMode={userInfo.extendedData?.avatar ? 'cover' : 'contain'}
						/>
					</View>
				</TouchableOpacity>
			</SafeAreaView>
			{connectionStatus ? <></> : <View style={tw.style('bg-red-500 h-1')} />}
		</View>
	);
}
