import {
	View,
	Text,
	TouchableOpacity,
	Image,
	TextInput,
	TouchableWithoutFeedback,
	Keyboard,
	Alert,
	RefreshControl,
} from 'react-native';
import React, {useEffect} from 'react';
import tw from '../../../lib/tailwind';
import {BottomSheetModal} from '@gorhom/bottom-sheet';
import {useNavigation} from '@react-navigation/native';
import dateFormat from 'dateformat';
import CheckIcon from '../../../assets/icons/todo-active.png';
import DeleteIcon from '../../../assets/icons/close.png';

import axios from 'axios';
import {useDispatch, useSelector} from 'react-redux';
import {
	updateAllUsers,
	updateTodos,
	updateUserData,
} from '../../../redux/slices/appDataSlice';
import {addHeNotification} from '../../../lib/HeNotifications/HeNotificationList';
import {RectButton, ScrollView, Swipeable} from 'react-native-gesture-handler';
import Animated from 'react-native-reanimated';
import socket from '../../../lib/socket';
import {decrypt} from '../../../lib/eTe';
import DropDownPicker from 'react-native-dropdown-picker';

export default function Todo() {
	const navigation = useNavigation();

	const todos = useSelector((state) => state.appData.todos);

	const userInfo = useSelector((state) => state.appData.userData);

	const token = useSelector((state) => state.appData.token);

	const dispatch = useDispatch();

	const [openDropDown, setOpenDropDown] = React.useState(false);
	const [valueDropDown, setValueDropDown] = React.useState('none');

	const [refreshing, setRefreshing] = React.useState(false);

	const dropDownItems = [
		{
			label: 'Ohne Sortierung',
			value: 'none',
			labelStyle: {paddingLeft: 15},
		},
		{label: 'Fälligkeit', value: 'date', labelStyle: {paddingLeft: 15}},
		{label: 'Titel', value: 'title', labelStyle: {paddingLeft: 15}},
		{label: 'Eigene', value: 'own', labelStyle: {paddingLeft: 15}},
	];

	useEffect(() => {
		socket.emit('getUsers', {
			token,
			workspace: userInfo.workspace,
			userID: userInfo._id,
		});

		socket.on('allUser', (users) => {
			dispatch(updateAllUsers(users));

			let me = users.find((user) => user._id == userInfo._id);

			dispatch(
				updateUserData({
					...userInfo,
					role: me.role,
				})
			);
		});

		getTodos();

		navigation.addListener('focus', () => {
			getTodos();
			socket.emit('getUsers', {
				token,
				workspace: userInfo.workspace,
				userID: userInfo._id,
			});
		});

		return () => {
			socket.off('allUser');
			navigation.removeListener('focus');
		};
	}, []);

	async function getTodos() {
		try {
			let res = await axios.get(
				process.env.API_URL + '/' + userInfo.workspace + '/todo',
				{
					headers: {
						Authorization: 'Bearer ' + token,
					},
				}
			);

			dispatch(updateTodos(res.data.todos));
		} catch (error) {
			console.log(error);
		}

		setRefreshing(false);
	}

	async function deleteTodo(id) {
		try {
			let response = await axios.delete(
				process.env.API_URL + '/' + userInfo.workspace + '/todo/' + id,
				{
					headers: {
						Authorization: 'Bearer ' + token,
					},
				}
			);

			dispatch(updateTodos(response.data.todos));

			addHeNotification({
				title: 'Aufgaben',
				message: response.data.message,
				type: 'success',
				duration: 5000,
			});
		} catch (error) {
			if (typeof error.response !== 'undefined') {
				addHeNotification({
					title: 'Aufgaben',
					message: error.response.data.message,
					type: 'error',
					duration: 5000,
				});
				console.log(error.response.data);
			} else {
				addHeNotification({
					title: 'Aufgaben',
					message: 'Verbindung zum Server konnte nicht hergestellt werden',
					type: 'error',
					duration: 5000,
				});
				console.log(error.response);
			}
		}
	}

	async function updateTodoStatus(todo) {
		try {
			let response = await axios.put(
				process.env.API_URL + '/' + userInfo.workspace + '/todo/' + todo._id,
				{
					...todo,
					status: todo.status == 'open' ? 'closed' : 'open',
				},
				{
					headers: {
						Authorization: 'Bearer ' + token,
					},
				}
			);

			dispatch(updateTodos(response.data.todos));

			addHeNotification({
				title: 'Aufgaben',
				message: response.data.message,
				type: 'success',
				duration: 5000,
			});
		} catch (error) {
			if (typeof error.response !== 'undefined') {
				addHeNotification({
					title: 'Aufgaben',
					message: error.response.data.message,
					type: 'error',
					duration: 5000,
				});
				console.log(error.response.data);
			} else {
				addHeNotification({
					title: 'Aufgaben',
					message: 'Verbindung zum Server konnte nicht hergestellt werden',
					type: 'error',
					duration: 5000,
				});
				console.log(error.response);
			}
		}
	}

	return (
		<TouchableWithoutFeedback onPress={() => {}}>
			<View style={tw.style('flex-1 bg-white')}>
				<ScrollView
					nestedScrollEnabled
					refreshControl={
						<RefreshControl
							refreshing={refreshing}
							onRefresh={() => {
								setRefreshing(true);

								getTodos();
							}}
							colors={[tw.color('primary')]}
						/>
					}
					contentContainerStyle={tw.style('w-full max-w-[900px] mx-auto')}
				>
					<View
						style={tw.style('flex-row items-center justify-between px-8 py-6')}
					>
						<Text style={tw.style('text-primary text-4xl font-semibold')}>
							Aufgaben
						</Text>

						<DropDownPicker
							items={dropDownItems}
							containerStyle={{width: '50%', zIndex: 1000}}
							dropDownContainerStyle={{zIndex: 1000}}
							listItemContainerStyle={tw.style('w-full max-w-[900px] mx-auto')}
							onChangeItem={(item) => console.log(item.label, item.value)}
							open={openDropDown}
							setOpen={setOpenDropDown}
							value={valueDropDown}
							setValue={setValueDropDown}
							zIndex={1000}
							zIndexInverse={1000}
							listMode="MODAL"
							itemSeparator={true}
							itemSeparatorStyle={{
								backgroundColor: '#eee',
							}}
						/>
					</View>
					<View style={tw.style('flex-1 z-0 mb-24')}>
						{todos.filter((todo) => todo.status == 'open').length > 0 ? (
							<View style={tw.style('flex-1 z-0')}>
								{todos
									.filter((todo) => todo.status == 'open')
									.sort((a, b) => {
										if (valueDropDown == 'date') {
											// sort by year first then by month and then by day
											if (
												new Date(a.date).getFullYear() <
												new Date(b.date).getFullYear()
											) {
												return -1;
											} else if (
												new Date(a.date).getFullYear() >
												new Date(b.date).getFullYear()
											) {
												return 1;
											} else {
												if (
													new Date(a.date).getMonth() <
													new Date(b.date).getMonth()
												) {
													return -1;
												} else if (
													new Date(a.date).getMonth() >
													new Date(b.date).getMonth()
												) {
													return 1;
												} else {
													if (
														new Date(a.date).getDate() <
														new Date(b.date).getDate()
													) {
														return -1;
													} else if (
														new Date(a.date).getDate() >
														new Date(b.date).getDate()
													) {
														return 1;
													}
													return 0;
												}
											}
										} else if (valueDropDown == 'title') {
											if (
												decrypt(a.title, userInfo.workspace) <
												decrypt(b.title, userInfo.workspace)
											) {
												return -1;
											} else if (
												decrypt(a.title, userInfo.workspace) >
												decrypt(b.title, userInfo.workspace)
											) {
												return 1;
											}
											return 0;
										} else if (valueDropDown == 'own') {
											if (a.createdBy == userInfo._id) {
												return -1;
											} else {
												return 1;
											}
										} else {
											return 0;
										}
									})
									.map((todo) => (
										<Swipeable
											enabled
											renderRightActions={(progress, dragX) => {
												return (
													<TouchableOpacity
														activeOpacity={0.8}
														style={tw.style(
															'bg-accent w-1/3 flex items-center justify-center z-0'
														)}
														onPress={() => {
															updateTodoStatus(todo);
														}}
													>
														<Image
															source={CheckIcon}
															style={tw.style('h-6 w-6 tint-white')}
															resizeMode="contain"
														/>
														<Text style={tw.style('text-white mt-2')}>
															Abschließen
														</Text>
													</TouchableOpacity>
												);
											}}
											key={todo._id}
											renderLeftActions={(progress, dragX) => {
												return (
													<View
														style={tw.style('w-1/4 h-full flex flex-row z-0')}
													>
														<TouchableOpacity
															activeOpacity={0.8}
															style={tw.style(
																'bg-yellow-500 w-full flex items-center justify-center'
															)}
															onPress={() => {
																navigation.navigate('addTodo', {
																	todo,
																});
															}}
														>
															<Image
																source={require('../../../assets/icons/settings.png')}
																style={tw.style('h-6 w-6 tint-white')}
																resizeMode="contain"
															/>
															<Text style={tw.style('text-white mt-2')}>
																Bearbeiten
															</Text>
														</TouchableOpacity>

														{/* <TouchableOpacity
															activeOpacity={0.8}
															style={tw.style(
																'bg-red-500 w-1/2 flex items-center justify-center'
															)}
															onPress={() => {
																Alert.alert(
																	'Löschen',
																	'Soll diese Aufgabe wirklich gelöscht werden? Diese Aktion kann nicht rückgängig gemacht werden.',
																	[
																		{
																			text: 'Abbrechen',
																			style: 'cancel',
																		},
																		{
																			text: 'Löschen',
																			onPress: () => {
																				deleteTodo(todo._id);
																			},
																			style: 'destructive',
																		},
																	]
																);
															}}
														>
															<Image
																source={DeleteIcon}
																style={[
																	tw.style('h-6 w-6 tint-white'),
																	{transform: [{rotate: '45deg'}]},
																]}
																resizeMode="contain"
															/>
															<Text style={tw.style('text-white mt-2')}>
																Löschen
															</Text>
														</TouchableOpacity> */}
													</View>
												);
											}}
											containerStyle={tw.style('border-b border-gray-200 z-0')}
										>
											<TouchableOpacity
												onPress={() =>
													navigation.navigate('TodoDetail', {todo})
												}
												style={tw.style(
													'flex-row items-center justify-between bg-white py-6 px-8 z-0'
												)}
											>
												<View
													style={tw.style(
														'flex flex-row justify-between items-center flex-1'
													)}
												>
													<View style={tw.style('w-4/5')}>
														<Text
															style={tw.style(
																'text-gray-600 text-xl font-semibold'
															)}
															ellipsizeMode="tail"
														>
															{decrypt(todo.title, userInfo.workspace)}
														</Text>
														<Text
															style={tw.style(
																'text-gray-400 text-sm font-medium'
															)}
															numberOfLines={2}
														>
															{decrypt(todo.description, userInfo.workspace)
																? decrypt(todo.description, userInfo.workspace)
																: 'Keine Beschreibung'}
														</Text>
													</View>
													<Text style={tw.style('text-gray-400 text-sm')}>
														{todo.date
															? dateFormat(todo.date, 'dd. mmm yyyy')
															: 'Kein Datum'}
													</Text>
												</View>
											</TouchableOpacity>
										</Swipeable>
									))}
							</View>
						) : (
							<Text
								style={tw.style(
									'text-gray-400 text-lg font-semibold py-6 px-8'
								)}
							>
								Keine Aufgaben vorhanden
							</Text>
						)}
					</View>
				</ScrollView>

				<TouchableOpacity
					style={tw.style(
						'absolute right-6 bottom-6 bg-accent p-4 rounded-full'
					)}
					onPress={() => navigation.navigate('addTodo')}
				>
					<Image
						source={require('../../../assets/icons/close.png')}
						style={[tw.style('h-6 w-6'), {tintColor: tw.color('primary')}]}
					/>
				</TouchableOpacity>
			</View>
		</TouchableWithoutFeedback>
	);
}
