import React, {useEffect} from 'react';
import {
	ActivityIndicator,
	Image,
	Platform,
	RefreshControl,
	ScrollView,
	Text,
	TouchableOpacity,
	View,
} from 'react-native';
import {useDispatch, useSelector} from 'react-redux';
import tw from '../../../lib/tailwind';
import BottomSheet, {BottomSheetModal} from '@gorhom/bottom-sheet';
import socket from '../../../lib/socket';
import {useNavigation} from '@react-navigation/native';
import User from '../../../assets/icons/user.png';

import CheckedIcon from '../../../assets/icons/checked.png';

import axios from 'axios';
import {
	updateAllChats,
	updateAllUsers,
	updateUserData,
} from '../../../redux/slices/appDataSlice';
import {addHeNotification} from '../../../lib/HeNotifications/HeNotificationList';

import dateFormat from 'dateformat';
import {decrypt} from '../../../lib/eTe';
import {Swipeable} from 'react-native-gesture-handler';
import DropDownPicker from 'react-native-dropdown-picker';
import {KeyboardAwareScrollView} from 'react-native-keyboard-aware-scroll-view';
import {G} from 'react-native-svg';

export default function Chat() {
	const chats = useSelector((state) => state.appData.allChats);

	const userInfo = useSelector((state) => state.appData.userData);

	const allUsers = useSelector((state) => state.appData.allUsers);

	const token = useSelector((state) => state.appData.token);

	const dispatch = useDispatch();

	const sheetRef = React.useRef(null);

	const navigation = useNavigation();

	const [openDropDown, setOpenDropDown] = React.useState(false);
	const [valueDropDown, setValueDropDown] = React.useState('none');

	const [refreshing, setRefreshing] = React.useState(false);

	const dropDownItems = [
		{
			label: 'Ohne Filterung',
			value: 'none',
			labelStyle: {paddingLeft: 15},
		},
		{label: 'Einzelchats', value: 'single', labelStyle: {paddingLeft: 15}},
		{label: 'Gruppenchats', value: 'group', labelStyle: {paddingLeft: 15}},
	];

	const handlePresentModalPress = React.useCallback(() => {
		sheetRef.current?.present();
	}, []);

	useEffect(() => {
		let focusListener = navigation.addListener('focus', () => {
			getAllChats();
		});

		getAllChats();

		return () => {
			focusListener();
		};
	}, []);

	async function getAllChats() {
		try {
			let response = await axios.get(
				process.env.API_URL + '/' + userInfo.workspace + '/chat/',
				{
					headers: {
						Authorization: 'Bearer ' + token,
					},
				}
			);

			dispatch(updateAllChats(response.data.chats));
		} catch (error) {
			console.log(error);
		}

		setRefreshing(false);
	}

	async function archiveChat(id) {
		try {
			let response = await axios.put(
				process.env.API_URL + '/' + userInfo.workspace + '/chat/' + id,
				{
					status: 'closed',
				},
				{
					headers: {
						Authorization: 'Bearer ' + token,
					},
				}
			);

			dispatch(updateAllChats(response.data.chats));

			addHeNotification({
				title: 'Chats',
				message: response.data.message,
				type: 'success',
				duration: 5000,
			});
		} catch (error) {
			if (typeof error.response !== 'undefined') {
				addHeNotification({
					title: 'Chats',
					message: error.response.data.message,
					type: 'error',
					duration: 5000,
				});
				console.log(error.response.data);
			} else {
				addHeNotification({
					title: 'Chats',
					message: 'Verbindung zum Server konnte nicht hergestellt werden',
					type: 'error',
					duration: 5000,
				});
				console.log(error);
			}
		}
	}

	async function createChat(users) {
		try {
			let response = await axios.post(
				process.env.API_URL + '/' + userInfo.workspace + '/chat/',
				{
					users,
					title: '',
				},
				{
					headers: {
						Authorization: 'Bearer ' + token,
					},
				}
			);

			dispatch(updateAllChats(response.data.chats));

			addHeNotification({
				title: 'Chats',
				message: response.data.message,
				type: 'success',
				duration: 5000,
			});
		} catch (error) {
			if (typeof error.response !== 'undefined') {
				addHeNotification({
					title: 'Chats',
					message: error.response.data.message,
					type: 'error',
					duration: 5000,
				});
				console.log(error.response.data);
			} else {
				addHeNotification({
					title: 'Chats',
					message: 'Verbindung zum Server konnte nicht hergestellt werden',
					type: 'error',
					duration: 5000,
				});
				console.log(error);
			}
		}
	}

	useEffect(() => {
		socket.emit('getUsers', {
			token,
			workspace: userInfo.workspace,
			userID: userInfo._id,
		});

		socket.on('allUser', (users) => {
			dispatch(updateAllUsers(users));

			let me = users.find((user) => user._id == userInfo._id);

			dispatch(
				updateUserData({
					...userInfo,
					role: me.role,
				})
			);
		});

		navigation.addListener('focus', () => {
			socket.emit('getUsers', {
				token,
				workspace: userInfo.workspace,
				userID: userInfo._id,
			});
		});

		return () => {
			socket.off('allUser');
			navigation.removeListener('focus');
		};
	}, []);

	function getLastActiveTime(chat) {
		let lastTime = chat.createdAt;

		if (chat.messages.length > 0) {
			lastTime = chat.messages[chat.messages.length - 1].createdAt;
		}

		let formatString = 'HH:MM';

		if (
			dateFormat(lastTime, 'dd.mm.yyyy') !==
			dateFormat(new Date(), 'dd.mm.yyyy')
		) {
			formatString = 'dd.mm.yyyy';
		}

		return dateFormat(lastTime, formatString);
	}

	return (
		<View style={tw.style('flex-1 bg-white')}>
			<ScrollView
				style={tw.style('flex-1')}
				contentContainerStyle={tw.style('pb-50 w-full max-w-[900px] mx-auto')}
				refreshControl={
					<RefreshControl
						refreshing={refreshing}
						onRefresh={() => {
							setRefreshing(true);

							getAllChats();
						}}
						colors={[tw.color('primary')]}
					/>
				}
			>
				<View
					style={tw.style('flex-row items-center  justify-between px-8 py-6')}
				>
					<Text style={tw.style('text-primary text-4xl font-semibold')}>
						Nachrichten
					</Text>
					<DropDownPicker
						items={dropDownItems}
						containerStyle={{width: '50%', zIndex: 1000}}
						dropDownContainerStyle={{zIndex: 1000}}
						listItemContainerStyle={tw.style('w-full max-w-[900px] mx-auto')}
						open={openDropDown}
						setOpen={setOpenDropDown}
						value={valueDropDown}
						setValue={setValueDropDown}
						zIndex={1000}
						zIndexInverse={1000}
						listMode="MODAL"
						itemSeparator={true}
						itemSeparatorStyle={{
							backgroundColor: '#eee',
						}}
					/>
				</View>

				{chats &&
					chats
						.filter((item) => {
							switch (valueDropDown) {
								case 'single':
									return item.users.length <= 2;
								case 'group':
									return item.users.length > 2;
								default:
									return true;
							}
						})
						.filter((item) => item.status != 'closed')
						.map((chat) => {
							let chatUsers = [];

							let swipeableOpen = false;

							chat.users.forEach((user) => {
								chatUsers.push(
									allUsers.filter(
										(item) => item._id == user && item._id != userInfo._id
									)[0] || null
								);
							});

							chatUsers = chatUsers.filter((item) => item !== null);

							return (
								<Swipeable
									key={chat._id}
									renderRightActions={() => (
										<TouchableOpacity
											onPress={() => {
												archiveChat(chat._id);
											}}
											style={tw.style(
												'bg-yellow-500 p-6 flex items-center justify-center'
											)}
										>
											<Text style={tw.style('text-white')}>Archivieren</Text>
										</TouchableOpacity>
									)}
									onSwipeableWillOpen={() => {
										swipeableOpen = true;
									}}
									onSwipeableWillClose={() => {
										swipeableOpen = false;
									}}
								>
									<TouchableOpacity
										key={chat._id}
										style={tw.style(
											'flex-row items-center justify-between px-8 py-4 border-b border-gray-200'
										)}
										onPress={() => {
											if (swipeableOpen) return;

											navigation.navigate('SingleChat', {
												chatID: chat._id,
											});
										}}
									>
										<View style={tw.style('flex-row items-center')}>
											<View style={tw.style('flex-row items-center mr-1')}>
												{chatUsers.length <= 1 && chat.type != 'group' ? (
													<Image
														source={
															chat.users.length == 2
																? allUsers
																		.filter((user) => user._id != userInfo._id)
																		.find((user) =>
																			chat.users.includes(user._id)
																		)?.extendedData?.avatar !== ''
																	? {
																			uri:
																				process.env.API_URL +
																				'/' +
																				allUsers
																					.filter(
																						(user) => user._id != userInfo._id
																					)
																					.find((user) =>
																						chat.users.includes(user._id)
																					)?.extendedData.avatar +
																				'?width=64&height=64&token=' +
																				token,
																	  }
																	: User
																: User
														}
														style={tw.style(
															'w-7 h-7 bg-[#F2F2F2] rounded-full '
														)}
														resizeMode={
															chat.users.length == 2
																? allUsers
																		.filter((user) => user._id != userInfo._id)
																		.find((user) =>
																			chat.users.includes(user._id)
																		)?.extendedData?.avatar !== ''
																	? 'cover'
																	: 'contain'
																: 'contain'
														}
													/>
												) : (
													<>
														<Image
															source={User}
															style={tw.style(
																'w-7 h-7 bg-[#F2F2F2] rounded-full '
															)}
															resizeMode={'contain'}
														/>
														<Image
															source={User}
															style={tw.style(
																'w-7 h-7 -ml-3 bg-[#F2F2F2] rounded-full '
															)}
															resizeMode={'contain'}
														/>
													</>
												)}
											</View>
											<View style={tw.style('ml-2 w-2/3')}>
												<Text
													style={tw.style(
														'text-primary text-lg font-semibold w-full'
													)}
													numberOfLines={1}
												>
													{chat.title !== ''
														? chat.title
														: chatUsers.length == 0
														? 'DU'
														: chatUsers.length == 1
														? allUsers
																.filter((user) => user._id != userInfo._id)
																.find((user) => chat.users.includes(user._id))
																?.username
														: chatUsers[0]?.username +
														  ' und ' +
														  (chatUsers.length - 1) +
														  ' weitere'}
												</Text>

												<Text
													style={tw.style('text-gray-500 text-sm max-w-full')}
													numberOfLines={1}
												>
													{chat.messages.length > 0
														? (chat.messages[chat.messages.length - 1]
																.createdBy == userInfo._id
																? 'Du: '
																: '') +
														  (chat.messages[chat.messages.length - 1].type ==
														  'text'
																? decrypt(
																		chat.messages[chat.messages.length - 1]
																			.value,
																		chat._id
																  )
																: chat.messages[chat.messages.length - 1]
																		.type == 'file'
																? 'Datei'
																: chat.messages[chat.messages.length - 1]
																		.type == 'image'
																? 'Bild'
																: chat.messages[chat.messages.length - 1]
																		.type == 'video'
																? 'Video'
																: 'Nachricht')
														: 'Keine Nachrichten'}
												</Text>
											</View>
										</View>

										<Text style={tw.style('text-gray-500 text-sm')}>
											{getLastActiveTime(chat)}
										</Text>
									</TouchableOpacity>
								</Swipeable>
							);
						})}

				{!chats && (
					<View style={tw.style('flex-1 justify-center items-center')}>
						<ActivityIndicator size="large" color={tw.color('gray-400')} />
						<Text
							style={tw.style('text-gray-400 text-lg font-semibold py-6 px-8')}
						>
							Wird geladen...
						</Text>
					</View>
				)}

				{chats &&
					chats.filter((item) => item.status != 'closed').length === 0 && (
						<Text
							style={tw.style('text-gray-400 text-lg font-semibold py-6 px-8')}
						>
							Keine Chats vorhanden
						</Text>
					)}
			</ScrollView>

			<TouchableOpacity
				style={tw.style('absolute right-6 bottom-6 bg-accent p-4 rounded-full')}
				onPress={handlePresentModalPress}
			>
				<Image
					source={require('../../../assets/icons/close.png')}
					style={[tw.style('h-6 w-6'), {tintColor: tw.color('primary')}]}
				/>
			</TouchableOpacity>

			<BottomSheetModal
				ref={sheetRef}
				snapPoints={['55%']}
				style={tw.style('shadow-lg')}
				index={0}
				backdropComponent={() => {
					return (
						<TouchableOpacity
							style={tw.style('flex-1 absolute top-0 left-0 right-0 bottom-0')}
							onPress={() => {
								sheetRef.current?.close();
							}}
						/>
					);
				}}
				enableContentPanningGesture={Platform.OS != 'android'}
				enableHandlePanningGesture={Platform.OS != 'web'}
			>
				<CreateNewChat
					onCreateNewChat={(users) => {
						sheetRef.current?.close();
						createChat(users);
					}}
				/>
			</BottomSheetModal>
		</View>
	);
}

function CustomScrollView({children}) {
	return (
		<ScrollView style={tw.style('flex-1')} scrollEnabled>
			{children}
		</ScrollView>
	);
}

function CreateNewChat(props) {
	const [allUsers, setAllUsers] = React.useState(null);

	const token = useSelector((state) => state.appData.token);
	const userData = useSelector((state) => state.appData.userData);

	const dispatch = useDispatch();

	const [userSelection, setUserSelection] = React.useState([]);

	useEffect(() => {
		socket.emit('getUsers', {
			token,
			workspace: userData.workspace,
			userID: userData._id,
		});

		socket.on('allUser', (users) => {
			let filteredUsers = users.filter((user) => user._id != userData._id);
			setAllUsers(filteredUsers);
			dispatch(updateAllUsers(filteredUsers));
		});
	}, []);

	return (
		<View style={tw.style('flex-1 bg-white p-8 w-full max-w-[900px] mx-auto')}>
			<Text style={tw.style('text-primary text-4xl font-semibold')}>
				Neuen Chat erstellen
			</Text>
			<Text style={tw.style('text-gray-400 text-base mb-6')}>
				Wähle einen oder mehrere Kontakte aus, um einen neuen Chat zu erstellen.
			</Text>

			<CustomScrollView>
				{allUsers &&
					allUsers.map((user) => (
						<TouchableOpacity
							key={user._id}
							style={tw.style(
								'flex-row items-center justify-between py-4 border-b border-gray-200'
							)}
							onPress={() => {
								if (userSelection.includes(user._id)) {
									setUserSelection(
										userSelection.filter((id) => id !== user._id)
									);
								} else {
									setUserSelection([...userSelection, user._id]);
								}
							}}
						>
							<View style={tw.style('flex-row items-center')}>
								<View style={tw.style('flex-row items-center mr-4')}>
									<Image
										source={
											user.extendedData?.avatar
												? {
														uri:
															process.env.API_URL +
															'/' +
															user.extendedData.avatar +
															'?width=64&height=64&token=' +
															token,
												  }
												: User
										}
										style={tw.style('w-7 h-7 bg-[#F2F2F2] rounded-full ')}
										resizeMode={user.extendedData?.avatar ? 'cover' : 'contain'}
									/>
								</View>
								<View style={tw.style('flex')}>
									{user.extendedData.firstname ? (
										<>
											<Text style={tw.style('text-lg')}>
												{user.extendedData.firstname +
													' ' +
													user.extendedData.lastname}
											</Text>
										</>
									) : (
										<Text style={tw.style('text-lg')}>{user.username}</Text>
									)}
								</View>
							</View>
							{userSelection.includes(user._id) && (
								<Image
									source={CheckedIcon}
									style={tw.style('w-6 h-6')}
									resizeMode="contain"
								/>
							)}
						</TouchableOpacity>
					))}

				{!allUsers && (
					<View style={tw.style('flex-1 justify-center items-center')}>
						<ActivityIndicator size="large" color={tw.color('gray-400')} />
						<Text style={tw.style('text-gray-400 text-lg font-semibold')}>
							Wird geladen...
						</Text>
					</View>
				)}

				{allUsers && allUsers.length === 0 && (
					<View style={tw.style('flex-1 justify-center items-center')}>
						<Text style={tw.style('text-gray-500 text-base text-center')}>
							Aktuell gibt es keine weiteren Kontakte in der Organisation.
						</Text>
					</View>
				)}
			</CustomScrollView>
			<View style={tw.style('flex flex-row justify-end my-4')}>
				<TouchableOpacity
					style={tw.style('bg-accent p-4 px-6 rounded-full')}
					onPress={() => props.onCreateNewChat(userSelection)}
				>
					<Text style={tw.style('font-semibold text-base text-white')}>
						{userSelection.length > 1
							? 'Gruppenchat erstellen'
							: 'Chat erstellen'}
					</Text>
				</TouchableOpacity>
			</View>
		</View>
	);
}
