import {
	View,
	Text,
	TouchableOpacity,
	TextInput,
	TouchableWithoutFeedback,
	Image,
	Keyboard,
	ImageBackground,
	Platform,
	ScrollView,
} from 'react-native';
import React from 'react';
import tw from '../../lib/tailwind';

import LoginBGImage from '../../assets/images/auth/xd_vorlagen_login.png';
import {useNavigation} from '@react-navigation/native';

import VisibiltyIcon from '../../assets/icons/visibility.png';
import VisibiltyOffIcon from '../../assets/icons/visibility-off.png';
import axios from 'axios';
import {addHeNotification} from '../../lib/HeNotifications/HeNotificationList';
import {useDispatch, useSelector} from 'react-redux';
import {
	setMessageData,
	updateToken,
	updateUserData,
	updateWorkspaceData,
} from '../../redux/slices/appDataSlice';

import Checked from '../../assets/icons/checked.png';
import Unchecked from '../../assets/icons/unchecked.png';
import * as SecureStore from 'expo-secure-store';
import {KeyboardAwareScrollView} from 'react-native-keyboard-aware-scroll-view';

export default function Login() {
	const navigation = useNavigation();

	const pushNotificationToken = useSelector(
		(state) => state.appData.pushNotificationToken
	);

	const [email, setEmail] = React.useState('');
	const [emailError, setEmailError] = React.useState(false);
	const [password, setPassword] = React.useState('');
	const [passwordError, setPasswordError] = React.useState(false);

	const [passwordVisible, setPasswordVisible] = React.useState(false);

	const [persistLogin, setPersistLogin] = React.useState(false);

	const dispatch = useDispatch();

	async function login() {
		if (Platform.OS != 'web') {
			Keyboard.dismiss();
		}

		if (email.trim() === '') {
			setEmailError(true);
			return;
		}

		if (password.trim() === '') {
			setPassword('');
			setPasswordError(true);
			return;
		}
		console.log(process.env.API_URL);
		try {
			const response = await axios.post(process.env.API_URL + '/user/login', {
				username: email,
				password,
				pushNotificationToken: persistLogin ? pushNotificationToken : null,
				platform: Platform.OS,
			});

			console.log(response.data);

			dispatch(updateUserData(response.data.user));
			dispatch(updateToken(response.data.token));
			dispatch(setMessageData(response.data.lastMessages));
			dispatch(updateWorkspaceData(response.data.workspace));

			if (Platform.OS === 'web') {
				localStorage.setItem('token', response.data.token);
				localStorage.setItem('user', JSON.stringify(response.data.user));
				localStorage.setItem(
					'workspace',
					JSON.stringify(response.data.workspace)
				);
				localStorage.setItem(
					'lastMessages',
					JSON.stringify(response.data.lastMessages)
				);
			} else {
				if (persistLogin) {
					await SecureStore.setItemAsync('token', response.data.token);
					await SecureStore.setItemAsync(
						'user',
						JSON.stringify(response.data.user)
					);
					await SecureStore.setItemAsync(
						'workspace',
						JSON.stringify(response.data.workspace)
					);
					await SecureStore.setItemAsync(
						'lastMessages',
						JSON.stringify(response.data.lastMessages)
					);
				}
			}

			navigation.navigate('Home');

			addHeNotification({
				title: 'Login',
				message: response.data.message,
				type: 'success',
				duration: 5000,
			});
		} catch (error) {
			if (typeof error.response !== 'undefined') {
				addHeNotification({
					title: 'Login',
					message: error.response.data.message,
					type: 'error',
					duration: 5000,
				});
				console.log(error.response.data);
			} else {
				addHeNotification({
					title: 'Login',
					message: 'Verbindung zum Server konnte nicht hergestellt werden',
					type: 'error',
					duration: 5000,
				});
				console.log(error);
			}
		}
	}

	function navigateToRegister() {
		if (Platform.OS != 'web') {
			Keyboard.dismiss();
		}
		navigation.navigate('Register');
	}

	return (
		<ImageBackground
			source={LoginBGImage}
			resizeMode={'contain'}
			style={tw.style('flex-1 items-center')}
		>
			<TouchableWithoutFeedback
				onPress={() => {
					if (Platform.OS != 'web') {
						Keyboard.dismiss();
					}
				}}
			>
				<View
					style={tw.style(
						'flex-1' +
							(Platform.OS == 'web'
								? ' max-w-[500px] w-2/3 min-w-[350px]'
								: ' w-full')
					)}
				>
					<View style={tw.style('w-full h-5/11')}></View>
					<KeyboardAwareScrollView
						contentContainerStyle={tw.style(
							'justify-start items-center px-12 pb-12'
						)}
						enableOnAndroid={true}
						enableAutomaticScroll={true}
						scrollEnabled={true}
						keyboardShouldPersistTaps="handled"
					>
						<Text
							style={tw.style('w-full mb-10 text-4xl text-accent font-medium')}
						>
							willkommen
						</Text>

						<View style={tw.style('w-full')}>
							<View
								style={tw.style(
									' w-full border-b border-opacity-35 pb-3' +
										(emailError ? ' border-red-500' : ' border-gray-400')
								)}
							>
								<TextInput
									keyboardType="default"
									placeholder="benutzername"
									style={tw.style('text-lg text-gray-700 leading-tight')}
									onChangeText={(text) => {
										setEmail(text);
										setEmailError(false);
									}}
									value={email}
									placeholderTextColor={tw.color(
										emailError ? 'red-300' : 'gray-300'
									)}
									textContentType="username"
									spellCheck={false}
									autoCapitalize="none"
									clearButtonMode="while-editing"
								/>
							</View>
							<View
								style={tw.style(
									'w-full flex flex-row border-b border-opacity-35 pb-3 pt-6' +
										(passwordError ? ' border-red-500' : ' border-gray-400')
								)}
							>
								<TextInput
									keyboardType="default"
									secureTextEntry={!passwordVisible}
									placeholder="passwort"
									style={tw.style(
										'flex-1 leading-tight text-lg text-gray-700 '
									)}
									onChangeText={(text) => {
										setPassword(text);
										setPasswordError(false);
									}}
									placeholderTextColor={tw.color(
										passwordError ? 'red-300' : 'gray-300'
									)}
									value={password}
									clearButtonMode="while-editing"
								/>
								<TouchableOpacity
									onPress={() => setPasswordVisible(!passwordVisible)}
									style={tw.style('justify-center items-center')}
								>
									<Image
										source={passwordVisible ? VisibiltyOffIcon : VisibiltyIcon}
									/>
								</TouchableOpacity>
							</View>
						</View>
						{Platform.OS != 'web' && (
							<TouchableOpacity
								onPress={() => {
									setPersistLogin(!persistLogin);
								}}
								style={tw.style('w-full mt-6 flex-row items-center')}
							>
								<Image
									source={persistLogin ? Checked : Unchecked}
									style={tw.style('h-6 w-6 mr-3')}
									resizeMode="contain"
								/>
								<Text style={tw.style('w-full text-gray-500')}>
									Angemeldet bleiben
								</Text>
							</TouchableOpacity>
						)}
						<View style={tw.style('w-full')}>
							<TouchableOpacity
								activeOpacity={0.8}
								style={tw.style(
									'w-full mt-14 rounded-full py-3 bg-accent self-start'
								)}
								onPress={() => login()}
							>
								<Text
									style={tw.style('text-primary text-center text-xl font-bold')}
								>
									anmelden
								</Text>
							</TouchableOpacity>

							<TouchableOpacity
								activeOpacity={0.8}
								style={tw.style(
									'w-full mt-2 rounded-full py-3 bg-primary self-start'
								)}
								onPress={() => navigateToRegister()}
							>
								<Text
									style={tw.style('text-accent text-center text-xl font-bold')}
								>
									registrieren
								</Text>
							</TouchableOpacity>

							<TouchableOpacity
								activeOpacity={0.8}
								style={tw.style(
									'w-full mt-7 rounded-full py-3 bg-transparent self-start'
								)}
								onPress={() => {
									navigation.navigate('ForgotPassword', {
										email,
									});
								}}
							>
								<Text
									style={tw.style('text-accent text-center text-xl font-bold')}
								>
									passwort vergessen
								</Text>
							</TouchableOpacity>
						</View>
					</KeyboardAwareScrollView>
				</View>
			</TouchableWithoutFeedback>
		</ImageBackground>
	);
}
