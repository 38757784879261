import CryptoJS from 'crypto-js';
import CryptoES from 'crypto-es';

// import hashKey from env
// WARNING: IF THIS KEY IS LOST OR CHANGED, ALL ENCRYPTED DATA WILL BE LOST

let hashKey = process.env.E2E_HASH_KEY;

export function encrypt(text, key) {
	let hashedKey = key + hashKey + key;

	return CryptoES.AES.encrypt(text, hashedKey).toString();
}

export function decrypt(text, key) {
	let hashedKey = key + hashKey + key;

	return CryptoES.AES.decrypt(text, hashedKey).toString(CryptoES.enc.Utf8);
}
