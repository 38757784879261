import {View, Text, Platform} from 'react-native';
import React from 'react';
import {createNativeStackNavigator} from '@react-navigation/native-stack';
import Chat from './chat';
import SingleChat from './singleChat';
import HeaderBar from '../../../components/headerBar';
import ChatHeader from '../../../components/chatHeader';
import {useEffect} from 'react';
import socket from '../../../lib/socket';
import {useDispatch} from 'react-redux';
import {updateChatByID} from '../../../redux/slices/appDataSlice';
import {createMaterialTopTabNavigator} from '@react-navigation/material-top-tabs';
import tw from '../../../lib/tailwind';
import ChatArchive from './ChatArchive';
import ChatDetails from './ChatDetails';

export default function ChatIndex() {
	const Stack = createNativeStackNavigator();

	const dispatch = useDispatch();

	useEffect(() => {
		socket.on('newMessage', (data) => {
			dispatch(updateChatByID(data.updatedChat));
		});
	}, []);

	return (
		<Stack.Navigator
			screenOptions={{
				header: () => <HeaderBar />,
			}}
			initialRouteName="Chats"
		>
			<Stack.Screen name="Chats" component={ChatTopTab} />
			<Stack.Screen
				name="SingleChat"
				component={SingleChat}
				options={{headerShown: false, title: 'Chat'}}
			/>
			<Stack.Screen
				name="ChatDetails"
				component={ChatDetails}
				options={{headerShown: false, title: 'Chat Details'}}
			/>
		</Stack.Navigator>
	);
}

function ChatTopTab() {
	const Tab = createMaterialTopTabNavigator();

	return (
		<Tab.Navigator
			screenOptions={{
				tabBarActiveTintColor: '#000',
				tabBarIndicatorStyle: {
					backgroundColor: tw.color('accent'),
				},
				tabBarLabelStyle: {
					fontSize: 13,
					textTransform: 'none',
				},
				tabBarStyle: {
					backgroundColor: '#fff',
				},
				swipeEnabled: Platform.OS != 'web' ? true : false,
			}}
		>
			<Tab.Screen
				name="Chat"
				component={Chat}
				options={{
					title: 'Chat',
				}}
			/>
			<Tab.Screen
				name="Archive"
				component={ChatArchive}
				options={{
					title: 'Archiv',
				}}
			/>
		</Tab.Navigator>
	);
}
