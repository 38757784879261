import {View, Text} from 'react-native';
import React, {useEffect} from 'react';
import {createBottomTabNavigator} from '@react-navigation/bottom-tabs';
import Home from './home';
import Chat from './chat/chatIndex';
import TabBar from '../../components/tabBar';
import Pinboard from './pinboard/pinboardIndex';
import tw from '../../lib/tailwind';
import HeaderBar from '../../components/headerBar';
import socket from '../../lib/socket';
import {addHeNotification} from '../../lib/HeNotifications/HeNotificationList';
import {useDispatch, useSelector} from 'react-redux';
import {updateServerConnected} from '../../redux/slices/appDataSlice';
import TodoIndex from './todo';
import WorkspaceInfo from './workspaceInfo/workspaceInfo';

export default function HomeIndex() {
	const Tab = createBottomTabNavigator();

	const dispatch = useDispatch();

	let connectionError = false;

	const workspaceData = useSelector((state) => state.appData.workspaceData);
	const token = useSelector((state) => state.appData.token);

	const userInfo = useSelector((state) => state.appData.userData);

	useEffect(() => {
		socket.on('connect', () => {
			console.log('connected');

			socket.emit('registerSocketID', {
				token,
			});

			dispatch(updateServerConnected(true));

			if (connectionError) {
				addHeNotification({
					title: 'Verbindung wiederhergestellt',
					message: 'Die Verbindung zum Server wurde wiederhergestellt',
					type: 'success',
				});
			}
			connectionError = false;
		});

		socket.on('connect_error', (err) => {
			console.log(`connect_error due to ${err.message}`, connectionError);

			// send notification only once
			if (!connectionError) {
				addHeNotification({
					title: 'Verbindungsfehler',
					message: 'Es besteht keine Verbindung zum Server',
					type: 'error',
				});
				dispatch(updateServerConnected(false));
			}

			connectionError = true;
		});

		socket.on('disconnect', () => {
			console.log('disconnected');
			dispatch(updateServerConnected(false));
		});

		return () => {
			socket.off('connect');
			socket.off('connect_error');
			socket.off('disconnect');
		};
	}, []);

	useEffect(() => {
		console.log('registerSocketID', userInfo._id);
		socket.emit('registerSocketID', {
			id: userInfo._id,
		});
	}, [userInfo]);

	return (
		<View style={tw.style('flex-1')}>
			<Tab.Navigator
				tabBar={(props) => <TabBar {...props} />}
				screenOptions={{
					header: () => <HeaderBar />,
					tabBarHideOnKeyboard: true,
				}}
			>
				<Tab.Screen name="Home" component={Home} />
				<Tab.Screen
					name="PinIndex"
					component={Pinboard}
					options={{headerShown: false, title: 'Pinboard'}}
				/>
				<Tab.Screen
					name="ChatIndex"
					component={Chat}
					options={{headerShown: false, title: 'Chat'}}
				/>
				<Tab.Screen
					name="TodoIndex"
					component={TodoIndex}
					options={{headerShown: false, title: 'Todo'}}
				/>
				{workspaceData && userInfo.role == 'admin' && (
					<Tab.Screen name="Info" component={WorkspaceInfo} />
				)}
			</Tab.Navigator>
		</View>
	);
}
