import {
	View,
	Text,
	TouchableOpacity,
	TextInput,
	TouchableWithoutFeedback,
	Keyboard,
	ImageBackground,
	Image,
	Platform,
	ScrollView,
} from 'react-native';
import React from 'react';
import tw from '../../lib/tailwind';

import RegisterBGImage from '../../assets/images/auth/xd_vorlagen_register.png';
import VisibiltyIcon from '../../assets/icons/visibility.png';
import VisibiltyOffIcon from '../../assets/icons/visibility-off.png';

import {useNavigation} from '@react-navigation/native';
import axios from 'axios';
import {addHeNotification} from '../../lib/HeNotifications/HeNotificationList';
import {
	setMessageData,
	updateToken,
	updateUserData,
	updateWorkspaceData,
} from '../../redux/slices/appDataSlice';
import {useDispatch, useSelector} from 'react-redux';
import {KeyboardAwareScrollView} from 'react-native-keyboard-aware-scroll-view';

export default function SetPassword(props) {
	const navigation = useNavigation();

	const [password, setPassword] = React.useState('');
	const [passwordError, setPasswordError] = React.useState(false);
	const [passwordVisible, setPasswordVisible] = React.useState(false);

	const [passwordRepeat, setPasswordRepeat] = React.useState('');
	const [passwordRepeatError, setPasswordRepeatError] = React.useState(false);
	const [passwordRepeatVisible, setPasswordRepeatVisible] =
		React.useState(false);

	const params = props.route.params;

	const dispatch = useDispatch();

	const pushNotificationToken = useSelector(
		(state) => state.appData.pushNotificationToken
	);

	async function register() {
		if (Platform.OS != 'web') {
			Keyboard.dismiss();
		}

		let tempPasswordRepeatError = passwordRepeat.trim() === '';
		let tempPasswordError = password.trim() === '';

		setPasswordError(tempPasswordError);

		setPasswordRepeatError(tempPasswordRepeatError);

		if (tempPasswordError || tempPasswordRepeatError) {
			if (tempPasswordError && tempPasswordRepeatError) {
				addHeNotification({
					title: 'Passwort',
					message: 'Diese Felder dürfen nicht leer sein',
					type: 'error',
					duration: 5000,
				});
			} else {
				addHeNotification({
					title: 'Passwort',
					message: 'Dieses Feld darf nicht leer sein',
					type: 'error',
					duration: 5000,
				});
			}
			return;
		}

		if (password !== passwordRepeat) {
			addHeNotification({
				title: 'Passwort',
				message: 'Die Passwörter stimmen nicht überein',
				type: 'error',
				duration: 5000,
			});
			setPasswordError(true);
			setPasswordRepeatError(true);
			return;
		}

		if (params.mode == 'user') {
			try {
				const response = await axios.post(process.env.API_URL + '/user', {
					username: params.username,
					accessCode: params.accessCode,
					password,
					pushNotificationToken,
					platform: Platform.OS,
				});

				dispatch(updateUserData(response.data.user));
				dispatch(setMessageData(response.data.lastMessages));
				dispatch(updateToken(response.data.token));

				addHeNotification({
					title: 'Account erstellen',
					message: response.data.message,
					type: 'success',
					duration: 5000,
				});

				navigation.navigate('App');
			} catch (error) {
				console.log(error.response.data);
				addHeNotification({
					title: 'Account erstellen',
					message: error.response.data.message,
					type: 'error',
					duration: 5000,
				});
			}

			return;
		}

		if (params.mode == 'workspace') {
			try {
				const response = await axios.post(process.env.API_URL + '/workspace', {
					username: params.username,
					workspaceName: params.organisationName,
					password,
					pushNotificationToken,
					platform: Platform.OS,
				});

				dispatch(updateUserData(response.data.user));
				dispatch(setMessageData(response.data.lastMessages));
				dispatch(updateWorkspaceData(response.data.workspace));
				dispatch(updateToken(response.data.token));

				addHeNotification({
					title: 'Organisation erstellen',
					message: response.data.message,
					type: 'success',
					duration: 5000,
				});

				navigation.navigate('App');
			} catch (error) {
				console.log(error.response);
				addHeNotification({
					title: 'Organisation erstellen',
					message: error.response.data.message,
					type: 'error',
					duration: 5000,
				});
			}

			return;
		}
	}

	function navigateGoBack() {
		if (Platform.OS != 'web') {
			Keyboard.dismiss();
		}
		navigation.goBack();
	}

	return (
		<ImageBackground
			source={RegisterBGImage}
			resizeMode={'contain'}
			style={tw.style('flex-1 items-center')}
			zIndex={0}
		>
			<TouchableWithoutFeedback
				onPress={() => {
					if (Platform.OS != 'web') {
						Keyboard.dismiss();
					}
				}}
			>
				<View
					style={tw.style(
						'flex-1' +
							(Platform.OS == 'web'
								? ' max-w-[500px] w-2/3 min-w-[350px]'
								: ' w-full')
					)}
				>
					<View style={tw.style('w-full h-5/11')}></View>
					<KeyboardAwareScrollView
						contentContainerStyle={tw.style(
							'justify-start items-center px-12 pb-12'
						)}
						enableOnAndroid={true}
						enableAutomaticScroll={true}
						scrollEnabled={true}
						keyboardShouldPersistTaps="handled"
					>
						<Text style={tw.style('w-full text-4xl text-accent font-light')}>
							hallo,
						</Text>
						<Text
							style={tw.style('w-full mb-10 text-4xl text-accent font-medium')}
						>
							{params?.username || 'username'}
						</Text>

						<View style={tw.style('w-full')}>
							<View
								style={tw.style(
									'w-full flex flex-row border-b border-opacity-35 pb-3 pt-6' +
										(passwordError ? ' border-red-500' : ' border-gray-400')
								)}
							>
								<TextInput
									keyboardType="default"
									secureTextEntry={!passwordVisible}
									placeholder="passwort"
									style={tw.style('flex-1 leading-tight text-lg text-gray-700')}
									onChangeText={(text) => {
										setPassword(text);
										setPasswordError(false);
									}}
									placeholderTextColor={tw.color(
										passwordError ? 'red-300' : 'gray-300'
									)}
									value={password}
									clearButtonMode="while-editing"
								/>
								<TouchableOpacity
									onPress={() => setPasswordVisible(!passwordVisible)}
									style={tw.style('justify-center items-center')}
								>
									<Image
										source={passwordVisible ? VisibiltyOffIcon : VisibiltyIcon}
									/>
								</TouchableOpacity>
							</View>
							<View
								style={tw.style(
									'w-full flex flex-row border-b border-opacity-35 pb-3 pt-6' +
										(passwordRepeatError
											? ' border-red-500'
											: ' border-gray-400')
								)}
							>
								<TextInput
									keyboardType="default"
									secureTextEntry={!passwordRepeatVisible}
									placeholder="passwort wiederholen"
									style={tw.style('flex-1 leading-tight text-lg text-gray-700')}
									onChangeText={(text) => {
										setPasswordRepeat(text);
										setPasswordRepeatError(false);
									}}
									placeholderTextColor={tw.color(
										passwordRepeatError ? 'red-300' : 'gray-300'
									)}
									value={passwordRepeat}
									clearButtonMode="while-editing"
								/>
								<TouchableOpacity
									onPress={() =>
										setPasswordRepeatVisible(!passwordRepeatVisible)
									}
									style={tw.style('justify-center items-center')}
								>
									<Image
										source={
											passwordRepeatVisible ? VisibiltyOffIcon : VisibiltyIcon
										}
									/>
								</TouchableOpacity>
							</View>
						</View>

						<View style={tw.style('w-full')}>
							<TouchableOpacity
								activeOpacity={0.8}
								style={tw.style(
									'w-full mt-14 rounded-full py-3 bg-accent self-start'
								)}
								onPress={() => register()}
							>
								<Text
									style={tw.style('text-primary text-center text-xl font-bold')}
								>
									erstellen
								</Text>
							</TouchableOpacity>

							<TouchableOpacity
								activeOpacity={0.8}
								style={tw.style(
									'w-full mt-2 rounded-full py-3 bg-primary self-start'
								)}
								onPress={() => navigateGoBack()}
							>
								<Text
									style={tw.style('text-accent text-center text-xl font-bold')}
								>
									zurück
								</Text>
							</TouchableOpacity>
						</View>
					</KeyboardAwareScrollView>
				</View>
			</TouchableWithoutFeedback>
		</ImageBackground>
	);
}
