import {View, Text, Platform} from 'react-native';
import React from 'react';
import {createNativeStackNavigator} from '@react-navigation/native-stack';
import HeaderBar from '../../../components/headerBar';
import Todo from './todo';
import TodoDetail from './todoDetail';
import {createMaterialTopTabNavigator} from '@react-navigation/material-top-tabs';
import tw from '../../../lib/tailwind';
import Archive from './archive';

export default function TodoIndex() {
	const Stack = createNativeStackNavigator();

	return (
		<Stack.Navigator
			screenOptions={{
				header: () => <HeaderBar />,
			}}
			initialRouteName="Todo"
		>
			<Stack.Screen name="Todo" component={ToDoTopTab} />
			<Stack.Screen
				name="TodoDetail"
				component={TodoDetail}
				options={{
					headerShown: true,
					title: 'Todo Detail',
				}}
			/>
		</Stack.Navigator>
	);
}

function ToDoTopTab() {
	const Tab = createMaterialTopTabNavigator();

	return (
		<Tab.Navigator
			screenOptions={{
				tabBarActiveTintColor: '#000',
				tabBarIndicatorStyle: {
					backgroundColor: tw.color('accent'),
				},
				tabBarLabelStyle: {
					fontSize: 13,
					textTransform: 'none',
				},
				tabBarStyle: {
					backgroundColor: '#fff',
				},
				swipeEnabled: Platform.OS != 'web' ? true : false,
			}}
		>
			<Tab.Screen
				name="ToDo"
				component={Todo}
				options={{
					title: 'Todo',
				}}
			/>
			<Tab.Screen
				name="Archive"
				component={Archive}
				options={{
					title: 'Archiv',
				}}
			/>
		</Tab.Navigator>
	);
}
