import React from 'react';
import {useEffect} from 'react';
import {
	FlatList,
	Image,
	ImageBackground,
	Platform,
	Text,
	TouchableOpacity,
	View,
} from 'react-native';
import {useDispatch, useSelector} from 'react-redux';
import tw from '../../lib/tailwind';
import axios from 'axios';

import Arrow from '../../assets/icons/arrow-right.png';

import dateFormat from 'dateformat';
import {updateAllNotifications} from '../../redux/slices/appDataSlice';
import {addHeNotification} from '../../lib/HeNotifications/HeNotificationList';
import {useNavigation} from '@react-navigation/native';
import socket from '../../lib/socket';

function Home() {
	const userInfo = useSelector((state) => state.appData.userData);
	const token = useSelector((state) => state.appData.token);
	const allNotifications = useSelector(
		(state) => state.appData.allNotifications
	);

	const dispatch = useDispatch();
	const navigation = useNavigation();

	const [webActivities, setWebActivities] = React.useState([]);

	const [helloMessage, setHelloMessage] = React.useState('moin');
	const [date, setDate] = React.useState(new Date());

	function handleClockUpdate() {
		const hour = date.getHours();

		if (hour < 12) {
			setHelloMessage('guten morgen');
		} else if (hour > 18) {
			setHelloMessage('guten abend');
		} else {
			setHelloMessage('moin');
		}
	}

	useEffect(() => {
		handleClockUpdate();
	}, [date]);

	async function getAllNotifications() {
		try {
			const response = await axios.get(
				`${process.env.API_URL}/user/notifications`,
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			);

			dispatch(updateAllNotifications(response.data.notifications));
		} catch (error) {
			if (typeof error.response !== 'undefined') {
				addHeNotification({
					title: 'Aktivitäten abrufen',
					message: error.response.data.message,
					type: 'error',
					duration: 5000,
				});
				console.log(error.response.data);
			} else {
				addHeNotification({
					title: 'Aktivitäten abrufen',
					message:
						'Beim Abrufen der Aktivitäten ist ein unbekannter Fehler aufgetreten.',
					type: 'error',
					duration: 5000,
				});
				console.log(error);
			}
		}
	}

	async function getCurrentWebsiteActivities() {
		try {
			const response = await axios.get(
				`https://pyng-app.de/wp-json/wp/v2/appnews`
			);

			setWebActivities(response.data);
		} catch (error) {
			console.log(error);
		}
	}

	useEffect(() => {
		const interval = setInterval(() => {
			setDate(new Date());
			handleClockUpdate();
		}, 10000);

		getAllNotifications();

		socket.on('newNotification', (notification) => {
			console.log('start screen : ', notification);
			getAllNotifications();
			// addHeNotification({
			// 	title: 'Neue Aktivität',
			// 	message: notification.message.body,
			// 	type: 'info',
			// 	duration: 2000,
			// });
		});

		getCurrentWebsiteActivities();

		return () => clearInterval(interval);
	}, []);

	function combineNotifications() {
		let apiNotifications = allNotifications.map((notification) => {
			return {
				...notification,
				notificationType: 'api',
				date: notification.createdAt,
			};
		});

		let webNotifications = webActivities.map((notification) => {
			return {
				...notification,
				notificationType: 'web',
				date: notification.modified,
				_id: notification.id,
			};
		});

		let combinedNotifications = [...apiNotifications, ...webNotifications];

		combinedNotifications.sort((a, b) => {
			return new Date(b.date) - new Date(a.date);
		});

		return combinedNotifications;
	}

	return (
		<View style={tw.style('flex-1')}>
			<ImageBackground
				source={
					Platform.OS == 'web'
						? require('../../assets/images/homeBG.svg')
						: require('../../assets/images/homeBG.png')
				}
				style={tw.style('flex-1 py-12 px-8')}
				resizeMode="cover"
			>
				<View
					style={tw.style(
						'w-full flex flex-row justify-between items-center max-w-[900px] mx-auto'
					)}
				>
					<View style={tw.style('w-1/2 flex justify-center items-center')}>
						<View style={tw.style('border-t-4 border-white border-b-4')}>
							<Text
								style={[
									tw.style(
										'text-7xl font-bold text-white py-3 border-white w-full text-center'
									),
								]}
							>
								{dateFormat(date, 'HH:MM')}
							</Text>
						</View>
					</View>
					<View style={tw.style('w-1/2')}>
						<Text style={tw.style('text-2xl text-white')}>{helloMessage}</Text>
						{userInfo.extendedData && (
							<Text style={tw.style('text-2xl text-white')}>
								{userInfo.extendedData?.firstName === ''
									? userInfo.username
									: userInfo.extendedData.firstName +
									  ' ' +
									  userInfo.extendedData.lastName}
							</Text>
						)}
					</View>
				</View>
				<View style={tw.style('w-full flex-1 py-8 max-w-[900px] mx-auto')}>
					<FlatList
						data={combineNotifications()}
						renderItem={({item}) => {
							switch (item.notificationType) {
								case 'web':
									return <WebNotification item={item} />;
									break;
								case 'api':
									return (
										<PushNotification
											item={item}
											onPress={(screenData) => {
												navigation.navigate(screenData.screen, {
													...screenData.params,
												});
											}}
										/>
									);
									break;
								default:
									return <></>;
									break;
							}
						}}
						keyExtractor={(item) => item._id}
						ListEmptyComponent={() => {
							return (
								<View style={tw.style('w-full flex flex-row px-6 mb-6')}>
									<View
										style={tw.style(
											'w-3/4 h-auto p-6 bg-white rounded-3xl flex justify-center'
										)}
									>
										<Text style={tw.style('text-2xl text-primary font-bold')}>
											Aktivitäten
										</Text>
										<Text
											style={tw.style('text-lg text-accent font-bold mt-2')}
										>
											{dateFormat(date, 'dd.mm.yyyy')}
										</Text>
										<Text style={tw.style('text-lg text-accent')}>
											Aktuell keine Aktivitäten
										</Text>
									</View>
									<View
										style={tw.style('w-1/4 flex flex-row justify-end')}
									></View>
								</View>
							);
						}}
					/>
				</View>
			</ImageBackground>
		</View>
	);
}

function WebNotification({item}) {
	return (
		<View key={item.id} style={tw.style('w-full flex flex-row px-6 mb-6')}>
			<View
				style={tw.style(
					'w-3/4 h-auto p-6 bg-white rounded-3xl flex justify-center'
				)}
			>
				<Text style={tw.style('text-2xl text-primary font-bold')}>
					{item.title.rendered.replace(/(<([^>]+)>)/gi, '') || 'Aktivität'}
				</Text>
				<Text style={tw.style('text-lg text-accent font-bold mt-2')}>
					{dateFormat(item.modified, 'dd.mm.yyyy')}
				</Text>
				<Text style={tw.style('text-lg text-accent mt--4')}>
					{item.content.rendered.replace(/(<([^>]+)>)/gi, '')}
				</Text>
			</View>
			<View style={tw.style('w-1/4 flex flex-row justify-end')}></View>
		</View>
	);
}

function PushNotification({item, onPress = () => {}}) {
	return (
		<View key={item._id} style={tw.style('w-full flex flex-row px-6 mb-6')}>
			<View
				style={tw.style(
					'w-3/4 h-auto p-6 bg-white rounded-3xl flex justify-center'
				)}
			>
				<Text style={tw.style('text-2xl text-primary font-bold')}>
					Aktivitäten
				</Text>
				<Text style={tw.style('text-lg text-accent font-bold mt-2')}>
					{dateFormat(item.createdAt, 'dd.mm.yyyy')}
				</Text>
				<Text style={tw.style('text-lg text-accent')}>{item.message.body}</Text>
			</View>
			<View style={tw.style('w-1/4 flex flex-row justify-end')}>
				<TouchableOpacity
					style={tw.style(
						'w-15 h-15 bg-white rounded-full flex items-center justify-center'
					)}
					onPress={() => {
						if (item.message.screenData) {
							onPress(item.message.screenData);
						}
					}}
				>
					<Image
						source={Arrow}
						style={tw.style('flex-1 tint-accent w-5 h-5')}
						resizeMode="contain"
					/>
				</TouchableOpacity>
			</View>
		</View>
	);
}

export default Home;
