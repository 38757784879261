import {View, Text, Image, TouchableWithoutFeedback} from 'react-native';
import React from 'react';
import {useSelector} from 'react-redux';
import tw from '../../lib/tailwind';
import dateFormat from 'dateformat';
import User from '../../assets/icons/user.png';
import ImageView from 'react-native-image-viewing';

export default function ImageMessage({value, createdAt, createdBy, _id}) {
	const userInfo = useSelector((state) => state.appData.userData);
	const token = useSelector((state) => state.appData.token);
	const allUsers = useSelector((state) => state.appData.allUsers);

	const isUser = userInfo._id === createdBy;

	const [visible, setIsVisible] = React.useState(false);

	if (isUser) {
		return (
			<TouchableWithoutFeedback
				onPress={() => {
					setIsVisible(true);
				}}
			>
				<View
					style={tw.style(
						'bg-transparent px-4 py-2 w-full flex-col-reverse justify-end items-end'
					)}
				>
					<Text style={tw.style('text-xs text-gray-400 font-light mr-4')}>
						{dateFormat(createdAt, 'HH:MM')}
					</Text>
					<View style={tw.style('bg-primary rounded-2xl p-1 rounded-br-none')}>
						<Image
							source={{
								uri: process.env.API_URL + '/' + value + '?token=' + token,
							}}
							style={tw.style('w-64 h-64 rounded-2xl')}
						/>
					</View>
					<ImageView
						images={[
							{
								uri: process.env.API_URL + '/' + value + '?token=' + token,
							},
						]}
						imageIndex={0}
						visible={visible}
						onRequestClose={() => setIsVisible(false)}
					/>
				</View>
			</TouchableWithoutFeedback>
		);
	}

	return (
		<TouchableWithoutFeedback
			onPress={() => {
				setIsVisible(true);
			}}
		>
			<View
				style={tw.style(
					'bg-transparent px-4 py-2 w-full flex-col justify-start items-start'
				)}
			>
				<View style={tw.style('bg-accent rounded-2xl p-1 rounded-bl-none')}>
					<Image
						source={{
							uri: process.env.API_URL + '/' + value + '?token=' + token,
						}}
						style={tw.style('w-64 h-64 rounded-2xl')}
					/>
				</View>
				<View style={tw.style('flex-row items-center')}>
					<View style={tw.style('flex-row items-center ')}>
						<Image
							source={
								allUsers.find((user) => user._id == createdBy)?.extendedData
									?.avatar
									? {
											uri:
												process.env.API_URL +
												'/' +
												allUsers.find((user) => user._id == createdBy)
													?.extendedData.avatar +
												'?width=64&height=64&token=' +
												token,
									  }
									: User
							}
							style={tw.style('w-6 h-6 my-2 mr-2 bg-[#F2F2F2] rounded-full')}
							resizeMode={
								allUsers.find((user) => user._id == createdBy)?.extendedData
									?.avatar
									? 'cover'
									: 'contain'
							}
						/>
					</View>
					<Text style={tw.style('text-xs text-gray-400 font-light mt-2')}>
						{(allUsers.find((user) => user._id == createdBy)?.extendedData
							.firstName === ''
							? allUsers.find((user) => user._id == createdBy)?.username
							: allUsers.find((user) => user._id == createdBy)?.extendedData
									.firstName +
							  ' ' +
							  allUsers.find((user) => user._id == createdBy)?.extendedData
									.lastName) +
							'  -  ' +
							dateFormat(createdAt, 'HH:MM')}
					</Text>
				</View>
				<ImageView
					images={[
						{
							uri: process.env.API_URL + '/' + value + '?token=' + token,
						},
					]}
					imageIndex={0}
					visible={visible}
					onRequestClose={() => setIsVisible(false)}
				/>
			</View>
		</TouchableWithoutFeedback>
	);
}
