import {
	View,
	Text,
	TouchableOpacity,
	TextInput,
	TouchableWithoutFeedback,
	Image,
	Keyboard,
	ImageBackground,
	Platform,
	ScrollView,
} from 'react-native';
import React from 'react';
import tw from '../../lib/tailwind';

import LoginBGImage from '../../assets/images/auth/xd_vorlagen_login.png';
import {useNavigation} from '@react-navigation/native';

import VisibiltyIcon from '../../assets/icons/visibility.png';
import VisibiltyOffIcon from '../../assets/icons/visibility-off.png';
import axios from 'axios';
import {addHeNotification} from '../../lib/HeNotifications/HeNotificationList';
import {useDispatch, useSelector} from 'react-redux';
import {
	setMessageData,
	updateToken,
	updateUserData,
	updateWorkspaceData,
} from '../../redux/slices/appDataSlice';

import Checked from '../../assets/icons/checked.png';
import Unchecked from '../../assets/icons/unchecked.png';
import * as SecureStore from 'expo-secure-store';
import {KeyboardAwareScrollView} from 'react-native-keyboard-aware-scroll-view';

export default function ResetPassword({route}) {
	const navigation = useNavigation();

	const passwordResetToken = route.params.token;
	const [password, setPassword] = React.useState('');
	const [passwordError, setPasswordError] = React.useState(false);

	const [repeatPassword, setRepeatPassword] = React.useState('');
	const [repeatPasswordError, setRepeatPasswordError] = React.useState(false);

	const [passwordVisible, setPasswordVisible] = React.useState(false);

	const dispatch = useDispatch();

	async function setNewPassword() {
		if (password == '') {
			setPasswordError(true);
			return;
		}

		if (repeatPassword == '') {
			setRepeatPasswordError(true);
			return;
		}

		if (password != repeatPassword) {
			setRepeatPasswordError(true);
			return;
		}

		try {
			const response = await axios.post(
				`${process.env.API_URL}/user/reset-password`,
				{
					password: password,
					token: passwordResetToken,
				}
			);

			if (response.status == 200) {
				// add notification
				addHeNotification({
					type: 'success',
					title: 'Passwort geändert',
					message: 'Dein Passwort wurde erfolgreich geändert.',
				});

				// navigate to home screen
				navigation.navigate('App');
			}
		} catch (error) {
			console.log(error);
			if (error.response) {
				if (error.response.status == 400) {
					addHeNotification({
						type: 'error',
						title: 'Fehler',
						message: 'Es gab ein Fehler beim zurücksetzen deines Passworts.',
					});
				}
			}
		}
	}

	return (
		<ImageBackground
			source={LoginBGImage}
			resizeMode={'contain'}
			style={tw.style('flex-1 items-center')}
		>
			<TouchableWithoutFeedback
				onPress={() => {
					if (Platform.OS != 'web') {
						Keyboard.dismiss();
					}
				}}
			>
				<View
					style={tw.style(
						'flex-1' +
							(Platform.OS == 'web'
								? ' max-w-[500px] w-2/3 min-w-[350px]'
								: ' w-full')
					)}
				>
					<View style={tw.style('w-full h-5/11')}></View>
					<KeyboardAwareScrollView
						contentContainerStyle={tw.style('justify-start items-center px-12')}
						enableOnAndroid={true}
						enableAutomaticScroll={true}
						scrollEnabled={true}
						keyboardShouldPersistTaps="handled"
					>
						<Text
							style={tw.style('w-full mb-10 text-4xl text-accent font-medium')}
						>
							password vergessen
						</Text>

						<View style={tw.style('w-full')}>
							<View
								style={tw.style(
									'w-full flex flex-row border-b border-opacity-35 pb-3 pt-6' +
										(passwordError ? ' border-red-500' : ' border-gray-400')
								)}
							>
								<TextInput
									keyboardType="default"
									secureTextEntry={!passwordVisible}
									placeholder="passwort"
									style={tw.style('flex-1 leading-tight text-lg text-gray-700')}
									onChangeText={(text) => {
										setPassword(text);
										setPasswordError(false);
									}}
									placeholderTextColor={tw.color(
										passwordError ? 'red-300' : 'gray-300'
									)}
									value={password}
									clearButtonMode="while-editing"
								/>
								<TouchableOpacity
									onPress={() => setPasswordVisible(!passwordVisible)}
									style={tw.style('justify-center items-center')}
								>
									<Image
										source={passwordVisible ? VisibiltyOffIcon : VisibiltyIcon}
									/>
								</TouchableOpacity>
							</View>
							<View
								style={tw.style(
									'w-full flex flex-row border-b border-opacity-35 pb-3 pt-6' +
										(passwordError ? ' border-red-500' : ' border-gray-400')
								)}
							>
								<TextInput
									keyboardType="default"
									secureTextEntry={!passwordVisible}
									placeholder="passwort"
									style={tw.style('flex-1 leading-tight text-lg text-gray-700')}
									onChangeText={(text) => {
										setRepeatPassword(text);
										setRepeatPasswordError(false);
									}}
									placeholderTextColor={tw.color(
										repeatPasswordError ? 'red-300' : 'gray-300'
									)}
									value={repeatPassword}
									clearButtonMode="while-editing"
								/>
								<TouchableOpacity
									onPress={() => setPasswordVisible(!passwordVisible)}
									style={tw.style('justify-center items-center')}
								>
									<Image
										source={passwordVisible ? VisibiltyOffIcon : VisibiltyIcon}
									/>
								</TouchableOpacity>
							</View>
						</View>

						<View style={tw.style('w-full')}>
							<TouchableOpacity
								activeOpacity={0.8}
								style={tw.style(
									'w-full mt-14 rounded-full py-3 bg-accent self-start'
								)}
								onPress={() => setNewPassword()}
							>
								<Text
									style={tw.style('text-primary text-center text-xl font-bold')}
								>
									passwort zurücksetzen
								</Text>
							</TouchableOpacity>

							<TouchableOpacity
								activeOpacity={0.8}
								style={tw.style(
									'w-full mt-7 rounded-full py-3 bg-transparent self-start'
								)}
								onPress={() => {
									navigation.reset({
										index: 0,
										routes: [{name: 'App'}],
									});
								}}
							>
								<Text
									style={tw.style('text-primary text-center text-xl font-bold')}
								>
									Startseite
								</Text>
							</TouchableOpacity>
						</View>
					</KeyboardAwareScrollView>
				</View>
			</TouchableWithoutFeedback>
		</ImageBackground>
	);
}
