import {DeviceEventEmitter, Text, View} from 'react-native';
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import HeNotificationItem from './HeNotificationItem';
import tw from '../tailwind';
import {SafeAreaView} from 'react-native-safe-area-context';

export default class HeNotificationList extends Component {
	state = {
		notification: [],
		notificationListener: null,
	};

	constructor(props) {
		super(props);
		this.state = {
			notification: [],
		};
	}

	componentDidMount() {
		this.notificationListener = DeviceEventEmitter.addListener(
			'addHeNotification',
			(notification) => {
				let temp = this.state.notification;

				this.setState({
					notification: [
						{...notification, id: Math.random().toString(36).substr(2, 9)},
						...temp,
					],
				});
			}
		);
	}

	componentWillUnmount() {
		this.notificationListener.remove();
		this.setState({
			notification: [],
		});
	}

	removeNotification(id) {
		let temp = this.state.notification;
		this.setState({
			notification: temp.filter((item) => item.id !== id),
		});
	}

	render() {
		return (
			<SafeAreaView
				edges={['top']}
				style={tw.style('absolute top-0 left-0 right-0 z-90')}
			>
				{this.state.notification.map((item, index) => {
					return (
						<HeNotificationItem
							{...item}
							onTimeout={() => {
								this.removeNotification(item.id);
								item.onTimeout();
							}}
							onClose={() => {
								this.removeNotification(item.id);
								item.onClose();
							}}
							currentListIndex={index}
							key={item.id}
						/>
					);
				})}
			</SafeAreaView>
		);
	}
}

function addHeNotification({
	title = 'HeNotificationList',
	message = 'HeNotificationList',
	type = 'success',
	duration = 10000,
	onClose = () => {},
	onClick = () => {},
	onTimeout = () => {},
}) {
	DeviceEventEmitter.emit('addHeNotification', {
		title,
		message,
		type,
		duration,
		onClose,
		onClick,
		onTimeout,
	});
}

addHeNotification.propTypes = {
	title: PropTypes.string,
	message: PropTypes.string,
	type: PropTypes.string,
};

export {addHeNotification};
