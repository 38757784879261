import {View, Text, Image} from 'react-native';
import React from 'react';
import {createNativeStackNavigator} from '@react-navigation/native-stack';
import HeaderBar from '../../../components/headerBar';
import Pinboard from './pinboard';
import PinboardDetail from './pinboardDetail';
import tw from '../../../lib/tailwind';
import {TouchableOpacity} from 'react-native-gesture-handler';
import {useNavigation} from '@react-navigation/native';

export default function PinIndex() {
	const Stack = createNativeStackNavigator();

	const navigation = useNavigation();

	return (
		<Stack.Navigator initialRouteName="Boards">
			<Stack.Screen
				name="Boards"
				component={Pinboard}
				options={{
					header: () => <HeaderBar />,
				}}
			/>
			<Stack.Screen
				name="BoardDetail"
				component={PinboardDetail}
				options={{
					headerShown: true,
					headerTintColor: tw.color('accent'),
					headerTitleStyle: tw.style('text-lg font-bold'),
					title: 'Pinboard',
				}}
			/>
		</Stack.Navigator>
	);
}
