import {View, Text, ScrollView, RefreshControl} from 'react-native';
import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {addHeNotification} from '../lib/HeNotifications/HeNotificationList';
import {useState} from 'react';
import {useEffect} from 'react';
import {useNavigation} from '@react-navigation/native';
import tw from '../lib/tailwind';

import dateFormat from 'dateformat';
import axios from 'axios';
import {updateAllNotifications} from '../redux/slices/appDataSlice';

export default function ShowNotifications() {
	const notifications = useSelector((state) => state.appData.allNotifications);
	const token = useSelector((state) => state.appData.token);

	const [refreshing, setRefreshing] = React.useState(false);

	const navigation = useNavigation();
	const dispatch = useDispatch();

	useEffect(() => {
		const unsubscribe = navigation.addListener('focus', () => {
			// The screen is focused
			// Call any action
			getNotifications();
		});

		// Return the function to unsubscribe from the event so it gets removed on unmount
		return unsubscribe;
	}, [navigation]);

	async function getNotifications() {
		try {
			let response = await axios.get(
				process.env.API_URL + '/user/notifications',
				{
					headers: {
						Authorization: 'Bearer ' + token,
					},
				}
			);

			//(response.data.notifications);
			dispatch(updateAllNotifications(response.data.notifications.reverse()));
		} catch (error) {
			if (typeof error.response !== 'undefined') {
				addHeNotification({
					title: 'Benachrichtigungen',
					message: error.response.data.message,
					type: 'error',
					duration: 5000,
				});
				console.log(error.response.data);
			} else {
				addHeNotification({
					title: 'Benachrichtigungen',
					message: 'Verbindung zum Server konnte nicht hergestellt werden',
					type: 'error',
					duration: 5000,
				});
				console.log(error);
			}
		}

		setRefreshing(false);
	}

	return (
		<ScrollView
			style={tw.style('flex-1 bg-white')}
			refreshControl={
				<RefreshControl
					refreshing={refreshing}
					onRefresh={() => {
						setRefreshing(true);

						getNotifications();
					}}
					colors={[tw.color('primary')]}
				/>
			}
			contentContainerStyle={tw.style('w-full max-w-[900px] mx-auto')}
		>
			{notifications.map((notification, index) => {
				return (
					<View
						key={notification._id}
						style={tw.style(
							'flex-row justify-between items-center p-2 border-b border-gray-200 py-4 px-8'
						)}
					>
						<View style={tw.style('flex flex-1')}>
							<View style={tw.style('')}>
								<Text style={tw.style('text-gray-700 font-bold')}>
									{notification.message.title}
								</Text>
								<Text style={tw.style('text-gray-500')}>
									{notification.message.body}
								</Text>
							</View>
						</View>
						<Text style={tw.style('text-gray-500')}>
							{dateFormat(notification.createdAt, 'dd.mm.yyyy HH:MM')}
						</Text>
					</View>
				);
			})}

			{notifications.length === 0 && (
				<View style={tw.style('flex-1 justify-center items-center p-8')}>
					<Text style={tw.style('text-gray-500 text-base text-center')}>
						Du hast noch keine Benachrichtigungen
					</Text>
				</View>
			)}
		</ScrollView>
	);
}
