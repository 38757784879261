import {
	View,
	Text,
	TouchableOpacity,
	TextInput,
	TouchableWithoutFeedback,
	Image,
	Keyboard,
	ImageBackground,
	Platform,
	ScrollView,
} from 'react-native';
import React from 'react';
import tw from '../../lib/tailwind';

import LoginBGImage from '../../assets/images/auth/xd_vorlagen_login.png';
import {useNavigation} from '@react-navigation/native';

import VisibiltyIcon from '../../assets/icons/visibility.png';
import VisibiltyOffIcon from '../../assets/icons/visibility-off.png';
import {addHeNotification} from '../../lib/HeNotifications/HeNotificationList';
import {KeyboardAwareScrollView} from 'react-native-keyboard-aware-scroll-view';

export default function CreateWorkspace() {
	const navigation = useNavigation();

	const [organisationName, setOrganisationsName] = React.useState('');
	const [organisationError, setOrganisationError] = React.useState(false);
	const [username, setUsername] = React.useState('');
	const [usernameError, setUsernameError] = React.useState(false);

	async function navigateToSetPassword() {
		if (Platform.OS != 'web') {
			Keyboard.dismiss();
		}

		let tempOrganisationError = organisationName.trim('').length == 0;
		let tempUsernameError =
			username.trim('').includes(' ') || username.trim('').length == 0;

		setOrganisationError(tempOrganisationError);

		setUsernameError(tempUsernameError);

		if (tempOrganisationError || tempUsernameError) {
			if (tempOrganisationError && username.trim('').length == 0) {
				addHeNotification({
					title: 'Organisation erstellen',
					message: 'Diese Felder dürfen nicht leer sein',
					type: 'error',
					duration: 5000,
				});
			} else {
				addHeNotification({
					title: 'Organisation erstellen',
					message: 'Dieses Feld darf nicht leer sein',
					type: 'error',
					duration: 5000,
				});
			}
			return;
		}

		navigation.navigate('SetPassword', {
			organisationName: organisationName.trim(),
			username: username.trim(),
			mode: 'workspace',
		});
	}

	function navigateGoBack() {
		if (Platform.OS != 'web') {
			Keyboard.dismiss();
		}
		navigation.goBack();
	}

	return (
		<ImageBackground
			source={LoginBGImage}
			resizeMode={'contain'}
			style={tw.style('flex-1 items-center')}
		>
			<TouchableWithoutFeedback
				onPress={() => {
					if (Platform.OS != 'web') {
						Keyboard.dismiss();
					}
				}}
			>
				<View
					style={tw.style(
						'flex-1' +
							(Platform.OS == 'web'
								? ' max-w-[500px] w-2/3 min-w-[350px]'
								: ' w-full')
					)}
				>
					<View style={tw.style('w-full h-5/11')}></View>
					<KeyboardAwareScrollView
						contentContainerStyle={tw.style(
							'justify-start items-center px-12 pb-12'
						)}
						enableOnAndroid={true}
						enableAutomaticScroll={true}
						scrollEnabled={true}
						keyboardShouldPersistTaps="handled"
					>
						<Text
							style={tw.style('w-full mb-10 text-4xl text-accent font-medium')}
						>
							organisation erstellen
						</Text>

						<View style={tw.style('w-full')}>
							<View
								style={tw.style(
									' w-full border-b border-opacity-35 pb-3' +
										(organisationError ? ' border-red-500' : ' border-gray-400')
								)}
							>
								<TextInput
									keyboardType="default"
									placeholder="organisationsname"
									style={tw.style('text-lg leading-tight text-gray-700')}
									onChangeText={(text) => {
										setOrganisationsName(text);
										setOrganisationError(false);
									}}
									value={organisationName}
									placeholderTextColor={tw.color(
										organisationError ? 'red-300' : 'gray-300'
									)}
									spellCheck={false}
									clearButtonMode="while-editing"
								/>
							</View>
							<View
								style={tw.style(
									'w-full flex flex-row border-b border-opacity-35 pb-3 pt-6' +
										(usernameError ? ' border-red-500' : ' border-gray-400')
								)}
							>
								<TextInput
									keyboardType="default"
									placeholder="nutzername/e-mail-adresse"
									style={tw.style('flex-1 leading-tight text-lg text-gray-700')}
									onChangeText={(text) => {
										setUsername(text);
										setUsernameError(false);
									}}
									placeholderTextColor={tw.color(
										usernameError ? 'red-300' : 'gray-300'
									)}
									value={username}
									clearButtonMode="while-editing"
									spellCheck={false}
									autoCorrect={false}
									autoCapitalize="none"
								/>
							</View>
						</View>
						<View style={tw.style('w-full')}>
							<TouchableOpacity
								activeOpacity={0.8}
								style={tw.style(
									'w-full mt-14 rounded-full py-3 bg-accent self-start'
								)}
								onPress={() => navigateToSetPassword()}
							>
								<Text
									style={tw.style('text-primary text-center text-xl font-bold')}
								>
									weiter
								</Text>
							</TouchableOpacity>

							<TouchableOpacity
								activeOpacity={0.8}
								style={tw.style(
									'w-full mt-2 rounded-full py-3 bg-primary self-start'
								)}
								onPress={() => navigateGoBack()}
							>
								<Text
									style={tw.style('text-accent text-center text-xl font-bold')}
								>
									zurück
								</Text>
							</TouchableOpacity>
						</View>
					</KeyboardAwareScrollView>
				</View>
			</TouchableWithoutFeedback>
		</ImageBackground>
	);
}
