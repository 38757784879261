import {View, Text, ActivityIndicator} from 'react-native';
import React, {useEffect} from 'react';
import tw from '../lib/tailwind';
import {useNavigation} from '@react-navigation/native';
import {addHeNotification} from '../lib/HeNotifications/HeNotificationList';
import axios from 'axios';

export default function ActivateUser(props) {
	const navigation = useNavigation();

	let activationKey = props.route?.params?.code || '';

	useEffect(() => {
		if (activationKey == '') {
			console.log('No activation key provided');
			addHeNotification({
				title: 'Fehler',
				message: 'Dein Account konnte nicht aktiviert werden',
				type: 'error',
			});
			navigation.replace('App');
		} else {
			activateUser();
		}
	}, []);

	async function activateUser() {
		let running = sessionStorage.getItem('activationRunning');

		if (running == 'true') {
			console.log('Activation already running');
			setTimeout(() => {
				navigation.replace('App');
			}, 1000);
			return;
		}

		sessionStorage.setItem('activationRunning', 'true');

		try {
			console.log('Activating user', activationKey);
			let res = await axios.post(
				process.env.API_URL + '/user/activateWorkspace',
				{
					activationKey,
				},
				{
					headers: {
						'Content-Type': 'application/json',
					},
				}
			);

			addHeNotification({
				title: 'Account aktiviert',
				message: res.data.message,
				type: 'success',
			});
			sessionStorage.removeItem('activationRunning');

			setTimeout(() => {
				navigation.replace('App');
			}, 1000);
		} catch (error) {
			console.log('Error activating user');
			console.log(error);
			addHeNotification({
				title: 'Fehler',
				message: 'Dein Account konnte nicht aktiviert werden',
				type: 'error',
			});
			sessionStorage.removeItem('activationRunning');

			setTimeout(() => {
				navigation.replace('App');
			}, 1000);
		}
	}

	return (
		<View style={tw.style('flex-1 flex items-center justify-center')}>
			<ActivityIndicator size="large" color={tw.color('primary')} />
			<Text style={tw.style('mt-6')}>
				Dein Account wird aktiviert, bitte warten.
			</Text>
		</View>
	);
}
