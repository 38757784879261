import {configureStore} from '@reduxjs/toolkit';
import appDataSlice from './slices/appDataSlice';

// Import reducer

const store = configureStore({
	reducer: {
		appData: appDataSlice,
	},
});

export default store;
