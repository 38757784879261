import React from 'react';
import {
	Image,
	Platform,
	ScrollView,
	Text,
	TextInput,
	TouchableOpacity,
	View,
} from 'react-native';
import tw from '../lib/tailwind';

import User from '../assets/icons/user.png';
import VisibilityIcon from '../assets/icons/visibility.png';
import VisibilityOffIcon from '../assets/icons/visibility-off.png';
import {useDispatch, useSelector} from 'react-redux';

import axios from 'axios';
import {updateToken, updateUserData} from '../redux/slices/appDataSlice';
import {addHeNotification} from '../lib/HeNotifications/HeNotificationList';
import {useNavigation} from '@react-navigation/native';

import * as ImagePicker from 'expo-image-picker';
import * as SecureStore from 'expo-secure-store';
import {KeyboardAwareScrollView} from 'react-native-keyboard-aware-scroll-view';

export default function UserProfile() {
	const dispatch = useDispatch();
	const navigation = useNavigation();

	const token = useSelector((state) => state.appData.token);
	const userInfo = useSelector((state) => state.appData.userData);

	const [firstName, setFirstName] = React.useState(
		userInfo.extendedData.firstName || ''
	);
	const [lastName, setLastName] = React.useState(
		userInfo.extendedData.lastName || ''
	);

	const [username, setUsername] = React.useState(userInfo.username || 'Anonym');
	const [additionalEmail, setAdditionalEmail] = React.useState(
		userInfo.extendedData.additionalEmail || ''
	);

	const [password, setPassword] = React.useState('');
	const [passwordVisible, setPasswordVisible] = React.useState(false);

	const [repeatPassword, setRepeatPassword] = React.useState('');
	const [repeatPasswordVisible, setRepeatPasswordVisible] =
		React.useState(false);

	async function selectImage() {
		const {status} = await ImagePicker.requestMediaLibraryPermissionsAsync();

		if (status !== 'granted') {
			alert('Sorry, we need camera roll permissions to make this work!');
		} else {
			let result = await ImagePicker.launchImageLibraryAsync({
				mediaTypes: ImagePicker.MediaTypeOptions.Images,
				quality: 1,
			});

			if (!result.canceled) {
				console.log(result.assets[0]);
				return result.assets[0];
			}
		}
		return false;
	}

	function dataURLtoFile(dataurl, filename) {
		var arr = dataurl.split(','),
			mime = arr[0].match(/:(.*?);/)[1],
			bstr = atob(arr[1]),
			n = bstr.length,
			u8arr = new Uint8Array(n);

		while (n--) {
			u8arr[n] = bstr.charCodeAt(n);
		}

		return new File([u8arr], filename, {type: mime});
	}

	async function handleUploadAvatar() {
		try {
			const image = await selectImage();

			if (!image) {
				return;
			}

			let formData = new FormData();
			formData.append(
				'avatar',
				Platform.OS === 'web'
					? dataURLtoFile(image.uri, image.name)
					: {
							uri: image.uri,
							type: image.mimeType
								? image.mimeType
								: `${image.type}/${image.uri
										.split('.')
										.pop()
										.toLowerCase()
										.replace('jpeg', 'jpg')}`,
							name: image.uri.split('/').pop(),
					  }
			);

			const response = await axios.post(
				process.env.API_URL + '/user/avatar',
				formData,
				{
					headers: {
						Authorization: 'Bearer ' + token,
						'Content-Type': 'multipart/form-data',
					},
				}
			);
			console.log(response.data);

			dispatch(updateUserData(response.data.userData));
			addHeNotification({
				title: 'Profil bearbeiten',
				message: response.data.message,
				type: 'success',
				duration: 5000,
			});
		} catch (error) {
			if (typeof error.response !== 'undefined') {
				addHeNotification({
					title: 'Profil bearbeiten',
					message: error.response.data.message,
					type: 'error',
					duration: 5000,
				});
				console.log(error.response.data);
			} else {
				addHeNotification({
					title: 'Profil bearbeiten',
					message: 'Verbindung zum Server konnte nicht hergestellt werden',
					type: 'error',
					duration: 5000,
				});
				console.log(error.response);
			}
		}
	}

	async function saveData() {
		if (
			password != repeatPassword &&
			(password != '' || repeatPassword != '')
		) {
			addHeNotification({
				title: 'Profil bearbeiten',
				message: 'Die Passwörter stimmen nicht überein',
				type: 'error',
				duration: 5000,
			});
			return;
		}

		if (
			username == '' ||
			(!additionalEmail.match(
				'^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$'
			) &&
				additionalEmail != '')
		) {
			addHeNotification({
				title: 'Profil bearbeiten',
				message: 'Bitte gib eine gültige E-Mail Adresse an',
				type: 'error',
				duration: 5000,
			});
			return;
		}

		try {
			const response = await axios.patch(
				process.env.API_URL + '/user',
				{
					firstName,
					lastName,
					password,
					username,
					additionalEmail,
				},
				{
					headers: {
						Authorization: 'Bearer ' + token,
					},
				}
			);

			dispatch(updateUserData(response.data.userData));

			if (Platform.OS != 'web') {
				if (await SecureStore.getItemAsync('user')) {
					await SecureStore.setItemAsync(
						'user',
						JSON.stringify(response.data.userData)
					);
				}
			}

			addHeNotification({
				title: 'Profil bearbeiten',
				message: response.data.message,
				type: 'success',
				duration: 5000,
			});
		} catch (error) {
			if (typeof error.response !== 'undefined') {
				addHeNotification({
					title: 'Profil bearbeiten',
					message: error.response.data.message,
					type: 'error',
					duration: 5000,
				});
				console.log(error.response.data);
			} else {
				addHeNotification({
					title: 'Profil bearbeiten',
					message: 'Verbindung zum Server konnte nicht hergestellt werden',
					type: 'error',
					duration: 5000,
				});
				console.log(error.response);
			}
		}
	}

	async function handleLogout() {
		navigation.navigate('App');

		if (Platform.OS === 'web') {
			localStorage.removeItem('token');
			localStorage.removeItem('user');
			localStorage.removeItem('workspace');
			localStorage.removeItem('lastMessages');
		} else {
			await SecureStore.deleteItemAsync('token');
			await SecureStore.deleteItemAsync('user');
			await SecureStore.deleteItemAsync('workspace');
			await SecureStore.deleteItemAsync('lastMessages');
		}
		dispatch(updateToken(null));
		dispatch(updateUserData(null));
	}

	return (
		<ScrollView
			style={tw.style('flex-1 bg-white')}
			contentContainerStyle={tw.style(
				'bg-white py-12 w-full max-w-[900px] mx-auto'
			)}
		>
			<KeyboardAwareScrollView
				style={tw.style('flex-1')}
				contentContainerStyle={tw.style(
					'flex-1 flex-col items-center justify-center'
				)}
			>
				<TouchableOpacity
					style={tw.style('flex-row items-center mr-4')}
					onPress={handleUploadAvatar}
				>
					<Image
						source={
							userInfo.extendedData?.avatar
								? {
										uri:
											process.env.API_URL +
											'/' +
											userInfo.extendedData.avatar +
											'?width=640&height=640&token=' +
											token,
								  }
								: User
						}
						style={tw.style('w-26 h-26 bg-[#F2F2F2] rounded-full ')}
						resizeMode={userInfo.extendedData?.avatar ? 'cover' : 'contain'}
					/>
				</TouchableOpacity>
				<TextInput
					style={tw.style(
						'bg-transparent leading-tight border border-primary rounded-full p-3 bg-gray-50 w-3/4 mt-8 text-base'
					)}
					placeholder="username oder email"
					value={username}
					onChangeText={(text) => setUsername(text)}
					placeholderTextColor={tw.color('gray-300')}
				/>
				{!username.match('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$') && (
					<TextInput
						style={tw.style(
							'bg-transparent leading-tight border border-primary rounded-full p-3 bg-gray-50 w-3/4 mt-4 text-base'
						)}
						placeholder="zusätzliche email"
						keyboardType="email-address"
						value={additionalEmail}
						onChangeText={(text) => setAdditionalEmail(text)}
						placeholderTextColor={tw.color('gray-300')}
					/>
				)}
				<TextInput
					style={tw.style(
						'bg-transparent leading-tight border border-primary rounded-full p-3 bg-gray-50 w-3/4 mt-4 text-base'
					)}
					placeholder="vorname"
					value={firstName}
					onChangeText={(text) => setFirstName(text)}
					placeholderTextColor={tw.color('gray-300')}
				/>
				<TextInput
					style={tw.style(
						'bg-transparent leading-tight border border-primary rounded-full p-3 bg-gray-50 w-3/4 mt-4 text-base'
					)}
					placeholder="nachname"
					value={lastName}
					onChangeText={(text) => setLastName(text)}
					placeholderTextColor={tw.color('gray-300')}
				/>
				<View
					style={tw.style(
						'bg-transparent leading-tight border border-primary rounded-full p-3 bg-gray-50 w-3/4 mt-4 text-base flex-row'
					)}
				>
					<TextInput
						keyboardType="default"
						secureTextEntry={!passwordVisible}
						placeholder="passwort"
						style={tw.style('flex-1 text-gray-700 leading-tight')}
						onChangeText={(text) => {
							setPassword(text);
						}}
						placeholderTextColor={tw.color('gray-300')}
						value={password}
						clearButtonMode="while-editing"
					/>
					<TouchableOpacity
						onPress={() => setPasswordVisible(!passwordVisible)}
						style={tw.style('justify-center items-center')}
					>
						<Image
							source={passwordVisible ? VisibilityOffIcon : VisibilityIcon}
							style={tw.style('w-6 h-6')}
							resizeMode="contain"
						/>
					</TouchableOpacity>
				</View>

				<View
					style={tw.style(
						'bg-transparent leading-tight border border-primary rounded-full p-3 bg-gray-50 w-3/4 mt-4 text-base flex-row'
					)}
				>
					<TextInput
						keyboardType="default"
						secureTextEntry={!repeatPasswordVisible}
						placeholder="passwort wiederholen"
						style={tw.style('flex-1 text-gray-700 leading-tight')}
						onChangeText={(text) => {
							setRepeatPassword(text);
						}}
						placeholderTextColor={tw.color('gray-300')}
						value={repeatPassword}
						clearButtonMode="while-editing"
					/>
					<TouchableOpacity
						onPress={() => setRepeatPasswordVisible(!repeatPasswordVisible)}
						style={tw.style('justify-center items-center')}
					>
						<Image
							source={
								repeatPasswordVisible ? VisibilityOffIcon : VisibilityIcon
							}
							style={tw.style('w-6 h-6')}
							resizeMode="contain"
						/>
					</TouchableOpacity>
				</View>

				<TouchableOpacity
					activeOpacity={0.8}
					style={tw.style('w-3/4 mt-8 rounded-full py-3 bg-accent ')}
					onPress={() => saveData()}
				>
					<Text style={tw.style('text-primary text-center text-xl font-bold')}>
						daten speichern
					</Text>
				</TouchableOpacity>

				<TouchableOpacity
					activeOpacity={0.8}
					style={tw.style('w-3/4 mt-4 rounded-full py-3 ')}
					onPress={() => handleLogout()}
				>
					<Text style={tw.style('text-red-500 text-center text-xl font-bold')}>
						abmelden
					</Text>
				</TouchableOpacity>
			</KeyboardAwareScrollView>
		</ScrollView>
	);
}
