import React, {useEffect} from 'react';
import {
	ActivityIndicator,
	Image,
	ScrollView,
	Text,
	TouchableOpacity,
	View,
} from 'react-native';
import {useDispatch, useSelector} from 'react-redux';
import tw from '../../../lib/tailwind';
import BottomSheet, {BottomSheetModal} from '@gorhom/bottom-sheet';
import socket from '../../../lib/socket';
import {useNavigation} from '@react-navigation/native';
import User from '../../../assets/icons/user.png';

import CheckedIcon from '../../../assets/icons/checked.png';

import axios from 'axios';
import {
	updateAllChats,
	updateAllUsers,
} from '../../../redux/slices/appDataSlice';
import {addHeNotification} from '../../../lib/HeNotifications/HeNotificationList';

import dateFormat from 'dateformat';
import {decrypt} from '../../../lib/eTe';
import {Swipeable} from 'react-native-gesture-handler';

export default function ChatArchive() {
	const chats = useSelector((state) => state.appData.allChats);

	const userInfo = useSelector((state) => state.appData.userData);

	const allUsers = useSelector((state) => state.appData.allUsers);

	const token = useSelector((state) => state.appData.token);

	const [swipeableOpen, setSwipeableOpen] = React.useState(false);

	const dispatch = useDispatch();

	const sheetRef = React.useRef(null);

	const navigation = useNavigation();

	const handlePresentModalPress = React.useCallback(() => {
		sheetRef.current?.present();
	}, []);

	useEffect(() => {
		let focusListener = navigation.addListener('focus', () => {
			getAllChats();
		});

		getAllChats();

		return () => {
			focusListener();
		};
	}, []);

	async function getAllChats() {
		try {
			let response = await axios.get(
				process.env.API_URL + '/' + userInfo.workspace + '/chat/',
				{
					headers: {
						Authorization: 'Bearer ' + token,
					},
				}
			);

			dispatch(updateAllChats(response.data.chats));
		} catch (error) {
			console.log(error);
		}
	}

	async function createChat(users) {
		try {
			let response = await axios.post(
				process.env.API_URL + '/' + userInfo.workspace + '/chat/',
				{
					users,
					title: '',
				},
				{
					headers: {
						Authorization: 'Bearer ' + token,
					},
				}
			);

			dispatch(updateAllChats(response.data.chats));

			addHeNotification({
				title: 'Chats',
				message: response.data.message,
				type: 'success',
				duration: 5000,
			});
		} catch (error) {
			if (typeof error.response !== 'undefined') {
				addHeNotification({
					title: 'Chats',
					message: error.response.data.message,
					type: 'error',
					duration: 5000,
				});
				console.log(error.response.data);
			} else {
				addHeNotification({
					title: 'Chats',
					message: 'Verbindung zum Server konnte nicht hergestellt werden',
					type: 'error',
					duration: 5000,
				});
				console.log(error);
			}
		}
	}

	useEffect(() => {
		socket.emit('getUsers', {
			token,
			workspace: userInfo.workspace,
			userID: userInfo._id,
		});

		socket.on('allUser', (users) => {
			dispatch(updateAllUsers(users));
		});
	}, []);

	function getLastActiveTime(chat) {
		let lastTime = chat.createdAt;

		if (chat.messages.length > 0) {
			lastTime = chat.messages[chat.messages.length - 1].createdAt;
		}

		let formatString = 'HH:MM';

		if (
			dateFormat(lastTime, 'dd.mm.yyyy') !==
			dateFormat(new Date(), 'dd.mm.yyyy')
		) {
			formatString = 'dd.mm.yyyy';
		}

		return dateFormat(lastTime, formatString);
	}

	async function openChat(id) {
		try {
			let response = await axios.put(
				process.env.API_URL + '/' + userInfo.workspace + '/chat/' + id,
				{
					status: 'open',
				},
				{
					headers: {
						Authorization: 'Bearer ' + token,
					},
				}
			);

			dispatch(updateAllChats(response.data.chats));

			addHeNotification({
				title: 'Chats',
				message: response.data.message,
				type: 'success',
				duration: 5000,
			});
		} catch (error) {
			if (typeof error.response !== 'undefined') {
				addHeNotification({
					title: 'Chats',
					message: error.response.data.message,
					type: 'error',
					duration: 5000,
				});
				console.log(error.response.data);
			} else {
				addHeNotification({
					title: 'Chats',
					message: 'Verbindung zum Server konnte nicht hergestellt werden',
					type: 'error',
					duration: 5000,
				});
				console.log(error);
			}
		}
	}

	return (
		<View style={tw.style('flex-1 bg-white')}>
			<ScrollView
				style={tw.style('flex-1')}
				contentContainerStyle={tw.style('pb-50 w-full max-w-[900px] mx-auto')}
			>
				<Text style={tw.style('text-primary text-4xl font-semibold py-6 px-8')}>
					Archiv
				</Text>

				{chats &&
					chats
						.filter((item) => item.status == 'closed')
						.map((chat) => {
							let chatUsers = [];

							chat.users.forEach((user) => {
								chatUsers.push(
									allUsers.filter(
										(item) => item._id == user && item._id != userInfo._id
									)[0] || null
								);
							});

							chatUsers = chatUsers.filter((item) => item !== null);

							console.log(chat);
							return (
								<Swipeable
									key={chat._id}
									renderRightActions={() => (
										<TouchableOpacity
											onPress={() => {
												openChat(chat._id);
											}}
											style={tw.style(
												'bg-yellow-500 p-6 flex items-center justify-center'
											)}
										>
											<Text style={tw.style('text-white')}>Wiedereröffnen</Text>
										</TouchableOpacity>
									)}
									onSwipeableWillOpen={() => {
										setSwipeableOpen(true);
									}}
									onSwipeableWillClose={() => {
										setSwipeableOpen(false);
									}}
								>
									<TouchableOpacity
										key={chat._id}
										style={tw.style(
											'flex-row items-center justify-between px-8 py-4 border-b border-gray-200'
										)}
										onPress={() => {
											if (swipeableOpen) return;

											navigation.navigate('SingleChat', {
												chatID: chat._id,
											});
										}}
									>
										<View style={tw.style('flex-row items-center')}>
											<View style={tw.style('flex-row items-center mr-4')}>
												<Image
													source={
														chat.users.length == 2
															? allUsers
																	.filter((user) => user._id != userInfo._id)
																	.find((user) => chat.users.includes(user._id))
																	?.extendedData?.avatar !== ''
																? {
																		uri:
																			process.env.API_URL +
																			'/' +
																			allUsers
																				.filter(
																					(user) => user._id != userInfo._id
																				)
																				.find((user) =>
																					chat.users.includes(user._id)
																				)?.extendedData.avatar +
																			'?width=64&height=64&token=' +
																			token,
																  }
																: User
															: User
													}
													style={tw.style('w-7 h-7 bg-[#F2F2F2] rounded-full ')}
													resizeMode={
														chat.users.length == 2
															? allUsers
																	.filter((user) => user._id != userInfo._id)
																	.find((user) => chat.users.includes(user._id))
																	?.extendedData?.avatar !== ''
																? 'cover'
																: 'contain'
															: 'contain'
													}
												/>
											</View>
											<View style={tw.style('ml-4 max-w-3/4')}>
												<Text
													style={tw.style(
														'text-primary text-lg font-semibold max-w-full'
													)}
													numberOfLines={1}
												>
													{chat.title !== ''
														? chat.title
														: chatUsers.length == 0
														? 'DU'
														: chatUsers.length == 1
														? allUsers
																.filter((user) => user._id != userInfo._id)
																.find((user) => chat.users.includes(user._id))
																?.username
														: chatUsers[0]?.username +
														  ' und ' +
														  (chatUsers.length - 1) +
														  ' weitere'}
												</Text>

												<Text
													style={tw.style('text-gray-500 text-sm max-w-full')}
													numberOfLines={1}
												>
													{chat.messages.length > 0
														? (chat.messages[chat.messages.length - 1]
																.createdBy == userInfo._id
																? 'Du: '
																: '') +
														  (chat.messages[chat.messages.length - 1].type ==
														  'text'
																? decrypt(
																		chat.messages[chat.messages.length - 1]
																			.value,
																		chat._id
																  )
																: 'Bild')
														: 'Keine Nachrichten'}
												</Text>
											</View>
										</View>

										<Text style={tw.style('text-gray-500 text-sm')}>
											{getLastActiveTime(chat)}
										</Text>
									</TouchableOpacity>
								</Swipeable>
							);
						})}

				{!chats && (
					<View style={tw.style('flex-1 justify-center items-center')}>
						<ActivityIndicator size="large" color={tw.color('gray-400')} />
						<Text
							style={tw.style('text-gray-400 text-lg font-semibold py-6 px-8')}
						>
							Wird geladen...
						</Text>
					</View>
				)}

				{chats &&
					chats.filter((item) => item.status == 'closed').length === 0 && (
						<Text
							style={tw.style('text-gray-400 text-lg font-semibold py-6 px-8')}
						>
							Keine Chats vorhanden
						</Text>
					)}
			</ScrollView>
		</View>
	);
}
