import {createSlice} from '@reduxjs/toolkit';
import dateFormat from 'dateformat';

export const appDataSlice = createSlice({
	name: 'appData',
	initialState: {
		token: '',
		userData: {},
		messageData: null,
		serverConnected: true,
		tabBarHeight: 0,
		todos: [],
		pinboards: [],
		workspaceData: null,
		pushNotificationToken: null,
		allUsers: [],
		allChats: [],
		allNotifications: [],
	},
	reducers: {
		updateToken: (state, action) => {
			state.token = action.payload;
		},
		updateUserData: (state, action) => {
			state.userData = action.payload;
		},
		setMessageData: (state, action) => {
			state.messageData = action.payload;
		},
		updateServerConnected: (state, action) => {
			state.serverConnected = action.payload;
		},
		updateTabBarHeight: (state, action) => {
			state.tabBarHeight = action.payload;
		},
		updateTodos: (state, action) => {
			state.todos = action.payload;
		},
		updateWorkspaceData: (state, action) => {
			state.workspaceData = action.payload;
		},
		setPushNotificationToken: (state, action) => {
			state.pushNotificationToken = action.payload;
		},
		updateAllUsers: (state, action) => {
			state.allUsers = action.payload;
		},
		updatePinboards: (state, action) => {
			state.pinboards = action.payload;
		},
		updateAllChats: (state, action) => {
			let tempChats = action.payload;

			// sort the chats by the last message date
			tempChats.sort((a, b) => {
				const aDate =
					a.messages.length != 0
						? new Date(a.messages[a.messages.length - 1].createdAt)
						: new Date(a.createdAt);
				const bDate =
					b.messages.length != 0
						? new Date(b.messages[b.messages.length - 1].createdAt)
						: new Date(b.createdAt);

				return bDate - aDate;
			});

			state.allChats = tempChats;
		},
		updateChatByID: (state, action) => {
			const chat = action.payload;
			if (state.allChats == []) {
				return;
			}
			const index = state.allChats.findIndex((item) => item._id === chat._id);

			// remove the chat from the array
			state.allChats.splice(index, 1);

			// add the chat to the beginning of the array
			state.allChats.unshift(chat);

			// sort the chats by the last message date

			state.allChats.sort((a, b) => {
				const aDate =
					a.messages.length != 0
						? new Date(a.messages[a.messages.length - 1].createdAt)
						: new Date(a.createdAt);
				const bDate =
					b.messages.length != 0
						? new Date(b.messages[b.messages.length - 1].createdAt)
						: new Date(b.createdAt);

				return bDate - aDate;
			});
		},
		updateAllNotifications: (state, action) => {
			state.allNotifications = action.payload;
		},
	},
});

// Action creators are generated for each case reducer function
export const {
	updateToken,
	updateMessageDataById,
	setMessageData,
	updateUserData,
	updateServerConnected,
	updateTabBarHeight,
	updateTodos,
	updateWorkspaceData,
	setPushNotificationToken,
	updateAllUsers,
	updatePinboards,
	updateAllChats,
	updateChatByID,
	updateAllNotifications,
} = appDataSlice.actions;

export default appDataSlice.reducer;
