import React, {useEffect} from 'react';
import {
	View,
	Text,
	TouchableOpacity,
	Platform,
	Image,
	Keyboard,
} from 'react-native';
import tw from '../lib/tailwind';

import ChatDefault from '../assets/icons/chat-default.png';
import ChatActive from '../assets/icons/chat-active.png';
import HomeDefault from '../assets/icons/home-default.png';
import HomeActive from '../assets/icons/home-active.png';
import PinDefault from '../assets/icons/pin-default.png';
import PinActive from '../assets/icons/pin-active.png';
import TodoDefault from '../assets/icons/todo-default.png';
import TodoActive from '../assets/icons/todo-active.png';
import WorkspaceActive from '../assets/icons/workspace-active.png';
import WorkspaceDefault from '../assets/icons/workspace-default.png';

import {SafeAreaView} from 'react-native-safe-area-context';
import {useDispatch} from 'react-redux';
import {updateTabBarHeight} from '../redux/slices/appDataSlice';

export default function TabBar({state, descriptors, navigation}) {
	const dispatch = useDispatch();

	const [keyboardVisible, setKeyboardVisible] = React.useState(false);

	useEffect(() => {
		if (Platform.OS != 'android') return;

		const keyboardDidShowListener = Keyboard.addListener(
			'keyboardDidShow',
			() => {
				setKeyboardVisible(true);
			}
		);
		const keyboardDidHideListener = Keyboard.addListener(
			'keyboardDidHide',
			() => {
				setKeyboardVisible(false);
			}
		);

		return () => {
			keyboardDidHideListener.remove();
			keyboardDidShowListener.remove();
		};
	}, []);

	if (keyboardVisible) {
		return null;
	}

	return (
		<SafeAreaView
			edges={['bottom']}
			style={tw.style(
				'pb-1 w-full flex flex-row justify-around items-center py-4 px-3 bg-white shadow-md'
			)}
			onLayout={(e) => {
				dispatch(updateTabBarHeight(e.nativeEvent.layout.height));
			}}
		>
			<View style={tw.style('flex-1 flex-row items-center justify-around')}>
				{state.routes.map((route, index) => {
					const {options} = descriptors[route.key];
					const label =
						options.tabBarLabel !== undefined
							? options.tabBarLabel
							: options.title !== undefined
							? options.title
							: route.name;

					const isFocused = state.index === index;

					const Icon = (props) => {
						switch (route.name) {
							case 'Home':
								return isFocused ? HomeActive : HomeDefault;

							case 'ChatIndex':
								return isFocused ? ChatActive : ChatDefault;

							case 'TodoIndex':
								return isFocused ? TodoActive : TodoDefault;

							case 'PinIndex':
								return isFocused ? PinActive : PinDefault;

							case 'Info':
								return isFocused ? WorkspaceActive : WorkspaceDefault;

							default:
								return isFocused ? HomeActive : HomeDefault;
						}
					};

					const color = isFocused ? tw.color('primary') : '#AEAEB2';

					const onPress = () => {
						const event = navigation.emit({
							type: 'tabPress',
							target: route.key,
							canPreventDefault: true,
						});

						if (!isFocused && !event.defaultPrevented) {
							navigation.navigate({name: route.name, merge: true});
						}
					};

					const onLongPress = () => {
						navigation.emit({
							type: 'tabLongPress',
							target: route.key,
						});
					};

					return (
						<TouchableOpacity
							accessibilityRole="button"
							accessibilityState={isFocused ? {selected: true} : {}}
							accessibilityLabel={options.tabBarAccessibilityLabel}
							testID={options.tabBarTestID}
							onPress={onPress}
							onLongPress={onLongPress}
							style={tw.style('flex items-center')}
							key={index}
						>
							<Image
								source={Icon()}
								resizeMode="contain"
								style={
									Platform.OS === 'web'
										? tw.style('w-8 h-7')
										: tw.style('w-8 h-6')
								}
							/>
							{false && (
								<Text
									style={{
										color: color,
									}}
								>
									{label}
								</Text>
							)}
						</TouchableOpacity>
					);
				})}
			</View>
		</SafeAreaView>
	);
}
