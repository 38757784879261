module.exports = {
	theme: {
		extend: {
			colors: {
				primary: {
					light: '#8defba',
					DEFAULT: '#67eaa4',
					dark: '#1ede77',
				},
				accent: {
					light: '#ab9ffa',
					DEFAULT: '#9080f9',
					dark: '#773de6',
				},
			},
			fontSize: {
				'4xl': '1.5rem',
				'5xl': '2rem',
				'6xl': '2.5rem',
				'7xl': '3rem',
				'8xl': '3.5rem',
			},
		},
	},
};
