import {
	View,
	Text,
	ScrollView,
	TextInput,
	Image,
	TouchableOpacity,
	Platform,
	RefreshControl,
} from 'react-native';
import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useState} from 'react';
import {useEffect} from 'react';
import tw from '../../../lib/tailwind';
import {FlatList} from 'react-native';
import {updateAllChats} from '../../../redux/slices/appDataSlice';
import axios from 'axios';
import {BottomSheetModal} from '@gorhom/bottom-sheet';
import CheckIcon from '../../../assets/icons/checked.png';
import User from '../../../assets/icons/user.png';
import Arrow from '../../../assets/icons/arrow-right.png';
import {useNavigation} from '@react-navigation/native';
import {addHeNotification} from '../../../lib/HeNotifications/HeNotificationList';
import BackIcon from '../../../assets/icons/arrow-left.png';

export default function ChatDetails(props) {
	const {chatID} = props.route.params;

	const allChats = useSelector((state) => state.appData.allChats);
	const allUsers = useSelector((state) => state.appData.allUsers);

	const token = useSelector((state) => state.appData.token);
	const userInfo = useSelector((state) => state.appData.userData);

	const sheetRef = React.useRef(null);

	const navigation = useNavigation();

	const dispatch = useDispatch();

	const [chatData, setChatData] = useState(
		allChats.find((chat) => chat._id === chatID)
	);
	const [title, setTitle] = useState(chatData?.title || '');
	const [update, setUpdate] = useState(0);

	const [addUsers, setAddUsers] = useState([]);

	const [refreshing, setRefreshing] = React.useState(false);

	useEffect(() => {
		if (allChats.find((chat) => chat._id === chatID)) {
			setChatData(allChats.find((chat) => chat._id === chatID));

			console.log(allChats.find((chat) => chat._id === chatID));
		} else {
			// navigation.navigate('Chats', {
			// 	merge: true,
			// });
		}
	}, [allChats]);

	useEffect(() => {
		getAllChats();
	}, []);

	async function getAllChats() {
		try {
			let response = await axios.get(
				process.env.API_URL + '/' + userInfo.workspace + '/chat/',
				{
					headers: {
						Authorization: 'Bearer ' + token,
					},
				}
			);

			dispatch(updateAllChats(response.data.chats));

			setChatData(response.data.chats.find((chat) => chat._id === chatID));
		} catch (error) {
			console.log(error);
		}

		setRefreshing(false);
	}

	async function removeUser(userID) {
		try {
			// combine arrays
			let tempUsers = chatData.users.filter((user) => user != userID);

			let response = await axios.put(
				process.env.API_URL + '/' + userInfo.workspace + '/chat/' + chatID,
				{
					users: tempUsers,
				},
				{
					headers: {
						Authorization: 'Bearer ' + token,
					},
				}
			);

			dispatch(updateAllChats(response.data.chats));

			if (response.data.chats.find((chat) => chat._id === chatID)) {
				setChatData(response.data.chats.find((chat) => chat._id === chatID));
			} else {
				setChatData({
					...chatData,
					users: tempUsers,
				});
			}

			addHeNotification({
				title: 'Nutzer entfernen',
				message: 'Nutzer wurde erfolgreich entfernt',
				type: 'success',
				duration: 5000,
			});
		} catch (error) {
			console.log(error.response);

			if (error.response?.data?.message) {
				addHeNotification({
					title: 'Nutzer entfernen',
					message: error.response.data.message,
					type: 'error',
					duration: 5000,
				});
				return;
			}

			addHeNotification({
				title: 'Nutzer entfernen',
				message: 'Beim entfernen des Nutzers ist ein Fehler aufgetreten',
				type: 'error',
				duration: 5000,
			});
		}
	}

	async function addUser(userID) {
		try {
			// combine arrays
			let tempUsers = [...chatData.users, ...userID];

			let response = await axios.put(
				process.env.API_URL + '/' + userInfo.workspace + '/chat/' + chatID,
				{
					users: tempUsers,
				},
				{
					headers: {
						Authorization: 'Bearer ' + token,
					},
				}
			);

			dispatch(updateAllChats(response.data.chats));

			setChatData(response.data.chats.find((chat) => chat._id === chatID));

			addHeNotification({
				title: 'Neue Nutzer',
				message: 'Du hast erfolgreich neue Nutzer hinzugefügt',
				type: 'success',
				duration: 5000,
			});

			sheetRef.current?.close();
		} catch (error) {
			console.log(error);

			if (error.response?.data?.message) {
				addHeNotification({
					title: 'Neuer Nutzer',
					message: error.response.data.message,
					type: 'error',
					duration: 5000,
				});

				return;
			}

			addHeNotification({
				title: 'Neuer Nutzer',
				message: 'Beim hinzufügen der Nutzer ist ein Fehler aufgetreten',
				type: 'error',
				duration: 5000,
			});
		}
	}

	async function saveChatTitle() {
		if (!chatData.users.includes(userInfo._id)) {
			addHeNotification({
				title: 'Titel bearbeiten',
				message: 'Du bist nicht mehr Teilnehmer des Chats',
				type: 'error',
				duration: 5000,
			});
			return;
		}

		try {
			let response = await axios.put(
				process.env.API_URL + '/' + userInfo.workspace + '/chat/' + chatID,
				{
					title: title,
				},
				{
					headers: {
						Authorization: 'Bearer ' + token,
					},
				}
			);

			console.log(response.data);
			dispatch(updateAllChats(response.data.chats));

			setChatData(response.data.chats.find((chat) => chat._id === chatID));

			addHeNotification({
				title: 'Titel bearbeiten',
				message: 'Der Titel wurde erfolgreich bearbeitet',
				type: 'success',
				duration: 5000,
			});
		} catch (error) {
			console.log(error);
			addHeNotification({
				title: 'Titel bearbeiten',
				message: 'Beim bearbeiten des Titels ist ein Fehler aufgetreten',
				type: 'error',
				duration: 5000,
			});
		}
	}

	function handleGoBack() {
		let allChatsIDs = allChats.map((chat) => chat._id);

		if (!allChatsIDs.includes(chatID)) {
			navigation.navigate('Chats', {
				merge: true,
			});
			return;
		}
		navigation.navigate('SingleChat', {
			chatID: chatID,
			merge: true,
		});
	}

	return (
		<ScrollView
			style={tw.style('flex-1 ')}
			contentContainerStyle={tw.style('py-12 items-center')}
			refreshControl={
				<RefreshControl
					refreshing={refreshing}
					onRefresh={() => {
						setRefreshing(true);

						getAllChats();
					}}
					colors={[tw.color('primary')]}
				/>
			}
		>
			<View style={tw.style('mb-24 w-3/4 items-start justify-start ')}>
				<TouchableOpacity
					style={tw.style('mt-8')}
					onPress={() => handleGoBack()}
				>
					<Image
						source={BackIcon}
						style={tw.style('w-5 h-5')}
						resizeMode="contain"
					/>
				</TouchableOpacity>
			</View>
			{chatData.type == 'group' && (
				<View style={tw.style('w-3/4 max-w-[900px] mx-auto')}>
					<Text style={tw.style('text-base font-bold mb-2 w-full ')}>
						Titel
					</Text>
					<TextInput
						style={tw.style(
							'w-full leading-tight border-b border-gray-300 text-base py-2 mb-14'
						)}
						placeholder={'Chat Titel'}
						onChangeText={setTitle}
						value={title}
						placeholderTextColor={tw.color('gray-400')}
					/>
				</View>
			)}
			<Text
				style={tw.style('text-base font-bold mb-2 w-3/4 max-w-[900px] mx-auto')}
			>
				Teilnehmer
			</Text>

			<View style={tw.style('w-3/4 max-w-[900px] mx-auto')}>
				<FlatList
					data={chatData.users.filter((user) => {
						return (
							chatData.users.indexOf(user) === chatData.users.lastIndexOf(user)
						);
					})}
					keyExtractor={(item) => item}
					renderItem={({item}) => {
						let user = allUsers.find((user) => user._id === item);

						if (!user)
							return (
								<View
									style={tw.style(
										'flex-row items-center justify-between py-4 w-full'
									)}
								>
									<View>
										<Text style={tw.style('text-base font-bold')}>
											Gelöschter Benutzer
										</Text>

										<Text style={tw.style('text-base font-thin')}>
											Ohne Angabe
										</Text>
									</View>
									{chatData.type == 'group' && (
										<TouchableOpacity
											onPress={() => {
												removeUser(item);
											}}
										>
											<Image
												source={require('../../../assets/icons/delete-default.png')}
												style={tw.style('w-4 h-4')}
												resizeMode="contain"
											/>
										</TouchableOpacity>
									)}
								</View>
							);

						return (
							<View
								style={tw.style(
									'flex-row items-center justify-between py-4 w-full'
								)}
							>
								<View>
									<Text style={tw.style('text-base font-bold')}>
										{user.extendedData.firstName === ''
											? user.username
											: user.extendedData.firstName +
											  ' ' +
											  user.extendedData.lastName}{' '}
										{user._id == userInfo._id && '(Du)'}
									</Text>

									<Text style={tw.style('text-base font-thin')}>
										{user.extendedData.additionalEmail == '' ||
										!user.extendedData.additionalEmail
											? user.username.match(
													'^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$'
											  )
												? user.username
												: 'Keine Email vorhanden'
											: user.extendedData.additionalEmail}
									</Text>
								</View>
								{chatData.type == 'group' && (
									<TouchableOpacity
										onPress={() => {
											removeUser(user._id);
										}}
									>
										<Image
											source={require('../../../assets/icons/delete-default.png')}
											style={tw.style('w-4 h-4')}
											resizeMode="contain"
										/>
									</TouchableOpacity>
								)}
							</View>
						);
					}}
					ItemSeparatorComponent={() => (
						<View style={tw.style('border-b border-gray-300 w-full')} />
					)}
					style={tw.style('w-full')}
					ListEmptyComponent={() => {
						if (chatData.users.length == 0)
							return (
								<Text style={tw.style('text-base text-gray-400 py-4')}>
									Keine Teilnehmer
								</Text>
							);

						return (
							<View
								style={tw.style(
									'flex-row items-center justify-between py-4 w-full'
								)}
							>
								<View>
									<Text style={tw.style('text-base font-bold')}>{'(Du)'}</Text>

									<Text style={tw.style('text-base font-thin')}>
										{userInfo.extendedData.additionalEmail == '' ||
										!userInfo.extendedData.additionalEmail
											? userInfo.username.match(
													'^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$'
											  )
												? userInfo.username
												: 'Keine Email vorhanden'
											: userInfo.extendedData.additionalEmail}
									</Text>
								</View>
							</View>
						);
					}}
				/>

				{chatData.type == 'group' && (
					<TouchableOpacity
						style={tw.style(
							'flex-row items-center justify-between py-4 border-t border-gray-300 w-full'
						)}
						onPress={() => {
							sheetRef.current?.present();
						}}
					>
						<Text style={tw.style('text-base mr-3')}>Nutzer hinzufügen</Text>

						<Image
							source={require('../../../assets/icons/close.png')}
							style={tw.style('w-4 h-4')}
							resizeMode="contain"
						/>
					</TouchableOpacity>
				)}
				{chatData.type == 'group' && (
					<View style={tw.style('flex flex-row justify-end mt-24')}>
						<TouchableOpacity
							style={tw.style('bg-accent p-4 px-6 rounded-full')}
							onPress={() => saveChatTitle()}
						>
							<Text style={tw.style('font-semibold text-base text-white')}>
								Speichern
							</Text>
						</TouchableOpacity>
					</View>
				)}
			</View>

			<BottomSheetModal
				ref={sheetRef}
				index={0}
				snapPoints={['50%']}
				style={tw.style('shadow-lg')}
				backdropComponent={() => {
					return (
						<TouchableOpacity
							style={tw.style('flex-1 absolute top-0 left-0 right-0 bottom-0')}
							onPress={() => {
								sheetRef.current?.close();
							}}
						/>
					);
				}}
				enableContentPanningGesture={Platform.OS != 'android'}
				enableHandlePanningGesture={Platform.OS != 'web'}
			>
				<View
					style={tw.style('flex-1 bg-white p-8 w-full max-w-[900px] mx-auto')}
				>
					<Text style={tw.style('text-primary text-4xl font-semibold')}>
						Nutzer hinzufügen
					</Text>
					<Text style={tw.style('text-gray-400 text-base mb-6')}>
						Wähle einen oder mehrere Nutzer aus, die dem Chat hinzugefügt werden
						sollen.
					</Text>
					<ScrollView>
						{allUsers &&
							allUsers
								.filter((user) => !chatData.users.includes(user._id))
								.map((user) => (
									<TouchableOpacity
										onPress={() => {
											// test if user is already in array and remove it

											let tempUser = [...addUsers];
											if (tempUser.includes(user._id)) {
												const index = tempUser.indexOf(user._id);
												if (index > -1) {
													tempUser.splice(index, 1);
												}
											} else {
												tempUser.push(user._id);
											}

											setAddUsers(tempUser);

											setUpdate(update + 1);
										}}
										style={tw.style('flex py-1')}
										key={user._id}
									>
										<View
											key={user._id}
											style={tw.style(
												'flex flex-row items-center justify-between mt-2'
											)}
										>
											<View style={tw.style('flex flex-row items-center')}>
												<View style={tw.style('flex-row items-center mr-4')}>
													<Image
														source={
															user.extendedData?.avatar
																? {
																		uri:
																			process.env.API_URL +
																			'/' +
																			user.extendedData.avatar +
																			'?width=64&height=64&token=' +
																			token,
																  }
																: User
														}
														style={tw.style(
															'w-7 h-7 bg-[#F2F2F2] rounded-full '
														)}
														resizeMode={
															user.extendedData?.avatar ? 'cover' : 'contain'
														}
													/>
												</View>
												<View>
													<Text style={tw.style('ml-2 text-lg')}>
														{user.extendedData.firstName === ''
															? user.username
															: user.extendedData.firstName +
															  ' ' +
															  user.extendedData.lastName}
													</Text>
												</View>
											</View>
											{addUsers.includes(user._id) ? (
												<Image
													source={CheckIcon}
													style={tw.style('w-5 h-5')}
													resizeMode="contain"
												/>
											) : (
												<View style={tw.style('w-5 h-5')} />
											)}
										</View>
									</TouchableOpacity>
								))}

						{allUsers.filter((user) => !addUsers.includes(user._id)).length ==
							0 && (
							<Text style={tw.style('text-base text-gray-400 py-4')}>
								Keine weiteren Nutzer vorhanden
							</Text>
						)}

						<View style={tw.style('flex flex-row justify-end my-4')}>
							<TouchableOpacity
								style={tw.style('bg-accent p-4 px-6 rounded-full')}
								onPress={() => addUser(addUsers)}
							>
								<Text style={tw.style('font-semibold text-base text-white')}>
									Nutzer hinzufügen
								</Text>
							</TouchableOpacity>
						</View>
					</ScrollView>
				</View>
			</BottomSheetModal>
		</ScrollView>
	);
}
