import {
	View,
	Text,
	Image,
	TouchableWithoutFeedback,
	TouchableOpacity,
} from 'react-native';
import React from 'react';
import {useSelector} from 'react-redux';
import tw from '../../lib/tailwind';
import dateFormat from 'dateformat';
import * as WebBrowser from 'expo-web-browser';
import User from '../../assets/icons/user.png';

export default function FileMessage({value, createdAt, createdBy, _id}) {
	const userInfo = useSelector((state) => state.appData.userData);
	const token = useSelector((state) => state.appData.token);

	const allUsers = useSelector((state) => state.appData.allUsers);

	const isUser = userInfo._id === createdBy;

	if (isUser) {
		return (
			<TouchableOpacity
				onPress={() => {
					WebBrowser.openBrowserAsync(
						process.env.API_URL + '/' + value + '?token=' + token
					);
				}}
			>
				<View
					style={tw.style(
						'bg-transparent px-4 py-2 w-full flex-col-reverse justify-end items-end'
					)}
				>
					<Text style={tw.style('text-xs text-gray-400 font-light mr-4')}>
						{dateFormat(createdAt, 'HH:MM')}
					</Text>
					<View style={tw.style('bg-primary rounded-2xl rounded-br-none')}>
						<Text
							style={tw.style(
								'text-white text-xl font-bold px-4 pt-2 uppercase'
							)}
						>
							{value.split('.')[value.split('.').length - 1]}
						</Text>
						<Text style={tw.style('text-white text-sm font-light px-4 pb-2')}>
							Datei öffnen
						</Text>
					</View>
				</View>
			</TouchableOpacity>
		);
	}

	return (
		<TouchableOpacity
			onPress={() => {
				WebBrowser.openBrowserAsync(
					process.env.API_URL + '/' + value + '?token=' + token
				);
			}}
		>
			<View
				style={tw.style(
					'bg-transparent px-4 py-2 w-full flex-col justify-start items-start'
				)}
			>
				<View style={tw.style('bg-accent rounded-2xl p-1 rounded-bl-none')}>
					<Text
						style={tw.style('text-white text-xl font-bold px-4 pt-2 uppercase')}
					>
						{value.split('.')[value.split('.').length - 1]}
					</Text>
					<Text style={tw.style('text-white text-sm font-light px-4 pb-2')}>
						Datei öffnen
					</Text>
				</View>
				<View style={tw.style('flex-row items-center')}>
					<View style={tw.style('flex-row items-center ')}>
						<Image
							source={
								allUsers.find((user) => user._id == createdBy)?.extendedData
									?.avatar
									? {
											uri:
												process.env.API_URL +
												'/' +
												allUsers.find((user) => user._id == createdBy)
													?.extendedData.avatar +
												'?width=64&height=64&token=' +
												token,
									  }
									: User
							}
							style={tw.style('w-6 h-6 my-2 mr-2 bg-[#F2F2F2] rounded-full')}
							resizeMode={
								allUsers.find((user) => user._id == createdBy)?.extendedData
									?.avatar
									? 'cover'
									: 'contain'
							}
						/>
					</View>
					<Text style={tw.style('text-xs text-gray-400 font-light mt-2')}>
						{(allUsers.find((user) => user._id == createdBy)?.extendedData
							.firstName === ''
							? allUsers.find((user) => user._id == createdBy)?.username
							: allUsers.find((user) => user._id == createdBy)?.extendedData
									.firstName +
							  ' ' +
							  allUsers.find((user) => user._id == createdBy)?.extendedData
									.lastName) +
							'  -  ' +
							dateFormat(createdAt, 'HH:MM')}
					</Text>
				</View>
			</View>
		</TouchableOpacity>
	);
}
