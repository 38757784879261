import {
	View,
	Text,
	Image,
	TextInput,
	TouchableOpacity,
	KeyboardAvoidingView,
	Platform,
	TouchableWithoutFeedback,
} from 'react-native';
import React from 'react';
import tw from '../lib/tailwind';
import {useDispatch, useSelector} from 'react-redux';

import dateFormat from 'dateformat';

import User from '../assets/icons/user.png';
import Arrow from '../assets/icons/arrow-right.png';
import {decrypt, encrypt} from '../lib/eTe';
import {useEffect} from 'react';
import {useState} from 'react';
import socket from '../lib/socket';
import {updateAllUsers} from '../redux/slices/appDataSlice';
import {ResizeMode, Video} from 'expo-av';
import ImageView from 'react-native-image-viewing';
import * as WebBrowser from 'expo-web-browser';

export default function PinPost(post) {
	const {
		headerImage,
		description,
		createdBy,
		createdAt,
		comments,
		pinboardID,
		likedBy,
		type,
		_id,
		onLiked = () => {},
		onCommented = () => {},
		onShowOptions = () => {},
		onShowWriteComment = () => {},
	} = post;

	const allUsers = useSelector((state) => state.appData.allUsers);

	const userInfo = useSelector((state) => state.appData.userData);

	const dispatch = useDispatch();

	const [user, setUser] = useState({});

	const token = useSelector((state) => state.appData.token);

	useEffect(() => {
		socket.emit('getUsers', {
			token,
			workspace: userInfo.workspace,
			userID: userInfo._id,
		});

		socket.on('allUser', (users) => {
			dispatch(updateAllUsers(users));
		});
	}, []);

	useEffect(() => {
		setUser(allUsers.find((user) => user._id === createdBy) || {});
	}, [allUsers]);

	const [showAllComments, setShowAllComments] = React.useState(false);
	const [showWriteComment, setShowWriteComment] = React.useState(false);

	const [comment, setComment] = React.useState('');

	const commentInputRef = React.useRef(null);

	const [visibileImageViewer, setVisibileImageViewer] = React.useState(false);

	return (
		<View style={tw.style('')}>
			<View style={tw.style('bg-white w-full rounded-3xl my-2')}>
				<View
					style={tw.style('px-8 py-4 flex-row justify-between items-center')}
				>
					<View style={tw.style('flex flex-row items-center')}>
						<View style={tw.style('flex-row items-center mr-4')}>
							<Image
								source={
									user.extendedData?.avatar
										? {
												uri:
													process.env.API_URL +
													'/' +
													user.extendedData.avatar +
													'?width=64&height=64&token=' +
													token,
										  }
										: User
								}
								style={tw.style('w-12 h-12 bg-[#F2F2F2] rounded-full ')}
								resizeMode={user.extendedData?.avatar ? 'cover' : 'contain'}
							/>
						</View>
						<View>
							<Text style={tw.style('ml-2 text-lg')}>
								{user.hasOwnProperty('extendedData')
									? user.extendedData?.firstName === ''
										? user.username
										: user.extendedData?.firstName +
										  ' ' +
										  user.extendedData?.lastName
									: 'Gelöschter Benutzer'}
							</Text>
							<Text style={tw.style('ml-2 text-xs')}>
								{dateFormat(new Date(createdAt), 'd. mmmm HH:MM "Uhr"')}
							</Text>
						</View>
					</View>
					{user._id == userInfo._id && (
						<Text
							style={tw.style('text-xl p-3')}
							onPress={() => {
								onShowOptions();
							}}
						>
							...
						</Text>
					)}
				</View>

				{headerImage ? (
					type == 'image' || !type ? (
						<TouchableOpacity onPress={() => setVisibileImageViewer(true)}>
							<>
								<Image
									source={{
										uri: `${process.env.API_URL}/${headerImage}?token=${token}`,
									}}
									style={tw.style(
										'w-full aspect-square ' +
											(Platform.OS == 'web'
												? 'object-contain'
												: 'object-contain')
									)}
									resizeMode={Platform.OS == 'web' ? 'contain' : 'contain'}
								/>
								<ImageView
									images={[
										{
											uri: `${process.env.API_URL}/${headerImage}?token=${token}`,
										},
									]}
									imageIndex={0}
									visible={visibileImageViewer}
									onRequestClose={() => setVisibileImageViewer(false)}
								/>
							</>
						</TouchableOpacity>
					) : type == 'video' || headerImage.toLowerCase().includes('mp4') ? (
						<Video
							resizeMode={ResizeMode.CONTAIN}
							source={{
								uri:
									process.env.API_URL + '/' + headerImage + '?token=' + token,
							}}
							style={tw.style('w-full aspect-video')}
							useNativeControls
							posterStyle={tw.style('w-full aspect-video')}
							videoStyle={tw.style('w-full aspect-video')}
						/>
					) : (
						<TouchableOpacity
							onPress={() => {
								WebBrowser.openBrowserAsync(
									process.env.API_URL + '/' + headerImage + '?token=' + token
								);
							}}
						>
							<Text
								style={tw.style(
									'text-black text-lg font-bold px-4 pt-8 uppercase'
								)}
							>
								{headerImage.split('.')[headerImage.split('.').length - 1]}
							</Text>
							<Text style={tw.style('text-black text-sm font-light px-4 pb-2')}>
								Datei öffnen
							</Text>
						</TouchableOpacity>
					)
				) : (
					<></>
				)}

				<View style={tw.style('pt-4 pb-6 px-8')}>
					{description !== '' && (
						<View
							style={tw.style(
								'flex-row justify-between items-center pb-4 mb-4'
							)}
						>
							<Text style={tw.style('text-lg text-gray-700')}>
								{decrypt(description, pinboardID)}
							</Text>
						</View>
					)}
					<View
						style={tw.style(
							'flex-row justify-between items-center flex-wrap border-b border-gray-200 pb-4'
						)}
					>
						<View style={tw.style('flex-row items-center')}>
							<Image
								source={require('../assets/icons/like.png')}
								style={tw.style('w-4 h-4 mr-2')}
								resizeMode="contain"
							/>
							<Text style={tw.style('text-sm text-gray-400')}>
								{likedBy.length}
							</Text>
						</View>
						<View style={tw.style('flex-row items-center')}>
							<Image
								source={require('../assets/icons/chat-default.png')}
								style={tw.style('w-4 h-4 mr-2')}
								resizeMode="contain"
							/>
							<Text
								style={tw.style('text-sm text-gray-400')}
								onPress={() => setShowAllComments(!showAllComments)}
							>
								{comments.length} Kommentar
								{comments.length > 1 || comments.length == 0 ? 'e' : ''}
							</Text>
						</View>
					</View>

					<View style={tw.style('flex-row justify-between items-center pt-4')}>
						<View style={tw.style('flex-row items-center')}>
							<Image
								source={
									likedBy.find((user) => user === userInfo._id)
										? require('../assets/icons/like-filled.png')
										: require('../assets/icons/like.png')
								}
								style={tw.style('w-4 h-4 mr-2')}
								resizeMode="contain"
							/>
							<Text
								style={tw.style('text-sm text-gray-400')}
								onPress={() => onLiked(_id)}
							>
								{likedBy.find((user) => user === userInfo._id)
									? `gefällt mir nicht mehr`
									: `gefällt mir`}
							</Text>
						</View>
						<View style={tw.style('flex-row items-center')}>
							<Image
								source={require('../assets/icons/chat-default.png')}
								style={tw.style('w-4 h-4 mr-2')}
								resizeMode="contain"
							/>
							<Text
								style={tw.style('text-sm text-gray-400')}
								onPress={() => {
									if (!showWriteComment) {
										setShowAllComments(true);
										setTimeout(() => {
											if (!commentInputRef.current) return;

											commentInputRef.current.focus();
											onShowWriteComment();
										}, 100);
									}

									setShowWriteComment(!showWriteComment);
								}}
							>
								kommentieren
							</Text>
						</View>
					</View>
					{showAllComments &&
						comments.map((comment) => {
							const commentedUser =
								allUsers.find((user) => user._id === comment.createdBy) || {};

							return (
								<View
									style={tw.style(
										'flex-row border-t border-gray-200 pt-4 mt-4'
									)}
									key={comment._id}
								>
									<View style={tw.style('flex-row mr-4')}>
										<Image
											source={
												commentedUser.extendedData?.avatar
													? {
															uri:
																process.env.API_URL +
																'/' +
																commentedUser.extendedData.avatar +
																'?width=64&height=64&token=' +
																token,
													  }
													: User
											}
											style={tw.style('w-8 h-8 bg-[#F2F2F2] rounded-full ')}
											resizeMode={
												commentedUser.extendedData?.avatar ? 'cover' : 'contain'
											}
										/>
									</View>
									<View>
										<Text
											style={tw.style('text-base text-gray-800 font-semibold ')}
										>
											{commentedUser.hasOwnProperty('extendedData')
												? commentedUser.extendedData?.firstName === ''
													? commentedUser.username
													: commentedUser.extendedData?.firstName +
													  ' ' +
													  commentedUser.extendedData?.lastName
												: 'Gelöschter Benutzer'}
										</Text>
										<Text style={tw.style('text-base text-gray-700 w-[75vw]')}>
											{decrypt(comment.comment, pinboardID)}
										</Text>
										<Text style={tw.style('text-xs text-gray-400')}>
											{dateFormat(
												new Date(comment.createdAt),
												'd. mmmm HH:MM "Uhr"'
											)}
										</Text>
									</View>
								</View>
							);
						})}
				</View>
			</View>
			{showWriteComment && (
				<View
					style={tw.style(
						'bg-white w-full rounded-3xl my-2 p-4 px-8 mb-12 flex-row items-center justify-between '
					)}
				>
					<View style={tw.style('flex-row items-center mr-4')}>
						<Image
							source={
								userInfo.extendedData?.avatar
									? {
											uri:
												process.env.API_URL +
												'/' +
												userInfo.extendedData.avatar +
												'?width=64&height=64&token=' +
												token,
									  }
									: User
							}
							style={tw.style('w-8 h-8 bg-[#F2F2F2] rounded-full ')}
							resizeMode={userInfo?.extendedData?.avatar ? 'cover' : 'contain'}
						/>
					</View>
					<TextInput
						style={[
							tw.style('text-lg flex-1 '),
							{
								lineHeight: 20,
							},
						]}
						numberOfLines={1}
						placeholder="Schreibe einen Kommentar..."
						value={comment}
						onChangeText={(text) => setComment(text)}
						onKeyPress={(e) => {
							if (e.nativeEvent.key === 'Enter') {
								if (comment === '') return;

								onCommented(_id, encrypt(comment, pinboardID));
								setComment('');
							}
						}}
						ref={commentInputRef}
					/>
					<TouchableOpacity
						style={[
							tw.style(
								'bg-accent p-3 w-10 h-10 flex justify-center items-center rounded-full ml-3'
							),
							{zIndex: 100},
						]}
						onPress={(e) => {
							e.preventDefault();
							if (comment === '') return;

							onCommented(_id, encrypt(comment, pinboardID));
							setComment('');
						}}
					>
						<Image
							source={Arrow}
							style={tw.style('flex-1 tint-white w-10 h-10')}
							resizeMode="contain"
						/>
					</TouchableOpacity>
				</View>
			)}
		</View>
	);
}
