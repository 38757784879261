import * as Device from 'expo-device';
import * as Notifications from 'expo-notifications';
import {Platform} from 'react-native';

export default async function registerForPushNotificationsAsync() {
	let token;
	if (Device.isDevice) {
		const {status: existingStatus} = await Notifications.getPermissionsAsync(
			{}
		);
		let finalStatus = existingStatus;
		if (existingStatus !== 'granted') {
			const {status} = await Notifications.requestPermissionsAsync();
			finalStatus = status;
		}
		if (finalStatus !== 'granted') {
			// alert('Leider konnten wir keine Push-Benachrichtigungen einstellen, dies kann dara');
			return;
		}
		token = (
			await Notifications.getExpoPushTokenAsync({
				projectId: 'b7e1caef-112f-4fba-812d-910b863255e7',
			})
		).data;
		console.log(token);
	} else {
		alert('Must use physical device for Push Notifications');
	}

	if (Platform.OS === 'android') {
		Notifications.setNotificationChannelAsync('default', {
			name: 'default',
			importance: Notifications.AndroidImportance.MAX,
			vibrationPattern: [0, 250, 250, 250],
			lightColor: '#FF231F7C',
		});
	}

	return token;
}
