import {
	View,
	Text,
	TextInput,
	TouchableOpacity,
	Image,
	Keyboard,
	Platform,
	TouchableWithoutFeedback,
	ScrollView,
	ActivityIndicator,
	FlatList,
	KeyboardAvoidingView,
} from 'react-native';
import React, {useEffect, useState} from 'react';
import ChatHeader from '../../../components/chatHeader';
import tw from '../../../lib/tailwind';
import Arrow from '../../../assets/icons/arrow-right.png';
import Close from '../../../assets/icons/close.png';
import Klammer from '../../../assets/icons/Klammer.png';
import TakeSnap from '../../../assets/icons/takesnap.png';
import {useNavigation} from '@react-navigation/native';
import {useDispatch, useSelector} from 'react-redux';
import socket from '../../../lib/socket';
import {
	updateAllChats,
	updateChatByID,
} from '../../../redux/slices/appDataSlice';

import axios from 'axios';
import TextMessage from '../../../components/messageTemplate/textMessage';
import dateFormat from 'dateformat';

import * as ImagePicker from 'expo-image-picker';
import ImageMessage from '../../../components/messageTemplate/imageMessage';
import {useRef} from 'react';
import {decrypt, encrypt} from '../../../lib/eTe';
import VideoMessage from '../../../components/messageTemplate/videoMessage';
import FileMessage from '../../../components/messageTemplate/fileMessage';
import * as DocumentPicker from 'expo-document-picker';
import {addHeNotification} from '../../../lib/HeNotifications/HeNotificationList';

export default function SingleChat(props) {
	const {chatID} = props.route.params;

	const allChats = useSelector((state) => state.appData.allChats);

	const [chatData, setChatData] = useState(
		allChats.find((chat) => chat._id === chatID)
	);

	const [messagesData, setMessagesData] = useState(chatData?.messages || []);

	const userInfo = useSelector((state) => state.appData.userData);

	const navigation = useNavigation();

	const dispatch = useDispatch();

	const tabBarHeight = useSelector((state) => state.appData.tabBarHeight);

	const token = useSelector((state) => state.appData.token);

	const [isKeyboardVisible, setKeyboardVisible] = useState(false);

	const [message, setMessage] = useState('');

	const [sendingMessage, setSendingMessage] = useState(false);

	const textInputRef = useRef();

	useEffect(() => {
		const keyboardWillShowListener = Keyboard.addListener(
			'keyboardWillShow',
			() => {
				setKeyboardVisible(true); // or some other action
				console.log('keyboard shown');
			}
		);
		const keyboardWillHideListener = Keyboard.addListener(
			'keyboardWillHide',
			() => {
				setKeyboardVisible(false); // or some other action
				console.log('keyboard hidden');
			}
		);

		const keyboardDidShowListener = Keyboard.addListener(
			'keyboardDidShow',
			() => {
				setKeyboardVisible(true); // or some other action
				console.log('keyboard shown');
			}
		);
		const keyboardDidHideListener = Keyboard.addListener(
			'keyboardDidHide',
			() => {
				setKeyboardVisible(false); // or some other action
				console.log('keyboard hidden');
			}
		);

		if (Platform.OS == 'web') {
			window.addEventListener('paste', sendImageFromClipboard);
		}

		testPermission();

		getAllChats();

		return () => {
			if (Platform.OS == 'web') {
				window.removeEventListener('paste', sendImageFromClipboard);
			}

			keyboardDidHideListener.remove();
			keyboardDidShowListener.remove();
			keyboardWillHideListener.remove();
			keyboardWillShowListener.remove();
		};
	}, []);

	useEffect(() => {
		if (scrollRef.current) {
			scrollRef.current.scrollToEnd({animated: true});
		}
	}, [scrollRef]);

	function sendImageFromClipboard(e) {
		if (e.clipboardData.files.length > 0) {
			//console.log(e.clipboardData.files[0]);

			// get the first image from clipboard with uri
			let image = e.clipboardData.files[0];

			console.log(image);

			if (image.type.includes('image')) {
				sendImage(image);
			}
		}
	}

	async function testPermission() {
		if (Platform.OS !== 'web') {
			const {status} = await ImagePicker.requestMediaLibraryPermissionsAsync();
			console.log(status, 'Libary');
			if (status !== 'granted') {
				alert('Um Bilder hochzuladen, benötigen wir deine Erlaubnis!');
			}

			const {status: status2} =
				await ImagePicker.requestCameraPermissionsAsync();

			if (status2 !== 'granted') {
				alert('Um Bilder hochzuladen, benötigen wir deine Erlaubnis!');
			}
			console.log(status2, 'Camera');
		}
	}

	useEffect(() => {
		if (allChats.length == 0) return;

		const findChat = allChats.find((chat) => chat._id === chatID);

		if (!findChat) {
			const routes = navigation.getState().routes;
			const todoRoute = routes.find((r) => r.name === 'Chats');
			if (todoRoute) {
				navigation.navigate('Chats', {
					params: {
						merge: true,
					},
				});
			} else {
				navigation.replace('Chats', {
					merge: true,
				});
			}

			addHeNotification({
				title: 'Chat nicht gefunden',
				message: 'Der Chat konnte nicht gefunden werden',
				type: 'error',
				duration: 5000,
			});
			return;
		}

		setChatData(findChat);
		setMessagesData(findChat.messages);
	}, [allChats]);

	useEffect(() => {
		let subscribe = navigation.addListener('focus', () => {
			getAllChats();
		});
		return subscribe;
	}, [navigation]);

	useEffect(() => {
		navigation.setOptions({
			header: () => {
				return (
					<ChatHeader
						chatData={
							chatData || {
								title: 'Lade...',
								users: [],
								type: 'single',
							}
						}
						onGoBack={() => {
							const routes = navigation.getState().routes;
							const todoRoute = routes.find((r) => r.name === 'Chats');
							if (todoRoute) {
								navigation.navigate('Chats', {
									params: {
										merge: true,
									},
								});
							} else {
								navigation.replace('Chats', {
									merge: true,
								});
							}
						}}
						onDetails={() =>
							navigation.navigate('ChatDetails', {
								chatID: chatID,
							})
						}
					/>
				);
			},
			headerShown: true,
		});
	}, [navigation, chatData]);

	async function getAllChats() {
		try {
			let response = await axios.get(
				process.env.API_URL + '/' + userInfo.workspace + '/chat/',
				{
					headers: {
						Authorization: 'Bearer ' + token,
					},
				}
			);

			dispatch(updateAllChats(response.data.chats));
			if (scrollRef.current) {
				scrollRef.current.scrollToEnd({animated: true});
			}
		} catch (error) {
			console.log(error);
		}
	}

	async function sendMessage() {
		if (message.trim() === '' || sendingMessage) {
			return;
		}

		setSendingMessage(true);
		const tempMessage = {
			value: encrypt(message, chatID),
			type: 'text',
		};

		try {
			let res = await axios.post(
				process.env.API_URL +
					'/' +
					userInfo.workspace +
					'/chat/' +
					chatID +
					'/messages',
				tempMessage,
				{
					headers: {
						Authorization: 'Bearer ' + token,
					},
				}
			);

			dispatch(updateChatByID(res.data.updatedChat));
			setChatData(res.data.updatedChat);
			setMessagesData(res.data.updatedChat.messages);

			let tempUsers = chatData.users.filter((user) => user !== userInfo._id);

			socket.emit('sendMessage', {
				users: tempUsers,
				chatID: chatID,
			});

			setMessage('');

			textInputRef.current.focus();
			setSendingMessage(false);

			//updateData();
		} catch (error) {
			console.log(error);
			setSendingMessage(false);
		}
	}

	async function openCamera() {
		console.log('open camera');

		try {
			let result = await ImagePicker.launchCameraAsync({
				mediaTypes: ImagePicker.MediaTypeOptions.All,
				allowsEditing: true,

				cancelButtonTitle: 'Abbrechen',
			});
			console.log('result', result);
			if (!result.canceled) {
				sendImage(result.assets[0]);
			}
		} catch (error) {
			console.log(error);
		}
	}

	async function openGallery() {
		let result = await ImagePicker.launchImageLibraryAsync({
			mediaTypes: ImagePicker.MediaTypeOptions.All,
			allowsEditing: true,
		});

		if (!result.canceled) {
			sendImage(result.assets[0]);
		}
	}

	async function openFilePicker() {
		let result = await DocumentPicker.getDocumentAsync({
			copyToCacheDirectory: true,
			multiple: false,
		});

		if (!result.canceled) {
			sendImage(result.assets[0]);
		}
	}

	function dataURLtoFile(dataurl, filename) {
		var arr = dataurl.split(','),
			mime = arr[0].match(/:(.*?);/)[1],
			bstr = atob(arr[1]),
			n = bstr.length,
			u8arr = new Uint8Array(n);

		while (n--) {
			u8arr[n] = bstr.charCodeAt(n);
		}

		if (!filename) {
			// get file extension from base64
			let extension = mime.split('/')[1];
			// create filename
			filename = 'file.' + extension;
		}

		return new File([u8arr], filename, {type: mime});
	}

	function getFileType(image) {
		if (!image) return;
		const FileType = {
			FILE: 'file',
			VIDEO: 'video',
			IMAGE: 'image',
		};

		const imageExtensions = [
			'jpg',
			'jpeg',
			'png',
			'gif',
			'bmp',
			'webp',
			'tiff',
			'psd',
			'svg',
			'heif',
			'image',
		];
		const videoExtensions = [
			'mp4',
			'mov',
			'avi',
			'flv',
			'wmv',
			'm4v',
			'mkv',
			'webm',
			'mpg',
			'mpeg',
			'3gp',
			'video',
		];

		if (Platform.OS == 'web') {
			console.log('image', image);
			let extension =
				image.mimeType?.split('/')[1]?.toLowerCase() ||
				image.uri.split('/')[1].split(';')[0].toLowerCase() ||
				image.uri.split('.')?.pop()?.toLowerCase();

			console.log('extension', extension);

			if (imageExtensions.includes(extension)) {
				return FileType.IMAGE;
			} else if (videoExtensions.includes(extension)) {
				return FileType.VIDEO;
			} else {
				return FileType.FILE;
			}
		}

		let extension = image.uri.split('.').pop().toLowerCase();
		console.log('extension', extension);

		if (imageExtensions.includes(extension)) {
			return FileType.IMAGE;
		} else if (videoExtensions.includes(extension)) {
			return FileType.VIDEO;
		} else {
			return FileType.FILE;
		}
	}

	function checkForRightFileType(file) {
		if (!file) return false;

		const AllowedFileTypes = [
			'jpg',
			'jpeg',
			'png',
			'gif',
			'heif',
			'bmp',
			'tiff',
			'svg',
			'mp4',
			'avi',
			'mov',
			'wmv',
			'flv',
			'mkv',
			'pdf',
			'txt',
			'md',
			'rtf',
			'odt',
			'doc',
			'docx',
			'xls',
			'xlsx',
			'ppt',
			'pptx',
			'mp3',
			'wav',
			'aac',
			'flac',
			'ogg',
			'm4a',
		];

		if (Platform.OS == 'web') {
			let extension =
				file.mimeType?.split('/')[1]?.toLowerCase() ||
				file.uri.split('/')[1].split(';')[0].toLowerCase() ||
				file.uri.split('.')?.pop()?.toLowerCase();
			return AllowedFileTypes.includes(extension);
		}

		let extension = file.uri.split('.').pop().toLowerCase();

		return AllowedFileTypes.includes(extension);
	}

	async function sendImage(image) {
		if (!image) {
			return;
		}

		console.log('image', image);

		// when file bigger than 75mb return
		if (image.fileSize > 75000000 || image.size > 75000000) {
			addHeNotification({
				title: 'Medien hochladen',
				message:
					'Die Datei ist zu groß. Bitte wähle eine Datei aus, die kleiner als 75MB ist.',
				type: 'error',
				duration: 5000,
			});
			return;
		}

		if (!checkForRightFileType(image)) {
			addHeNotification({
				title: 'Medien hochladen',
				message:
					'Die Datei hat ein nicht erlaubtes Format. Bitte wähle eine Datei aus, die ein erlaubtes Format hat.',
				type: 'error',
				duration: 5000,
			});
			return;
		}

		let formData = new FormData();
		formData.append(
			'file',
			Platform.OS != 'web'
				? {
						uri: image.uri,
						type:
							image.mimeType ||
							(Platform.OS == 'ios'
								? image.type
								: `${image.type}/${image.uri
										.split('.')
										.pop()
										.toLowerCase()
										.replace('jpeg', 'jpg')}`),
						name: image.uri.split('/').pop(),
				  }
				: image.webkitRelativePath == ''
				? image
				: dataURLtoFile(image.uri, image.name)
		);
		formData.append('type', getFileType(image));

		console.log('formData', formData);

		try {
			let res = await axios.post(
				process.env.API_URL +
					'/' +
					userInfo.workspace +
					'/chat/' +
					chatID +
					'/messages/file',
				formData,
				{
					headers: {
						Authorization: 'Bearer ' + token,
						'Content-Type': 'multipart/form-data',
					},
				}
			);

			dispatch(updateChatByID(res.data.updatedChat));
			setChatData(res.data.updatedChat);
			setMessagesData(res.data.updatedChat.messages);

			let tempUsers = chatData.users.filter((user) => user !== userInfo._id);

			socket.emit('sendMessage', {
				users: tempUsers,
				chatID: chatID,
			});

			///updateData();
		} catch (error) {
			console.log(JSON.stringify(error));
		}
	}

	const scrollRef = useRef();

	function getMessageContainer(message) {
		try {
			switch (message.type) {
				case 'text':
					return (
						<TextMessage
							value={decrypt(message.value, chatID)}
							createdAt={message.createdAt}
							createdBy={message.createdBy}
							_id={message._id}
						/>
					);
				case 'image':
					return (
						<ImageMessage
							value={message.value}
							createdAt={message.createdAt}
							createdBy={message.createdBy}
							_id={message._id}
						/>
					);
				case 'video':
					return (
						<VideoMessage
							value={message.value}
							createdAt={message.createdAt}
							createdBy={message.createdBy}
							_id={message._id}
						/>
					);
				case 'file':
					return (
						<FileMessage
							value={message.value}
							createdAt={message.createdAt}
							createdBy={message.createdBy}
							_id={message._id}
						/>
					);

				default:
					return <></>;
			}
		} catch (error) {
			console.log('Error while decrypting message: ' + message._id, error);
		}
	}

	return (
		<KeyboardAvoidingView
			behavior={Platform.OS == 'ios' ? 'padding' : 'height'}
			style={tw.style('flex-1')}
			keyboardVerticalOffset={Platform.OS == 'ios' ? tabBarHeight + 20 : 0}
		>
			<TouchableWithoutFeedback
				onPress={() => {
					if (Platform.OS != 'web') {
						Keyboard.dismiss();
					}
				}}
				style={tw.style('flex-1')}
			>
				<View style={tw.style('flex-1')}>
					<View style={tw.style('flex-1')}>
						<ScrollView
							ref={scrollRef}
							onContentSizeChange={() =>
								scrollRef.current.scrollToEnd({animated: true})
							}
							style={tw.style('w-full flex-1')}
							contentContainerStyle={tw.style('w-full max-w-[900px] mx-auto')}
						>
							{messagesData.map((message, index) => {
								// if createdAt is a day after the last message, show the date

								let renderDate = <></>;

								if (
									index > 0 &&
									dateFormat(message.createdAt, 'dd.mm.yyyy') !==
										dateFormat(messagesData[index - 1].createdAt, 'dd.mm.yyyy')
								) {
									let dateString = dateFormat(message.createdAt, 'dd.mm.yyyy');

									if (
										dateFormat(message.createdAt, 'dd.mm.yyyy') ==
										dateFormat(new Date(), 'dd.mm.yyyy')
									) {
										dateString = 'heute';
									} else if (
										dateFormat(message.createdAt, 'dd.mm.yyyy') ==
										dateFormat(
											new Date(new Date().setDate(new Date().getDate() - 1)),
											'dd.mm.yyyy'
										)
									) {
										dateString = 'gestern';
									} else if (
										dateFormat(message.createdAt, 'dd.mm.yyyy') ==
										dateFormat(
											new Date(new Date().setDate(new Date().getDate() - 2)),
											'dd.mm.yyyy'
										)
									) {
										dateString = 'vorgestern';
									}

									renderDate = (
										<Text
											style={tw.style(
												'text-primary font-bold text-base text-center mt-5'
											)}
										>
											{dateString}
										</Text>
									);
								}

								if (index == 0) {
									let dateString = dateFormat(message.createdAt, 'dd.mm.yyyy');

									if (
										dateFormat(message.createdAt, 'dd.mm.yyyy') ==
										dateFormat(new Date(), 'dd.mm.yyyy')
									) {
										dateString = 'heute';
									} else if (
										dateFormat(message.createdAt, 'dd.mm.yyyy') ==
										dateFormat(
											new Date(new Date().setDate(new Date().getDate() - 1)),
											'dd.mm.yyyy'
										)
									) {
										dateString = 'gestern';
									} else if (
										dateFormat(message.createdAt, 'dd.mm.yyyy') ==
										dateFormat(
											new Date(new Date().setDate(new Date().getDate() - 2)),
											'dd.mm.yyyy'
										)
									) {
										dateString = 'vorgestern';
									}

									renderDate = (
										<Text
											style={tw.style(
												'text-primary font-bold text-base text-center mb-5'
											)}
										>
											{dateString}
										</Text>
									);
								}

								return (
									<View key={message._id} style={tw.style('w-full')}>
										{renderDate}
										{getMessageContainer(message)}
									</View>
								);
							})}
						</ScrollView>
						{messagesData.length == 0 && (
							<View style={tw.style('flex justify-center items-center px-4')}>
								<Text style={tw.style('text-primary font-bold text-base')}>
									heute
								</Text>
								<Text style={tw.style('text-gray-400 text-base mt-2 mb-6')}>
									ihr habt akutell noch keine nachrichten ausgetauscht
								</Text>
							</View>
						)}
					</View>

					{chatData?.status != 'closed' && (
						<View style={tw.style('bg-white px-4 py-4')}>
							<View style={tw.style('w-full max-w-[900px] mx-auto')}>
								<View style={tw.style('flex flex-row items-center')}>
									<TextInput
										style={tw.style(
											'bg-white leading-tight border border-primary rounded-full flex-1 p-3 shadow-md'
										)}
										placeholder="Nachricht schreiben"
										onSubmitEditing={() => {
											if (sendingMessage) return;
											sendMessage();
										}}
										onChangeText={(text) => setMessage(text)}
										value={message}
										ref={textInputRef}
									/>
									<TouchableOpacity
										style={tw.style(
											'bg-accent p-3 w-10 h-10 flex justify-center items-center rounded-full ml-3'
										)}
										onPress={() => {
											if (sendingMessage) return;
											sendMessage();
										}}
									>
										{!sendingMessage ? (
											<Image
												source={Arrow}
												style={tw.style('flex-1 tint-white w-10 h-10')}
												resizeMode="contain"
											/>
										) : (
											<ActivityIndicator color="white" />
										)}
									</TouchableOpacity>
								</View>
							</View>

							{!isKeyboardVisible && (
								<View style={tw.style('w-full max-w-[900px] mx-auto')}>
									<View style={tw.style('bg-gray-100 h-.25 w-full my-4')} />
									<View style={tw.style('flex flex-row justify-end')}>
										<TouchableOpacity
											style={tw.style(
												'bg-accent p-2 w-8 h-8 flex justify-center items-center rounded-full ml-3'
											)}
											onPress={() => openFilePicker()}
										>
											<Image
												source={Klammer}
												style={tw.style('flex-1 tint-white w-8 h-8')}
												resizeMode="contain"
											/>
										</TouchableOpacity>
										<TouchableOpacity
											style={tw.style(
												'bg-accent p-2 w-8 h-8 flex justify-center items-center rounded-full ml-3'
											)}
											onPress={() => openGallery()}
										>
											<Image
												source={Close}
												style={tw.style('flex-1 tint-white w-8 h-8')}
												resizeMode="contain"
											/>
										</TouchableOpacity>
										{Platform.OS != 'web' && (
											<TouchableOpacity
												style={tw.style(
													'bg-accent p-2 w-8 h-8 flex justify-center items-center rounded-full ml-3'
												)}
												onPress={() => openCamera()}
											>
												<Image
													source={TakeSnap}
													style={tw.style('flex-1 tint-white w-8 h-8')}
													resizeMode="contain"
												/>
											</TouchableOpacity>
										)}
									</View>
								</View>
							)}
						</View>
					)}
				</View>
			</TouchableWithoutFeedback>
		</KeyboardAvoidingView>
	);
}
