import {
	View,
	Text,
	Platform,
	Image,
	Touchable,
	TouchableOpacity,
	AppState,
} from 'react-native';
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {createNativeStackNavigator} from '@react-navigation/native-stack';
import HomeIndex from './screens/app/homeIndex';
import Register from './screens/auth/register';
import CreateWorkspace from './screens/auth/createWorkspace';
import SetPassword from './screens/auth/setPassword';
import Login from './screens/auth/login';
import {
	useNavigation,
	useNavigationContainerRef,
} from '@react-navigation/native';
import * as Notifications from 'expo-notifications';
import {
	setMessageData,
	setPushNotificationToken,
	updateToken,
	updateUserData,
	updateWorkspaceData,
} from './redux/slices/appDataSlice';
import AddTodo from './screens/addTodo';
import tw from './lib/tailwind';
import AddPinboard from './screens/addPinboard';
import AddPost from './screens/addPost';
import ShowNotifications from './screens/showNotifications';
import UserProfile from './screens/userProfile';
import Settings from './screens/settings';
import * as SecureStore from 'expo-secure-store';
import registerForPushNotificationsAsync from './lib/registerForPushNotificationsAsync';
import {useRef} from 'react';
import ResetPassword from './screens/auth/ResetPassword';
import ForgotPassword from './screens/auth/ForgotPassword';
import axios from 'axios';
import IconBack from './assets/icons/arrow-left.png';
import ActivateUser from './screens/activation';
import * as Linking from 'expo-linking';

Notifications.setNotificationHandler({
	handleNotification: async () => ({
		shouldShowAlert: true,
		shouldPlaySound: false,
		shouldSetBadge: false,
	}),
});

export default function Navigator() {
	const Stack = createNativeStackNavigator();

	const token = useSelector((state) => state.appData.token);

	const navigation = useNavigation();
	const dispatch = useDispatch();

	const [didMount, setDidMount] = React.useState(false);

	const initialUrl = Linking.useURL();

	const notificationListener = useRef();
	const responseListener = useRef();

	let temp;

	if (!didMount) {
		if (Platform.OS === 'web') {
			if (!token) {
				let tempToken = localStorage.getItem('token');
				let tempUser = localStorage.getItem('user');
				let tempWorkspace = localStorage.getItem('workspace');

				if (tempToken) {
					dispatch(updateToken(tempToken));
					dispatch(updateUserData(JSON.parse(tempUser)));
					dispatch(updateWorkspaceData(JSON.parse(tempWorkspace)));
				}
			}
		}
	}

	useEffect(() => {
		// If there is an initial URL, parse and handle it
		if (initialUrl) {
			console.log('initialUrl', initialUrl);
			handleDeepLink(initialUrl);
		}
	}, [initialUrl]);

	//health - check
	useEffect(() => {
		if (!token) return;
		// check every 30 seconds if the user still exists
		const interval = setInterval(() => {
			if (token) {
				checkHealth();
			}
		}, 1000 * 30);
		return () => clearInterval(interval);
	}, [token]);

	useEffect(() => {
		let stateListener = AppState.addEventListener('change', (state) => {
			if (state === 'active') {
				// check for deep links
				handleDeepLink(initialUrl);
			}
		});

		return () => {
			stateListener.remove();
		};
	}, []);

	const handleDeepLink = (url) => {
		if (!url) return;
		// Parse the link
		const {hostname, path, queryParams} = Linking.parse(url);
		// Navigate to the appropriate screen or content based on the link
		if (!path) {
			return;
		}
		console.log('handleDeepLink 1 ', path, queryParams);
		if (path == 'ResetPassword') {
			console.log('handleDeepLink2 ', path, queryParams);
			navigation.navigate({
				name: 'ResetPassword',
				params: queryParams,
			});
		}
	};

	useEffect(() => {
		if (Platform.OS === 'web') {
			if (!token) {
				let tempToken = localStorage.getItem('token');
				let tempUser = localStorage.getItem('user');
				let tempWorkspace = localStorage.getItem('workspace');

				dispatch(updateToken(tempToken));
				dispatch(updateUserData(JSON.parse(tempUser)));
				dispatch(updateWorkspaceData(JSON.parse(tempWorkspace)));
			}

			if (
				window.location.href.includes('Register') ||
				window.location.href.includes('activateUser')
			) {
				return;
			}
		}

		setDidMount(true);

		if (Platform.OS === 'web') {
			let path = window.location.pathname;

			if (path.includes('/ResetPassword')) {
				return;
			}
		}
		if (token) {
			navigation.navigate('App');
		}
	}, [token]);

	useEffect(() => {
		if (Platform.OS === 'web') {
			dispatch(setPushNotificationToken(token));
		} else {
			registerForPushNotificationsAsync().then((token) =>
				dispatch(setPushNotificationToken(token))
			);
			testForPersistLogin();

			notificationListener.current =
				Notifications.addNotificationReceivedListener((notification) => {
					notificationCommonHandler(notification);
				});

			// This listener is fired whenever a user taps on or interacts with a notification
			// (works when app is foregrounded, backgrounded, or killed)
			responseListener.current =
				Notifications.addNotificationResponseReceivedListener((response) => {
					notificationCommonHandler(response.notification);
					notificationNavigationHandler(response.notification.request.content);
				});
		}

		return () => {
			Notifications.removeNotificationSubscription(notificationListener);
			Notifications.removeNotificationSubscription(responseListener);
		};
	}, []);

	async function testForPersistLogin() {
		const token = await SecureStore.getItemAsync('token');
		const user = await SecureStore.getItemAsync('user');
		const workspace = await SecureStore.getItemAsync('workspace');
		const lastMessages = await SecureStore.getItemAsync('lastMessages');

		if (token && user && workspace && lastMessages) {
			dispatch(updateToken(token));
			dispatch(updateUserData(JSON.parse(user)));
			dispatch(updateWorkspaceData(JSON.parse(workspace)));
			dispatch(setMessageData(JSON.parse(lastMessages)));

			navigation.navigate('App');
		}
	}

	const notificationCommonHandler = (notification) => {
		// save the notification to reac-redux store
		console.log('A notification has been received', notification);
	};

	const notificationNavigationHandler = ({data}) => {
		// navigate to app screen
		console.log('A notification has been touched', data);

		if (token) {
			navigation.navigate(data.screen, data.params);
		}
	};
	let healthRunning = false;

	useEffect(() => {
		let timeout;
		navigation.addListener('state', () => {
			console.log('state', healthRunning);

			if (!token || healthRunning) {
				return;
			}

			healthRunning = true;
			checkHealth();

			timeout = setTimeout(() => {
				healthRunning = false;
			}, 1000 * 60 * 5); // 5 minutes
		});

		return () => {
			clearTimeout(timeout);
			navigation.removeListener('focus');
		};
	}, []);

	async function checkHealth() {
		try {
			let res = await axios.get(process.env.API_URL + '/user/health', {
				headers: {
					Authorization: 'Bearer ' + token,
				},
			});
			dispatch(updateUserData(res.data.user));
		} catch (error) {
			if (error.response.status === 400) {
				handleLogout();
			}
		}
	}

	async function handleLogout() {
		navigation.navigate('App');

		if (Platform.OS === 'web') {
			localStorage.removeItem('token');
			localStorage.removeItem('user');
			localStorage.removeItem('workspace');
			localStorage.removeItem('lastMessages');
		} else {
			await SecureStore.deleteItemAsync('token');
			await SecureStore.deleteItemAsync('user');
			await SecureStore.deleteItemAsync('workspace');
			await SecureStore.deleteItemAsync('lastMessages');
		}
		dispatch(updateToken(null));
		dispatch(updateUserData(null));
		dispatch(updateWorkspaceData(null));
	}

	return (
		<>
			<Stack.Navigator
				screenOptions={{
					headerShown: false,
				}}
				initialRouteName="App"
			>
				<Stack.Screen
					name="App"
					component={token ? HomeIndex : Login}
					options={{
						title: 'willkommen',
					}}
				/>
				<Stack.Screen
					name="addTodo"
					component={AddTodo}
					options={{
						headerTitle: 'Todo erstellen',
						headerShown: true,
						headerLeft: () => (
							<TouchableOpacity
								onPress={() => navigation.goBack()}
								style={tw.style('pl-4 pr-6')}
							>
								<Image
									source={IconBack}
									style={tw.style('w-5 h-5')}
									resizeMode="contain"
								/>
							</TouchableOpacity>
						),
						headerTintColor: tw.color('accent'),
					}}
				/>

				<Stack.Screen
					name="addPinboard"
					component={AddPinboard}
					options={{
						title: 'Pinnwand',
						headerShown: true,
						headerLeft: () => (
							<TouchableOpacity
								onPress={() => navigation.goBack()}
								style={tw.style('pl-4 pr-6')}
							>
								<Image
									source={IconBack}
									style={tw.style('w-5 h-5')}
									resizeMode="contain"
								/>
							</TouchableOpacity>
						),
						headerTintColor: tw.color('accent'),
					}}
				/>

				<Stack.Screen
					name="addPost"
					component={AddPost}
					options={{
						title: 'Post erstellen',
						headerShown: true,
						headerLeft: () => (
							<TouchableOpacity
								onPress={() => navigation.goBack()}
								style={tw.style('pl-4 pr-6')}
							>
								<Image
									source={IconBack}
									style={tw.style('w-5 h-5')}
									resizeMode="contain"
								/>
							</TouchableOpacity>
						),
						headerTintColor: tw.color('accent'),
					}}
				/>

				<Stack.Screen
					name="userProfile"
					component={UserProfile}
					options={{
						title: 'Nutzerprofil',
						headerShown: true,
						headerLeft: () => (
							<TouchableOpacity
								onPress={() => navigation.goBack()}
								style={tw.style('pl-4 pr-6')}
							>
								<Image
									source={IconBack}
									style={tw.style('w-5 h-5')}
									resizeMode="contain"
								/>
							</TouchableOpacity>
						),
						headerTintColor: tw.color('accent'),
					}}
				/>

				<Stack.Screen
					name="settings"
					component={Settings}
					options={{
						title: 'Einstellungen',
						headerShown: true,
						headerLeft: () => (
							<TouchableOpacity
								onPress={() => navigation.goBack()}
								style={tw.style('pl-4 pr-6')}
							>
								<Image
									source={IconBack}
									style={tw.style('w-5 h-5')}
									resizeMode="contain"
								/>
							</TouchableOpacity>
						),
						headerTintColor: tw.color('accent'),
					}}
				/>

				<Stack.Screen
					name="Register"
					component={Register}
					options={{
						title: 'registrieren',
					}}
				/>
				<Stack.Screen
					name="CreateWorkspace"
					component={CreateWorkspace}
					options={{
						title: 'organisation erstellen',
					}}
				/>
				<Stack.Screen
					name="SetPassword"
					component={SetPassword}
					options={{
						title: 'password erstellen',
					}}
				/>

				<Stack.Screen
					name="ForgotPassword"
					component={ForgotPassword}
					options={{
						title: 'password zurücksetzen',
					}}
				/>

				<Stack.Screen
					name="ResetPassword"
					component={ResetPassword}
					options={{
						title: 'password zurücksetzen',
					}}
				/>

				<Stack.Screen
					name="activateUser"
					component={ActivateUser}
					options={{
						title: 'account aktivieren',
					}}
				/>

				<Stack.Screen
					name="notifications"
					component={ShowNotifications}
					options={{
						title: 'Benachrichtigungen',
						headerShown: true,
						headerLeft: () => (
							<TouchableOpacity
								onPress={() => navigation.goBack()}
								style={tw.style('pl-4 pr-6')}
							>
								<Image
									source={IconBack}
									style={tw.style('w-5 h-5')}
									resizeMode="contain"
								/>
							</TouchableOpacity>
						),
						headerTintColor: tw.color('accent'),
					}}
				/>
			</Stack.Navigator>
		</>
	);
}
