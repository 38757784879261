import {
	View,
	Text,
	ScrollView,
	TouchableOpacity,
	Image,
	TouchableWithoutFeedback,
	Platform,
	Alert,
	RefreshControl,
} from 'react-native';
import React from 'react';
import tw from '../../../lib/tailwind';
import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigation} from '@react-navigation/native';
import axios from 'axios';
import PinPost from '../../../components/pinPost';
import {BottomSheetModal} from '@gorhom/bottom-sheet';
import {addHeNotification} from '../../../lib/HeNotifications/HeNotificationList';
import {decrypt} from '../../../lib/eTe';
import {KeyboardAwareFlatList} from 'react-native-keyboard-aware-scroll-view';
import IconBack from '../../../assets/icons/arrow-left.png';
import alert from '../../../lib/alert';

export default function PinboardDetail({route}) {
	const [allPosts, setAllPosts] = React.useState([]);

	const userInfo = useSelector((state) => state.appData.userData);

	const token = useSelector((state) => state.appData.token);

	const tabBarHeight = useSelector((state) => state.appData.tabBarHeight);

	const dispatch = useDispatch();
	const navigation = useNavigation();

	let pinboardDetail = JSON.parse(route.params.pinboardDetail);

	const actionSheetRef = React.useRef(null);
	const scrollRef = React.useRef(null);

	const [selectedPost, setSelectedPost] = React.useState(null);

	const [refreshing, setRefreshing] = React.useState(false);

	useEffect(() => {
		navigation.setOptions({
			headerLeft: () => (
				<TouchableOpacity
					onPress={() => {
						const routes = navigation.getState().routes;
						const todoRoute = routes.find((r) => r.name === 'Boards');

						if (todoRoute) {
							navigation.navigate('Boards', {
								params: {
									merge: true,
								},
							});
						} else {
							navigation.replace('Boards', {
								merge: true,
							});
						}
					}}
					style={tw.style('pl-4 pr-6')}
				>
					<Image
						source={IconBack}
						style={tw.style('w-5 h-5')}
						resizeMode="contain"
					/>
				</TouchableOpacity>
			),
		});
	}, []);

	async function getAllPosts() {
		try {
			let res = await axios.get(
				process.env.API_URL +
					'/' +
					userInfo.workspace +
					'/pinboard/' +
					pinboardDetail._id +
					'/posts',
				{
					headers: {
						Authorization: 'Bearer ' + token,
					},
				}
			);

			setAllPosts(res.data.pinboardPosts);
		} catch (error) {
			console.log(error.response);
		}

		setRefreshing(false);
	}

	async function addCommentToPost(postID, comment) {
		try {
			let res = await axios.post(
				process.env.API_URL +
					'/' +
					userInfo.workspace +
					'/pinboard/' +
					pinboardDetail._id +
					'/posts/' +
					postID +
					'/comments',
				{comment},
				{
					headers: {
						Authorization: 'Bearer ' + token,
					},
				}
			);

			setAllPosts(res.data.pinboardPosts);
		} catch (error) {
			console.log(error.response.data);
		}
	}

	async function addLikeToPost(postID) {
		try {
			let res = await axios.put(
				process.env.API_URL +
					'/' +
					userInfo.workspace +
					'/pinboard/' +
					pinboardDetail._id +
					'/post/' +
					postID +
					'/likes',
				{},
				{
					headers: {
						Authorization: 'Bearer ' + token,
					},
				}
			);

			setAllPosts(res.data.pinboardPosts);
		} catch (error) {
			console.log(error.response.data);
		}
	}

	async function deletePost() {
		try {
			let response = await axios.delete(
				process.env.API_URL +
					'/' +
					userInfo.workspace +
					'/pinboard/' +
					pinboardDetail._id +
					'/posts/' +
					selectedPost._id,
				{
					headers: {
						Authorization: 'Bearer ' + token,
					},
				}
			);

			setAllPosts(response.data.pinboardPosts);

			addHeNotification({
				title: 'Pinnwände',
				message: response.data.message,
				type: 'success',
				duration: 5000,
			});
		} catch (error) {
			if (typeof error.response !== 'undefined') {
				addHeNotification({
					title: 'Pinnwände',
					message: error.response.data.message,
					type: 'error',
					duration: 5000,
				});
				console.log(error.response.data);
			} else {
				addHeNotification({
					title: 'Pinnwände',
					message: 'Verbindung zum Server konnte nicht hergestellt werden',
					type: 'error',
					duration: 5000,
				});
				console.log(error.response);
			}
		}
	}

	function handleBottomSheetModalPresent() {
		actionSheetRef.current?.present();
	}

	function handleBottomSheetModalDismiss() {
		actionSheetRef.current?.close();
	}

	useEffect(() => {
		const unsubscribe = navigation.addListener('focus', () => {
			getAllPosts();
		});

		navigation.setOptions({
			title: pinboardDetail.title
				? decrypt(pinboardDetail.title, userInfo.workspace)
				: 'Pinnwand',
		});
		getAllPosts();

		return unsubscribe;
	}, []);

	return (
		<TouchableWithoutFeedback onPress={() => {}} style={tw.style('flex-1')}>
			<>
				{allPosts.length > 0 ? (
					<KeyboardAwareFlatList
						data={[{_id: 'spacer'}, ...allPosts].reverse()}
						keyExtractor={(item) => item._id}
						renderItem={({item}) => {
							if (item._id === 'spacer') {
								return <View style={tw.style('h-24')} />;
							}
							return (
								<TouchableWithoutFeedback onPress={() => {}}>
									<PinPost
										key={item._id}
										{...item}
										pinboardID={pinboardDetail._id}
										onShowOptions={() => {
											setSelectedPost(item);
											handleBottomSheetModalPresent();
										}}
										onLiked={addLikeToPost}
										onCommented={addCommentToPost}
									/>
								</TouchableWithoutFeedback>
							);
						}}
						keyboardShouldPersistTaps="handled"
						style={tw.style('pb-24 mx-auto max-w-[900px] w-full')}
						refreshControl={
							<RefreshControl
								refreshing={refreshing}
								onRefresh={() => {
									setRefreshing(true);

									getAllPosts();
								}}
								colors={[tw.color('primary')]}
							/>
						}
					/>
				) : (
					<Text
						style={tw.style('text-gray-400 text-lg font-semibold py-6 px-8')}
					>
						Keine Pinnwandeinträge vorhanden
					</Text>
				)}

				<TouchableOpacity
					style={tw.style(
						'absolute right-6 bottom-6 bg-accent p-4 rounded-full'
					)}
					onPress={() =>
						navigation.navigate('addPost', {pinboardID: pinboardDetail._id})
					}
				>
					<Image
						source={require('../../../assets/icons/close.png')}
						style={[tw.style('h-6 w-6'), {tintColor: tw.color('primary')}]}
					/>
				</TouchableOpacity>
				<BottomSheetModal
					ref={actionSheetRef}
					index={0}
					snapPoints={['25%']}
					style={tw.style('shadow-lg')}
					backdropComponent={() => {
						return (
							<TouchableOpacity
								style={tw.style(
									'flex-1 absolute top-0 left-0 right-0 bottom-0'
								)}
								onPress={() => {
									actionSheetRef.current?.close();
								}}
							/>
						);
					}}
					enableContentPanningGesture={Platform.OS != 'android'}
					enableHandlePanningGesture={Platform.OS != 'web'}
				>
					<View style={tw.style('w-full max-w-[900px]	mx-auto')}>
						<TouchableOpacity
							onPress={() => {
								handleBottomSheetModalDismiss();
								navigation.navigate('addPost', {
									post: selectedPost,
									pinboardID: pinboardDetail._id,
								});
							}}
							style={tw.style('p-8 border-b border-gray-200 text-base')}
						>
							<Text style={tw.style('text-base')}>Post bearbeiten</Text>
						</TouchableOpacity>
						<TouchableOpacity
							onPress={() => {
								alert(
									'Post löschen',
									'Bist du dir sicher, das du diesen Post unwiederuflich löschen möchtest?',
									[
										{
											text: 'Abbrechen',
											style: 'cancel',
										},
										{
											text: 'Löschen',
											style: 'destructive',
											onPress: () => {
												handleBottomSheetModalDismiss();
												deletePost();
											},
										},
									],
									{cancelable: false}
								);
							}}
							style={tw.style('p-8 text-base mb-6')}
						>
							<Text style={tw.style('text-base')}>Post löschen</Text>
						</TouchableOpacity>
					</View>
				</BottomSheetModal>
			</>
		</TouchableWithoutFeedback>
	);
}
