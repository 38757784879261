import {
	View,
	Text,
	TouchableOpacity,
	Image,
	TouchableWithoutFeedback,
	Keyboard,
	ScrollView,
	Alert,
	RefreshControl,
} from 'react-native';
import React from 'react';
import tw from '../../../lib/tailwind';
import {useNavigation} from '@react-navigation/native';
import {BottomSheetModal} from '@gorhom/bottom-sheet';
import {useDispatch, useSelector} from 'react-redux';
import CheckIcon from '../../../assets/icons/todo-active.png';
import socket from '../../../lib/socket';
import {
	updateAllUsers,
	updatePinboards,
	updateUserData,
} from '../../../redux/slices/appDataSlice';
import axios from 'axios';
import {addHeNotification} from '../../../lib/HeNotifications/HeNotificationList';
import {Swipeable} from 'react-native-gesture-handler';
import {useEffect} from 'react';
import {decrypt} from '../../../lib/eTe';
import {Platform} from 'react-native';
import alert from '../../../lib/alert';

export default function Pinboard() {
	const navigation = useNavigation();

	const pinboards = useSelector((state) => state.appData.pinboards);

	const userInfo = useSelector((state) => state.appData.userData);

	const token = useSelector((state) => state.appData.token);

	const dispatch = useDispatch();

	const bottomSheetModalRef = React.useRef(null);

	function handleBottomSheetModalPresent() {
		bottomSheetModalRef.current?.present();
	}

	function handleBottomSheetModalDismiss() {
		bottomSheetModalRef.current?.close();
	}

	const [selectedPinboard, setSelectedPinboard] = React.useState(null);

	const [refreshing, setRefreshing] = React.useState(false);

	useEffect(() => {
		socket.emit('getUsers', {
			token,
			workspace: userInfo.workspace,
			userID: userInfo._id,
		});

		socket.on('allUser', (users) => {
			dispatch(updateAllUsers(users));

			let me = users.find((user) => user._id == userInfo._id);

			dispatch(
				updateUserData({
					...userInfo,
					role: me.role,
				})
			);
		});

		navigation.addListener('focus', () => {
			socket.emit('getUsers', {
				token,
				workspace: userInfo.workspace,
				userID: userInfo._id,
			});
		});

		getPinboards();

		return () => {
			socket.off('allUser');
			navigation.removeListener('focus');
		};
	}, []);

	useEffect(() => {
		const unsubscribe = navigation.addListener('focus', () => {
			getPinboards();
		});

		return unsubscribe;
	}, []);

	async function getPinboards() {
		try {
			let res = await axios.get(
				process.env.API_URL + '/' + userInfo.workspace + '/pinboard',
				{
					headers: {
						Authorization: 'Bearer ' + token,
					},
				}
			);

			dispatch(updatePinboards(res.data.pinboards));
			setRefreshing(false);
		} catch (error) {
			console.log(error);
		}
	}

	async function deletePinboard(id) {
		try {
			let response = await axios.delete(
				process.env.API_URL + '/' + userInfo.workspace + '/pinboard/' + id,
				{
					headers: {
						Authorization: 'Bearer ' + token,
					},
				}
			);

			console.log(response.data);

			dispatch(updatePinboards(response.data.pinboards));

			addHeNotification({
				title: 'Pinnwände',
				message: response.data.message,
				type: 'success',
				duration: 5000,
			});
		} catch (error) {
			if (typeof error.response !== 'undefined') {
				addHeNotification({
					title: 'Pinnwände',
					message: error.response.data.message,
					type: 'error',
					duration: 5000,
				});
				console.log(error.response.data);
			} else {
				addHeNotification({
					title: 'Pinnwände',
					message: 'Verbindung zum Server konnte nicht hergestellt werden',
					type: 'error',
					duration: 5000,
				});
				console.log(error.response);
			}
		}
	}

	return (
		<TouchableWithoutFeedback onPress={() => {}}>
			<>
				<ScrollView
					style={tw.style('flex-1 bg-white')}
					refreshControl={
						<RefreshControl
							refreshing={refreshing}
							onRefresh={() => {
								setRefreshing(true);

								getPinboards();
							}}
							colors={[tw.color('primary')]}
						/>
					}
					contentContainerStyle={tw.style('w-full max-w-[900px] mx-auto')}
				>
					<Text
						style={tw.style('text-primary text-4xl font-semibold py-6 px-8')}
					>
						Pinnwände
					</Text>
					<View style={tw.style('flex-1 pb-20')}>
						{pinboards.length > 0 ? (
							<View style={tw.style('flex-1 flex-row flex-wrap')}>
								{pinboards.map((pinboard, index) => (
									<TouchableOpacity
										style={tw.style(
											'px-6 w-1/2 md:w-auto flex items-center justify-center'
										)}
										key={pinboard._id}
										onPress={() => {
											navigation.navigate('BoardDetail', {
												pinboardDetail: JSON.stringify(pinboard),
											});
										}}
									>
										<Image
											source={{
												uri:
													pinboard.headerImage == ''
														? null
														: process.env.API_URL +
														  '/' +
														  pinboard.headerImage +
														  '?width=600&height=600&token=' +
														  token,
											}}
											style={tw.style(
												'w-full md:w-40 bg-gray-300 h-40 rounded-3xl'
											)}
										/>
										<View
											style={tw.style(
												'w-full md:w-40 py-4 flex flex-row justify-between items-center'
											)}
										>
											<Text style={tw.style('text-base')}>
												{decrypt(pinboard.title, userInfo.workspace)}
											</Text>

											{userInfo.role == 'admin' &&
												userInfo._id == pinboard.createdBy && (
													<TouchableOpacity
														onPress={() => {
															handleBottomSheetModalPresent();
															setSelectedPinboard(pinboard);
														}}
														style={tw.style(' px-4 pt-2 pb-0')}
													>
														<Text style={tw.style('text-base font-bold')}>
															...
														</Text>
													</TouchableOpacity>
												)}
										</View>
									</TouchableOpacity>
								))}
							</View>
						) : (
							<Text
								style={tw.style(
									'text-gray-400 text-lg font-semibold py-6 px-8'
								)}
							>
								Keine Pinnwände vorhanden
							</Text>
						)}
					</View>
				</ScrollView>
				{userInfo.role == 'admin' && (
					<TouchableOpacity
						style={tw.style(
							'absolute right-6 bottom-6 bg-accent p-4 rounded-full'
						)}
						onPress={() => navigation.navigate('addPinboard')}
					>
						<Image
							source={require('../../../assets/icons/close.png')}
							style={[tw.style('h-6 w-6'), {tintColor: tw.color('primary')}]}
						/>
					</TouchableOpacity>
				)}
				<BottomSheetModal
					ref={bottomSheetModalRef}
					index={0}
					snapPoints={['25%']}
					style={tw.style('shadow-lg')}
					backdropComponent={() => {
						return (
							<TouchableOpacity
								style={tw.style(
									'flex-1 absolute top-0 left-0 right-0 bottom-0'
								)}
								onPress={() => {
									bottomSheetModalRef.current?.close();
								}}
							/>
						);
					}}
					enableContentPanningGesture={Platform.OS != 'android'}
					enableHandlePanningGesture={Platform.OS != 'web'}
				>
					<View style={tw.style('w-full max-w-[900px]	mx-auto')}>
						<TouchableOpacity
							onPress={() => {
								handleBottomSheetModalDismiss();
								navigation.navigate('addPinboard', {
									pinboard: selectedPinboard,
								});
							}}
							style={tw.style('p-8 border-b border-gray-200 text-base')}
						>
							<Text style={tw.style('text-base')}>Pinnwand bearbeiten</Text>
						</TouchableOpacity>
						<TouchableOpacity
							onPress={() => {
								alert(
									'Pinnwand löschen',
									'Soll die Pinnwand wirklich gelöscht werden?',
									[
										{
											text: 'Abbrechen',
											onPress: () => {},
											style: 'cancel',
										},
										{
											style: 'destructive',
											text: 'Löschen',
											onPress: () => {
												handleBottomSheetModalDismiss();
												deletePinboard(selectedPinboard._id);
											},
										},
									]
								);
							}}
							style={tw.style('p-8 text-base')}
						>
							<Text style={tw.style('text-base')}>Pinnwand löschen</Text>
						</TouchableOpacity>
					</View>
				</BottomSheetModal>
			</>
		</TouchableWithoutFeedback>
	);
}
