import {
	View,
	Text,
	TouchableOpacity,
	TextInput,
	TouchableWithoutFeedback,
	Keyboard,
	ImageBackground,
	Image,
	Platform,
	ScrollView,
} from 'react-native';
import React from 'react';
import tw from '../../lib/tailwind';

import RegisterBGImage from '../../assets/images/auth/xd_vorlagen_register.png';
import ArrowRight from '../../assets/icons/arrow-right.png';
import {useNavigation} from '@react-navigation/native';
import {addHeNotification} from '../../lib/HeNotifications/HeNotificationList';
import {useSelector} from 'react-redux';
import {KeyboardAwareScrollView} from 'react-native-keyboard-aware-scroll-view';

export default function Register(props) {
	const navigation = useNavigation();

	console.log(props);
	const [username, setUsername] = React.useState(
		props.route?.params?.username || ''
	);
	const [usernameError, setUsernameError] = React.useState(false);
	const [accessCode, setAccessCode] = React.useState(
		props.route?.params?.code || ''
	);
	const [accessCodeError, setAccessCodeError] = React.useState(false);

	async function register() {
		if (Platform.OS != 'web') {
			Keyboard.dismiss();
		}

		let tempUsernameError =
			username.trim().includes(' ') || username.trim('').length == 0;
		let tempAccessCodeError = accessCode.trim() === '';

		setUsernameError(tempUsernameError);

		setAccessCodeError(tempAccessCodeError);

		if (tempUsernameError || tempAccessCodeError) {
			addHeNotification({
				title: 'Registrieren',
				message: 'Bitte überprüfe deine Eingaben.',
				type: 'error',
				duration: 5000,
			});
			return;
		}

		navigation.navigate('SetPassword', {
			accessCode: accessCode.trim(),
			username: username.trim(),
			mode: 'user',
		});
	}

	React.useEffect(() => {
		updateAccessCode();
	}, [props.route?.params]);

	function updateAccessCode() {
		let code = props.route?.params?.code || '';

		if (code == '' || (accessCode != '' && code == accessCode)) {
			return;
		}

		setAccessCode(code);
		setAccessCodeError(false);
	}

	function navigateGoBack() {
		if (Platform.OS != 'web') {
			Keyboard.dismiss();
		}
		navigation.navigate('App');
	}

	function navigateCreateWorkspace() {
		if (Platform.OS != 'web') {
			Keyboard.dismiss();
		}
		navigation.navigate('CreateWorkspace');
	}

	return (
		<ImageBackground
			source={RegisterBGImage}
			resizeMode={'contain'}
			style={tw.style('flex-1 items-center')}
		>
			<TouchableWithoutFeedback
				onPress={() => {
					if (Platform.OS != 'web') {
						Keyboard.dismiss();
					}
				}}
			>
				<View
					style={tw.style(
						'flex-1' +
							(Platform.OS == 'web'
								? ' max-w-[500px] w-2/3 min-w-[350px]'
								: ' w-full')
					)}
				>
					<View style={tw.style('w-full h-5/11')}></View>
					<KeyboardAwareScrollView
						contentContainerStyle={tw.style('justify-start items-center px-12')}
						enableOnAndroid={true}
						enableAutomaticScroll={true}
						scrollEnabled={true}
						keyboardShouldPersistTaps="handled"
					>
						<Text
							style={tw.style('w-full mb-10 text-4xl text-accent font-medium')}
						>
							registrieren
						</Text>

						<View style={tw.style('w-full')}>
							<View
								style={tw.style(
									'w-full border-b border-opacity-35 pb-3' +
										(usernameError ? ' border-red-500' : ' border-gray-400')
								)}
							>
								<TextInput
									keyboardType="default"
									placeholder="benutzername"
									style={tw.style('flex-1 leading-tight text-lg text-gray-700')}
									onChangeText={(text) => {
										setUsername(text);
										setUsernameError(false);
									}}
									value={username}
									placeholderTextColor={tw.color(
										usernameError ? 'red-300' : 'gray-300'
									)}
									textContentType="username"
									spellCheck={false}
									autoCapitalize="none"
									clearButtonMode="while-editing"
								/>
							</View>
							<View
								style={tw.style(
									'w-full border-b border-opacity-35 pb-3 pt-6' +
										(accessCodeError ? ' border-red-500' : ' border-gray-400')
								)}
							>
								<TextInput
									keyboardType="default"
									placeholder="freischaltcode"
									style={tw.style('text-lg leading-tight text-gray-700')}
									onChangeText={(text) => {
										setAccessCode(text);
										setAccessCodeError(false);
									}}
									placeholderTextColor={tw.color(
										accessCodeError ? 'red-300' : 'gray-300'
									)}
									value={accessCode}
									clearButtonMode="while-editing"
									spellCheck={false}
									autoCorrect={false}
									autoCapitalize="characters"
								/>
							</View>
							<Text style={tw.style('mt-4 font-thin')}>
								du hast noch keinen freischlatungscode?
							</Text>
							<Text style={tw.style('font-thin')}>
								bitte bei deinem administrator nachfragen, wie du einen
								erhältst.
							</Text>
						</View>
						<View style={tw.style('w-full')}>
							<TouchableOpacity
								activeOpacity={0.8}
								style={tw.style(
									'w-full mt-14 rounded-full py-3 bg-accent self-start'
								)}
								onPress={() => register()}
							>
								<Text
									style={tw.style('text-primary text-center text-xl font-bold')}
								>
									registrieren
								</Text>
							</TouchableOpacity>

							<TouchableOpacity
								activeOpacity={0.8}
								style={tw.style(
									'w-full mt-2 rounded-full py-3 bg-primary self-start'
								)}
								onPress={() => navigateGoBack()}
							>
								<Text
									style={tw.style('text-accent text-center text-xl font-bold')}
								>
									zurück
								</Text>
							</TouchableOpacity>
							<TouchableOpacity
								activeOpacity={0.8}
								style={tw.style(
									'w-full mt-4 self-start flex flex-row justify-center items-center'
								)}
								onPress={() => navigateCreateWorkspace()}
							>
								<Text
									style={tw.style(
										'text-accent text-center text-xl font-light mr-3'
									)}
								>
									organisation erstellen
								</Text>
								<Image
									source={ArrowRight}
									style={tw.style('w-4 h-4')}
									resizeMode="contain"
								/>
							</TouchableOpacity>
						</View>
					</KeyboardAwareScrollView>
				</View>
			</TouchableWithoutFeedback>
		</ImageBackground>
	);
}
