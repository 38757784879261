import {
	View,
	Text,
	Keyboard,
	TouchableWithoutFeedback,
	Image,
	TouchableOpacity,
	Alert,
} from 'react-native';
import React, {useEffect} from 'react';
import tw from '../../../lib/tailwind';

import dateFormat from 'dateformat';
import {useDispatch, useSelector} from 'react-redux';
import User from '../../../assets/icons/user.png';
import {decrypt} from '../../../lib/eTe';
import {addHeNotification} from '../../../lib/HeNotifications/HeNotificationList';
import {updateAllUsers, updateTodos} from '../../../redux/slices/appDataSlice';
import axios from 'axios';
import socket from '../../../lib/socket';
import {useNavigation} from '@react-navigation/native';
import alert from '../../../lib/alert';

export default function TodoDetail({route}) {
	const {todo} = route.params;

	const navigation = useNavigation();

	const {title, description, date, users, status} = todo;

	const allUsers = useSelector((state) => state.appData.allUsers);

	const userInfo = useSelector((state) => state.appData.userData);
	const token = useSelector((state) => state.appData.token);

	const dispatch = useDispatch();

	useEffect(() => {
		socket.emit('getUsers', {
			token,
			workspace: userInfo.workspace,
			userID: userInfo._id,
		});

		socket.on('allUser', (users) => {
			dispatch(updateAllUsers(users));
		});

		return () => {
			socket.off('allUser');
		};
	}, []);

	async function deleteTodo(id) {
		try {
			let response = await axios.delete(
				process.env.API_URL + '/' + userInfo.workspace + '/todo/' + id,
				{
					headers: {
						Authorization: 'Bearer ' + token,
					},
				}
			);

			dispatch(updateTodos(response.data.todos));

			addHeNotification({
				title: 'Aufgaben',
				message: response.data.message,
				type: 'success',
				duration: 5000,
			});
			navigation.goBack();
		} catch (error) {
			if (typeof error.response !== 'undefined') {
				addHeNotification({
					title: 'Aufgaben',
					message: error.response.data.message,
					type: 'error',
					duration: 5000,
				});
				console.log(error.response.data);
			} else {
				addHeNotification({
					title: 'Aufgaben',
					message: 'Verbindung zum Server konnte nicht hergestellt werden',
					type: 'error',
					duration: 5000,
				});
				console.log(error);
			}
		}
	}

	async function updateTodoStatus(todo) {
		try {
			let response = await axios.put(
				process.env.API_URL + '/' + userInfo.workspace + '/todo/' + todo._id,
				{
					...todo,
					status: todo.status == 'open' ? 'closed' : 'open',
				},
				{
					headers: {
						Authorization: 'Bearer ' + token,
					},
				}
			);

			dispatch(updateTodos(response.data.todos));

			addHeNotification({
				title: 'Aufgaben',
				message: response.data.message,
				type: 'success',
				duration: 5000,
			});
			navigation.goBack();
		} catch (error) {
			if (typeof error.response !== 'undefined') {
				addHeNotification({
					title: 'Aufgaben',
					message: error.response.data.message,
					type: 'error',
					duration: 5000,
				});
				console.log(error.response.data);
			} else {
				addHeNotification({
					title: 'Aufgaben',
					message: 'Verbindung zum Server konnte nicht hergestellt werden',
					type: 'error',
					duration: 5000,
				});
				console.log(error);
			}
		}
	}

	return (
		<TouchableWithoutFeedback onPress={() => {}}>
			<View style={tw.style('flex-1 bg-white p-8 justify-between')}>
				<View style={tw.style(' w-full max-w-[900px] mx-auto')}>
					<Text style={tw.style('text-primary text-4xl font-semibold')}>
						{decrypt(title, userInfo.workspace)} -{' '}
						{status == 'open' ? 'offen' : 'erledigt'}
					</Text>
					<Text style={tw.style('text-gray-400 text-base mb-6')}>
						{decrypt(description, userInfo.workspace)}
					</Text>

					{date && (
						<View style={tw.style('')}>
							<Text style={tw.style('text-gray-400 text-lg font-bold mr-2')}>
								Fällig am:
							</Text>
							<Text style={tw.style('text-gray-800 text-base')}>
								{dateFormat(date, 'dd.mm.yyyy')}
							</Text>
						</View>
					)}

					{users.length > 0 && (
						<View style={tw.style('mt-8 ')}>
							<Text style={tw.style('text-gray-400 text-lg font-bold mb-2')}>
								Zugewiesen an:
							</Text>
							{users.map((userID) => {
								const user = allUsers.find((u) => u._id == userID);

								if (!user) return null;

								return (
									<View
										key={user._id}
										style={tw.style(
											'flex flex-row items-center justify-between mt-2'
										)}
									>
										<View style={tw.style('flex flex-row items-center')}>
											<View style={tw.style('flex-row items-center mr-4')}>
												<Image
													source={
														user.extendedData?.avatar
															? {
																	uri:
																		process.env.API_URL +
																		'/' +
																		user.extendedData.avatar +
																		'?width=64&height=64&token=' +
																		token,
															  }
															: User
													}
													style={tw.style('w-7 h-7 bg-[#F2F2F2] rounded-full ')}
													resizeMode={
														user.extendedData?.avatar ? 'cover' : 'contain'
													}
												/>
											</View>
											<View>
												<Text style={tw.style('ml-2 text-lg')}>
													{user.extendedData?.firstName === ''
														? user.username
														: user.extendedData?.firstName +
														  ' ' +
														  user.extendedData?.lastName}
												</Text>
												<Text style={tw.style('ml-2 text-xs')}>
													{user.stillInvited
														? 'Einladung ausstehend'
														: user.role === 'admin'
														? 'Administrator'
														: 'Nutzer'}
												</Text>
											</View>
										</View>
									</View>
								);
							})}
						</View>
					)}
				</View>
				<View
					style={tw.style(
						'w-full flex flex-row justify-evenly max-w-[900px] mx-auto'
					)}
				>
					<TouchableOpacity
						style={tw.style('flex items-center justify-center')}
						onPress={() => {
							const routes = navigation.getState().routes;
							const todoRoute = routes.find((r) => r.name === 'Todo');

							if (todoRoute) {
								navigation.navigate('Todo', {
									params: {
										merge: true,
									},
								});
							} else {
								navigation.replace('Todo', {
									merge: true,
								});
							}
						}}
					>
						<Image
							source={require('../../../assets/icons/arrow-right.png')}
							style={[
								tw.style('mb-2 w-6 h-6 tint-accent'),
								{transform: [{rotate: '180deg'}]},
							]}
							resizeMode="contain"
						/>
						<Text>Zurück</Text>
					</TouchableOpacity>
					{todo.createdBy == userInfo._id && todo.status == 'closed' && (
						<TouchableOpacity
							style={tw.style('flex items-center justify-center')}
							onPress={() => {
								alert(
									'Löschen',
									'Soll diese Aufgabe wirklich gelöscht werden? Diese Aktion kann nicht rückgängig gemacht werden.',
									[
										{
											text: 'Abbrechen',
											style: 'cancel',
										},
										{
											text: 'Löschen',
											onPress: () => {
												deleteTodo(todo._id);
											},
											style: 'destructive',
										},
									]
								);
							}}
						>
							<Image
								source={require('../../../assets/icons/delete-default.png')}
								style={tw.style('mb-2 w-6 h-6 tint-accent')}
								resizeMode="contain"
							/>
							<Text>Löschen</Text>
						</TouchableOpacity>
					)}
					<TouchableOpacity
						style={tw.style('flex items-center justify-center')}
						onPress={() => {
							navigation.navigate('addTodo', {
								todo,
							});
						}}
					>
						<Image
							source={require('../../../assets/icons/settings.png')}
							style={tw.style('mb-2 w-6 h-6 tint-accent')}
							resizeMode="contain"
						/>
						<Text>Bearbeiten</Text>
					</TouchableOpacity>
					<TouchableOpacity
						style={tw.style('flex items-center justify-center')}
						onPress={() => {
							updateTodoStatus(todo);
						}}
					>
						<Image
							source={require('../../../assets/icons/todo-active.png')}
							style={tw.style('mb-2 w-6 h-6 tint-accent')}
							resizeMode="contain"
						/>
						<Text>
							{todo.status != 'closed' ? 'Abschließen' : 'Wiedereröffnen'}
						</Text>
					</TouchableOpacity>
				</View>
			</View>
		</TouchableWithoutFeedback>
	);
}
