import React, {useEffect} from 'react';

import {NavigationContainer} from '@react-navigation/native';
import {createNativeStackNavigator} from '@react-navigation/native-stack';
import {registerRootComponent} from 'expo';
import {StatusBar} from 'expo-status-bar';
import {KeyboardAvoidingView, Platform, Text, View} from 'react-native';
import {SafeAreaProvider} from 'react-native-safe-area-context';
import {useDeviceContext} from 'twrnc';
import tw from './lib/tailwind';
import HeNotificationList from './lib/HeNotifications/HeNotificationList';

import {Provider, useSelector} from 'react-redux';
import store from './redux/store';
import Navigator from './navigator';
import {GestureHandlerRootView} from 'react-native-gesture-handler';
import {BottomSheetModalProvider} from '@gorhom/bottom-sheet';

import {i18n} from 'dateformat';
import * as Linking from 'expo-linking';
import * as Notifications from 'expo-notifications';

export default function App() {
	useDeviceContext(tw);

	useEffect(() => {
		if (Platform.OS != 'web') {
			Notifications.setNotificationHandler({
				handleNotification: async () => ({
					shouldShowAlert: true,
					shouldPlaySound: false,
					shouldSetBadge: false,
				}),
			});
		}
	}, []);

	i18n.dayNames = [
		'Son',
		'Mon',
		'Die',
		'Mit',
		'Don',
		'Fre',
		'Sam',
		'Sonntag',
		'Montag',
		'Dienstag',
		'Mittwoch',
		'Donnerstag',
		'Freitag',
		'Samstag',
	];

	i18n.monthNames = [
		'Jan',
		'Feb',
		'Mär',
		'Apr',
		'Mai',
		'Jun',
		'Jul',
		'Aug',
		'Sep',
		'Okt',
		'Nov',
		'Dez',
		'Januar',
		'Februar',
		'März',
		'April',
		'Mai',
		'Juni',
		'Juli',
		'August',
		'September',
		'Oktober',
		'November',
		'Dezember',
	];
	const linking = {
		prefixes: ['pyng://', 'https://app.pyng-app.de'],
		config: {
			screens: {
				ResetPassword: 'ResetPassword',
				activateUser: 'activateUser',
				Register: 'Register',
			},
		},
	};

	return (
		<Provider store={store}>
			<GestureHandlerRootView style={tw.style('flex-1')}>
				<BottomSheetModalProvider>
					<SafeAreaProvider>
						<View style={tw.style('flex-1')}>
							<StatusBar style="auto" translucent />

							<HeNotificationList />

							<NavigationContainer
								linking={linking}
								fallback={<Text>Loading...</Text>}
							>
								<Navigator />
							</NavigationContainer>
						</View>
					</SafeAreaProvider>
				</BottomSheetModalProvider>
			</GestureHandlerRootView>
		</Provider>
	);
}

registerRootComponent(App);
