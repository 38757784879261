const {io} = require('socket.io-client');

const socket = io(process.env.API_URL, {
	reconnection: true,
	reconnectionDelay: 1000,
	reconnectionDelayMax: 5000,
	reconnectionAttempts: Infinity,
	transports: ['polling', 'websocket'],
});

module.exports = socket;
