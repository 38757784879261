import {
	View,
	Text,
	TouchableWithoutFeedback,
	Keyboard,
	TextInput,
	TouchableOpacity,
	Image,
	FlatList,
	Platform,
	KeyboardAvoidingView,
	ScrollView,
} from 'react-native';
import React, {useEffect} from 'react';
import tw from '../lib/tailwind';
import User from '../assets/icons/user.png';
import Calender from '../assets/icons/calender.png';
import CloseIcon from '../assets/icons/close.png';
import CheckIcon from '../assets/icons/todo-active.png';
import {useHeaderHeight} from '@react-navigation/elements';
import DateTimePickerModal from 'react-native-modal-datetime-picker';

import dateFormat from 'dateformat';
import {useNavigation} from '@react-navigation/native';
import {BottomSheetModal} from '@gorhom/bottom-sheet';
import {useDispatch, useSelector} from 'react-redux';

import DateTimePicker from '@react-native-community/datetimepicker';
import socket from '../lib/socket';
import {updateAllUsers, updateTodos} from '../redux/slices/appDataSlice';
import {addHeNotification} from '../lib/HeNotifications/HeNotificationList';
import axios from 'axios';
import {decrypt, encrypt} from '../lib/eTe';

export default function AddTodo({route}) {
	const navigation = useNavigation();
	const {todo} = route.params || {todo: null};

	const headerHeight = useHeaderHeight();

	const userInfo = useSelector((state) => state.appData.userData);
	const token = useSelector((state) => state.appData.token);

	const [title, setTitle] = React.useState(
		todo ? decrypt(todo.title, userInfo.workspace) : ''
	);
	const [description, setDescription] = React.useState(
		todo ? decrypt(todo.description, userInfo.workspace) : ''
	);

	const [date, setDate] = React.useState(
		todo && todo.date ? new Date(todo.date) : null
	);
	const [users, setUsers] = React.useState(todo ? todo.users : []);

	const [showDatePicker, setShowDatePicker] = React.useState(false);

	const [update, setUpdate] = React.useState(0);

	const dispatch = useDispatch();

	const sheetRef = React.useRef(null);
	const dateSheetRef = React.useRef(null);

	const dateInputWebRef = React.useRef(null);

	async function saveTodo() {
		if (title.trim() === '') {
			return;
		}
		const newTodo = {
			title: encrypt(title, userInfo.workspace),
			description: encrypt(description, userInfo.workspace),
			date,
			users,
		};

		try {
			const response = await axios.post(
				process.env.API_URL + '/' + userInfo.workspace + '/todo',
				newTodo,
				{
					headers: {
						Authorization: 'Bearer ' + token,
					},
				}
			);

			setTitle('');
			setDescription('');
			setDate(null);
			setUsers([]);

			dispatch(updateTodos(response.data.todos));

			addHeNotification({
				title: 'Aufgaben',
				message: response.data.message,
				type: 'success',
				duration: 5000,
			});
		} catch (error) {
			if (typeof error.response !== 'undefined') {
				addHeNotification({
					title: 'Login',
					message: error.response.data.message,
					type: 'error',
					duration: 5000,
				});
				console.log(error.response.data);
			} else {
				addHeNotification({
					title: 'Login',
					message: 'Verbindung zum Server konnte nicht hergestellt werden',
					type: 'error',
					duration: 5000,
				});
				console.log(error.response);
			}
		}
	}

	async function updateTodo() {
		if (title.trim() === '') {
			return;
		}

		try {
			const response = await axios.put(
				process.env.API_URL + '/' + userInfo.workspace + '/todo/' + todo._id,
				{
					...todo,
					title: encrypt(title, userInfo.workspace),
					description: encrypt(description, userInfo.workspace),
					date,
					users,
				},
				{
					headers: {
						Authorization: 'Bearer ' + token,
					},
				}
			);

			dispatch(updateTodos(response.data.todos));

			navigation.navigate('Todo', {
				merge: true,
			});

			addHeNotification({
				title: 'Aufgaben',
				message: response.data.message,
				type: 'success',
				duration: 5000,
			});
		} catch (error) {
			if (typeof error.response !== 'undefined') {
				addHeNotification({
					title: 'Login',
					message: error.response.data.message,
					type: 'error',
					duration: 5000,
				});
				console.log(error.response.data);
			} else {
				addHeNotification({
					title: 'Login',
					message: 'Verbindung zum Server konnte nicht hergestellt werden',
					type: 'error',
					duration: 5000,
				});
				console.log(error.response);
			}
		}
	}

	useEffect(() => {
		socket.emit('getUsers', {
			token,
			workspace: userInfo.workspace,
			userID: userInfo._id,
		});

		socket.on('allUser', (users) => {
			dispatch(updateAllUsers(users));
		});

		return () => {
			socket.off('allUser');
		};
	}, []);

	const allUsers = useSelector((state) => state.appData.allUsers);

	return (
		<KeyboardAvoidingView
			behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
			keyboardVerticalOffset={headerHeight}
			style={tw.style('flex-1 bg-white')}
		>
			<View style={tw.style('flex-1 bg-white w-full max-w-[900px] mx-auto')}>
				<TouchableWithoutFeedback
					onPress={() => {
						sheetRef.current?.close();
						dateSheetRef.current?.close();
						if (Platform.OS != 'web') {
							Keyboard.dismiss();
						}
					}}
				>
					<View style={tw.style('flex-1 bg-white p-8 flex justify-between')}>
						<View>
							<Text style={tw.style('text-accent text-2xl font-bold')}>
								{todo ? '' : 'neue'} Aufgabe {todo ? 'bearbeiten' : ''}
							</Text>
							<TextInput
								style={tw.style(
									'border-b leading-tight border-gray-300 text-base py-2 mt-4'
								)}
								placeholder={'Aufgabe'}
								onChangeText={setTitle}
								value={title}
							/>
							<View style={tw.style('my-4 flex flex-row flex-wrap')}>
								<TouchableOpacity
									style={tw.style('min-w-1/2 mb-2 flex flex-row items-center')}
									onPress={() => {
										dateSheetRef.current?.close();
										sheetRef.current?.present();
										if (Platform.OS != 'web') {
											Keyboard.dismiss();
										}
									}}
								>
									<View
										style={tw.style(
											'flex-row items-center border border-gray-300 rounded-full p-3 mr-2 ' +
												(users.length > 0
													? 'bg-[#F2F2F2] border-[#F2F2F2]'
													: 'bg-transparent border-gray-300')
										)}
									>
										<Image
											source={User}
											style={[
												tw.style('w-6 h-6'),
												{
													tintColor: tw.color(
														!users.length > 0 ? 'gray-300' : 'accent'
													),
												},
											]}
											resizeMode="contain"
										/>
									</View>
									<View>
										<Text
											style={tw.style(
												'text-sm' +
													(users.length > 0
														? ' text-gray-400'
														: ' text-gray-300')
											)}
										>
											zugewiesen an
										</Text>
										<Text
											style={tw.style(
												'text-base font-bold' +
													(users.length > 0 ? '' : ' text-gray-300')
											)}
										>
											{users.length > 0
												? `${users.length} Person(-en)`
												: 'nicht zugewiesen'}
										</Text>
									</View>
								</TouchableOpacity>

								{Platform.OS == 'web' ? (
									<TouchableOpacity
										style={tw.style(
											'min-w-1/2 flex flex-row items-center mb-2'
										)}
										onPress={() => {
											if (!date) {
												setDate(new Date());
											}

											sheetRef.current?.close();

											dateInputWebRef.current?.showPicker();
										}}
									>
										<View
											style={tw.style(
												'flex-row items-center border border-gray-300 rounded-full p-3 mr-2 ' +
													(date
														? 'bg-[#F2F2F2] border-[#F2F2F2]'
														: 'bg-transparent border-gray-300')
											)}
										>
											<Image
												source={Calender}
												style={[
													tw.style('w-6 h-6'),
													{tintColor: tw.color(!date ? 'gray-300' : 'accent')},
												]}
												resizeMode="contain"
											/>
										</View>

										<View>
											<Text
												style={tw.style(
													'text-sm' +
														(date ? ' text-gray-400' : ' text-gray-300')
												)}
											>
												Fälligkeitsdatum
											</Text>
											<Text
												style={tw.style(
													'text-base font-bold' + (date ? '' : ' text-gray-300')
												)}
											>
												{date
													? dateFormat(date, 'dd.mm.yyyy')
													: 'nicht gesetzt'}
											</Text>
											<input
												type="date"
												ref={dateInputWebRef}
												style={tw.style('hidden')}
												value={date ? dateFormat(date, 'yyyy-mm-dd') : ''}
												onChange={(event) => {
													if (
														event.target.value != '' &&
														event.target.value != null
													) {
														console.log(event.target.value);
														setDate(new Date(event.target.value));
													}
												}}
											/>
										</View>
									</TouchableOpacity>
								) : (
									<TouchableOpacity
										style={tw.style(
											'min-w-1/2 flex flex-row items-center mb-2'
										)}
										onPress={() => {
											if (!date) {
												setDate(new Date());
											}

											sheetRef.current?.close();

											if (Platform.OS != 'web') {
												setShowDatePicker(true);
												Keyboard.dismiss();
											}
										}}
									>
										<View
											style={tw.style(
												'flex-row items-center border border-gray-300 rounded-full p-3 mr-2 ' +
													(date
														? 'bg-[#F2F2F2] border-[#F2F2F2]'
														: 'bg-transparent border-gray-300')
											)}
										>
											<Image
												source={Calender}
												style={[
													tw.style('w-6 h-6'),
													{tintColor: tw.color(!date ? 'gray-300' : 'accent')},
												]}
												resizeMode="contain"
											/>
										</View>

										<View>
											<Text
												style={tw.style(
													'text-sm' +
														(date ? ' text-gray-400' : ' text-gray-300')
												)}
											>
												Fälligkeitsdatum
											</Text>
											<Text
												style={tw.style(
													'text-base font-bold' + (date ? '' : ' text-gray-300')
												)}
											>
												{date
													? dateFormat(date, 'dd.mm.yyyy')
													: 'nicht gesetzt'}
											</Text>
										</View>
									</TouchableOpacity>
								)}
							</View>
							<TextInput
								multiline
								style={tw.style(
									'text-base leading-tight py-3 border-b border-gray-300'
								)}
								placeholder={'Beschreibung'}
								onChangeText={setDescription}
								value={description}
								numberOfLines={4}
							/>
						</View>

						<View style={tw.style('flex flex-row justify-end mt-4')}>
							<TouchableOpacity
								style={tw.style('bg-accent p-4 px-6 rounded-full')}
								onPress={() => {
									if (todo) {
										updateTodo();
									} else {
										saveTodo();
									}
								}}
							>
								<Text style={tw.style('font-semibold text-base text-white')}>
									{todo ? 'Speichern' : 'Erstellen'}
								</Text>
							</TouchableOpacity>
						</View>
						{/* <BottomSheetModal
							ref={dateSheetRef}
							index={0}
							snapPoints={['40%']}
							style={tw.style('shadow-lg')}
							$modal
							animateOnMount={false}
							detached
							enableDismissOnClose={true}
							backdropComponent={() => {
								return (
									<TouchableOpacity
										style={tw.style(
											'flex-1 absolute top-0 left-0 right-0 bottom-0'
										)}
										onPress={() => {
											dateSheetRef.current?.close();
										}}
									/>
								);
							}}
							enableContentPanningGesture={Platform.OS != 'android'}
							stackBehavior="replace"
						>
							<View style={tw.style('flex items-center justify-center flex-1')}>
								<input
									type="date"
									value={date ? dateFormat(date, 'yyyy-mm-dd') : ''}
									onChange={(event) => {
										if (
											event.target.value != '' &&
											event.target.value != null
										) {
											console.log(event.target.value);
											setDate(new Date(event.target.value));
										}
									}}
									style={tw.style(
										'py-2 px-4 border-b-2 border-gray-500 text-lg'
									)}
								/>
							</View>
						</BottomSheetModal> */}
						<DateTimePickerModal
							isVisible={showDatePicker && Platform.OS != 'web'}
							onConfirm={(date) => {
								setDate(date);
								setShowDatePicker(false);
							}}
							onCancel={() => {
								setShowDatePicker(false);
							}}
							isDarkModeEnabled={false}
							textColor={tw.color('black')}
							confirmTextIOS="Bestätigen"
							cancelTextIOS="Abbrechen"
							date={date || new Date()}
							themeVariant="light"
							locale="de-DE"
						/>
						<BottomSheetModal
							ref={sheetRef}
							index={0}
							snapPoints={['50%']}
							style={tw.style('shadow-lg')}
							$modal
							animateOnMount={false}
							detached
							enableDismissOnClose={true}
							stackBehavior="replace"
							backdropComponent={() => {
								return (
									<TouchableOpacity
										style={tw.style(
											'flex-1 absolute top-0 left-0 right-0 bottom-0'
										)}
										onPress={() => {
											sheetRef.current?.close();
										}}
									/>
								);
							}}
							enableContentPanningGesture={Platform.OS != 'android'}
							enableHandlePanningGesture={Platform.OS != 'web'}
						>
							<View
								style={tw.style(
									'flex-1 bg-white p-8 w-full max-w-[900px] mx-auto'
								)}
							>
								<Text style={tw.style('text-primary text-4xl font-semibold')}>
									Nutzer zur Aufgabe zuweisen
								</Text>
								<Text style={tw.style('text-gray-400 text-base mb-6')}>
									Wähle einen oder mehrere Nutzer aus, die die Aufgabe
									bearbeiten
								</Text>
								<ScrollView
									style={tw.style('flex-1')}
									contentContainerStyle={tw.style('flex-grow')}
								>
									{allUsers &&
										allUsers.map((user) => (
											<TouchableOpacity
												onPress={() => {
													// test if user is already in array and remove it

													let tempUser = [...users];
													if (tempUser.includes(user._id)) {
														const index = tempUser.indexOf(user._id);
														if (index > -1) {
															tempUser.splice(index, 1);
														}
													} else {
														tempUser.push(user._id);
													}

													setUsers(tempUser);

													setUpdate(update + 1);
												}}
												style={tw.style('flex py-1')}
												key={user._id}
											>
												<View
													key={user._id}
													style={tw.style(
														'flex flex-row items-center justify-between mt-2'
													)}
												>
													<View style={tw.style('flex flex-row items-center')}>
														<View
															style={tw.style('flex-row items-center mr-4')}
														>
															<Image
																source={
																	user.extendedData?.avatar
																		? {
																				uri:
																					process.env.API_URL +
																					'/' +
																					user.extendedData.avatar +
																					'?width=64&height=64&token=' +
																					token,
																		  }
																		: User
																}
																style={tw.style(
																	'w-10 h-10 bg-[#F2F2F2] rounded-full '
																)}
																resizeMode={
																	user.extendedData?.avatar
																		? 'cover'
																		: 'contain'
																}
															/>
														</View>
														<View>
															<Text style={tw.style('ml-2 text-lg')}>
																{user.extendedData.firstName === ''
																	? user.username
																	: user.extendedData.firstName +
																	  ' ' +
																	  user.extendedData.lastName}
															</Text>
														</View>
													</View>
													{users.includes(user._id) ? (
														<Image
															source={CheckIcon}
															style={tw.style('w-5 h-5')}
															resizeMode="contain"
														/>
													) : (
														<View style={tw.style('w-5 h-5')} />
													)}
												</View>
											</TouchableOpacity>
										))}
								</ScrollView>
							</View>
						</BottomSheetModal>
					</View>
				</TouchableWithoutFeedback>
			</View>
		</KeyboardAvoidingView>
	);
}
