import {View, Text, ScrollView, TouchableOpacity, Platform} from 'react-native';
import React from 'react';
import tw from '../lib/tailwind';
import {useDispatch, useSelector} from 'react-redux';
import Checkbox from 'expo-checkbox';
import {useEffect} from 'react';

import axios from 'axios';
import {updateUserData} from '../redux/slices/appDataSlice';
import {addHeNotification} from '../lib/HeNotifications/HeNotificationList';
import {SafeAreaView} from 'react-native-safe-area-context';

import * as SecureStore from 'expo-secure-store';

import * as WebBrowser from 'expo-web-browser';

export default function Settings() {
	const dispatch = useDispatch();

	const userInfo = useSelector((state) => state.appData.userData);
	const token = useSelector((state) => state.appData.token);

	const hasValidMail =
		userInfo.username.match(/@/) ||
		userInfo.extendedData.additionalEmail?.match(/@/);

	const [pushGroup, setPushGroup] = React.useState(
		userInfo.settings?.pushGroup || false
	);
	const [pushDirect, setPushDirect] = React.useState(
		userInfo.settings?.pushDirect || false
	);
	const [pushComment, setPushComment] = React.useState(
		userInfo.settings?.pushComment || false
	);
	const [pushTask, setPushTask] = React.useState(
		userInfo.settings?.pushTask || false
	);
	const [pushTaskDone, setPushTaskDone] = React.useState(
		userInfo.settings?.pushTaskDone || false
	);
	const [pushAssignPinboard, setPushAssignPinboard] = React.useState(
		userInfo.settings?.pushAssignPinboard || false
	);

	const [pushNewPinboardPost, setPushNewPinboardPost] = React.useState(
		userInfo.settings?.pushNewPinboardPost || false
	);

	const [emailGroup, setEmailGroup] = React.useState(
		userInfo.settings?.emailGroup || false
	);
	const [emailDirect, setEmailDirect] = React.useState(
		userInfo.settings?.emailDirect || false
	);
	const [emailComment, setEmailComment] = React.useState(
		userInfo.settings?.emailComment || false
	);
	const [emailTask, setEmailTask] = React.useState(
		userInfo.settings?.emailTask || false
	);
	const [emailTaskDone, setEmailTaskDone] = React.useState(
		userInfo.settings?.emailTaskDone || false
	);

	const [emailAssignPinboard, setEmailAssignPinboard] = React.useState(
		userInfo.settings?.emailAssignPinboard || false
	);

	const [emailNewPinboardPost, setEmailNewPinboardPost] = React.useState(
		userInfo.settings?.emailNewPinboardPost || false
	);

	useEffect(() => {
		updateSettings();
	}, [
		pushGroup,
		pushDirect,
		pushComment,
		pushTask,
		pushTaskDone,
		pushAssignPinboard,
		pushNewPinboardPost,
		emailGroup,
		emailDirect,
		emailComment,
		emailTask,
		emailTaskDone,
		emailAssignPinboard,
		emailNewPinboardPost,
	]);

	async function updateSettings() {
		try {
			let res = await axios.patch(
				process.env.API_URL + '/user/settings/' + userInfo._id,
				{
					pushGroup: pushGroup,
					pushDirect: pushDirect,
					pushComment: pushComment,
					pushTask: pushTask,
					pushTaskDone: pushTaskDone,
					pushAssignPinboard: pushAssignPinboard,
					pushNewPinboardPost: pushNewPinboardPost,
					emailGroup: emailGroup,
					emailDirect: emailDirect,
					emailComment: emailComment,
					emailTask: emailTask,
					emailTaskDone: emailTaskDone,
					emailAssignPinboard: emailAssignPinboard,
					emailNewPinboardPost: emailNewPinboardPost,
				},
				{
					headers: {
						Authorization: 'Bearer ' + token,
					},
				}
			);

			dispatch(updateUserData(res.data.userData));

			if (Platform.OS !== 'web') {
				let isLoginPersist = await SecureStore.getItemAsync('user');
				if (isLoginPersist) {
					await SecureStore.setItemAsync(
						'user',
						JSON.stringify(res.data.userData)
					);
				}
			}

			console.log(res.data.userData);
		} catch (error) {
			if (typeof error.response !== 'undefined') {
				addHeNotification({
					title: 'Einstellungen',
					message: error.response.data.message,
					type: 'error',
					duration: 5000,
				});
				console.log(error.response.data);
			} else {
				addHeNotification({
					title: 'Einstellungen',
					message: 'Verbindung zum Server konnte nicht hergestellt werden',
					type: 'error',
					duration: 5000,
				});
				console.log(error);
			}
		}
	}

	return (
		<SafeAreaView
			edges={['top', 'bottom']}
			style={tw.style('bg-white flex-1 pb-5')}
		>
			<ScrollView
				contentContainerStyle={tw.style('  w-full max-w-[900px] mx-auto')}
				style={tw.style('flex-1 bg-white')}
			>
				<View style={tw.style('px-8 pt-6 mb-4')}>
					<Text style={tw.style('text-2xl font-bold')}>Einstellungen</Text>
				</View>
				<View style={tw.style('px-8 mb-8')}>
					<Text style={tw.style('text-xl font-bold mb-4')}>
						Push-Benachrichtigung, bei...
					</Text>
					<View style={tw.style('flex-row justify-between mb-4')}>
						<Text>Gruppennachrichten</Text>
						<Checkbox
							value={pushGroup}
							onValueChange={setPushGroup}
							color={tw.color('primary')}
						/>
					</View>
					<View style={tw.style('flex-row justify-between mb-4')}>
						<Text>Direktnachrichten</Text>
						<Checkbox
							value={pushDirect}
							onValueChange={setPushDirect}
							color={tw.color('primary')}
						/>
					</View>
					<View style={tw.style('flex-row justify-between mb-4')}>
						<Text>Zuweisung zu einer Pinnwand</Text>
						<Checkbox
							value={pushAssignPinboard}
							onValueChange={setPushAssignPinboard}
							color={tw.color('primary')}
						/>
					</View>
					<View style={tw.style('flex-row justify-between mb-4')}>
						<Text>Neuer Post in einer Pinnwand</Text>
						<Checkbox
							value={pushNewPinboardPost}
							onValueChange={setPushNewPinboardPost}
							color={tw.color('primary')}
						/>
					</View>
					<View style={tw.style('flex-row justify-between mb-4')}>
						<Text>Kommentaren in einem Post</Text>
						<Checkbox
							value={pushComment}
							onValueChange={setPushComment}
							color={tw.color('primary')}
						/>
					</View>
					<View style={tw.style('flex-row justify-between mb-4')}>
						<Text>Zuweisung von Aufgaben</Text>
						<Checkbox
							value={pushTask}
							onValueChange={setPushTask}
							color={tw.color('primary')}
						/>
					</View>
					<View style={tw.style('flex-row justify-between mb-4')}>
						<Text>Erledigung von Aufgaben</Text>
						<Checkbox
							value={pushTaskDone}
							onValueChange={setPushTaskDone}
							color={tw.color('primary')}
						/>
					</View>
				</View>

				<View style={tw.style('px-6 mb-8')}>
					<Text
						style={tw.style(
							'text-xl font-bold ' +
								(hasValidMail ? 'mb-4 text-black' : 'text-gray-500')
						)}
					>
						Email-Benachrichtigung, bei...
					</Text>
					{!hasValidMail && (
						<Text style={tw.style('text-sm mb-4 text-gray-500')}>
							diese option ist nur verfügbar, wenn sie eine email adresse
							hinterlegt haben
						</Text>
					)}
					<View style={tw.style('flex-row justify-between mb-4')}>
						<Text>Gruppennachrichten</Text>
						<Checkbox
							value={emailGroup && hasValidMail}
							onValueChange={setEmailGroup}
							color={tw.color('primary')}
							disabled={!hasValidMail}
						/>
					</View>
					<View style={tw.style('flex-row justify-between mb-4')}>
						<Text>Direktnachrichten</Text>
						<Checkbox
							value={emailDirect && hasValidMail}
							onValueChange={setEmailDirect}
							color={tw.color('primary')}
							disabled={!hasValidMail}
						/>
					</View>
					<View style={tw.style('flex-row justify-between mb-4')}>
						<Text>Zuweisung zu einer Pinnwand</Text>
						<Checkbox
							value={emailAssignPinboard && hasValidMail}
							onValueChange={setEmailAssignPinboard}
							color={tw.color('primary')}
							disabled={!hasValidMail}
						/>
					</View>
					<View style={tw.style('flex-row justify-between mb-4')}>
						<Text>Neuer Post in einer Pinnwand</Text>
						<Checkbox
							value={emailNewPinboardPost && hasValidMail}
							onValueChange={setEmailNewPinboardPost}
							color={tw.color('primary')}
							disabled={!hasValidMail}
						/>
					</View>
					<View style={tw.style('flex-row justify-between mb-4')}>
						<Text>Kommentaren in einem Post</Text>
						<Checkbox
							value={emailComment && hasValidMail}
							onValueChange={setEmailComment}
							color={tw.color('primary')}
							disabled={!hasValidMail}
						/>
					</View>
					<View style={tw.style('flex-row justify-between mb-4')}>
						<Text>Zuweisung von Aufgaben</Text>
						<Checkbox
							value={emailTask && hasValidMail}
							onValueChange={setEmailTask}
							color={tw.color('primary')}
							disabled={!hasValidMail}
						/>
					</View>
					<View style={tw.style('flex-row justify-between mb-4')}>
						<Text>Erledigung von Aufgaben</Text>
						<Checkbox
							value={emailTaskDone && hasValidMail}
							onValueChange={setEmailTaskDone}
							color={tw.color('primary')}
							disabled={!hasValidMail}
						/>
					</View>
				</View>
				<View style={tw.style('px-6 mb-8')}>
					<Text style={tw.style('text-xl font-bold mb-4 text-black')}>
						Rechtliches
					</Text>
					<TouchableOpacity
						onPress={() => {
							WebBrowser.openBrowserAsync(
								'https://pyng-app.de/datenschutzerklaerung/'
							);
						}}
					>
						<Text style={tw.style('underline text-base mb-2')}>
							Datenschutzerklärung
						</Text>
					</TouchableOpacity>
					<TouchableOpacity
						onPress={() => {
							WebBrowser.openBrowserAsync('https://pyng-app.de/impressum/');
						}}
					>
						<Text style={tw.style('underline text-base mb-2')}>Impressum</Text>
					</TouchableOpacity>
					<TouchableOpacity
						onPress={() => {
							WebBrowser.openBrowserAsync('https://pyng-app.de/agb/');
						}}
					>
						<Text style={tw.style('underline text-base mb-2')}>AGB</Text>
					</TouchableOpacity>
					{/* <TouchableOpacity
						onPress={() => {
							WebBrowser.openBrowserAsync(
								'https://www.nvii-media.com/widerruf'
							);
						}}
					>
						<Text style={tw.style('underline text-base mb-2')}>Widerruf</Text>
					</TouchableOpacity> */}
				</View>
			</ScrollView>
		</SafeAreaView>
	);
}
