import {View, Text, ScrollView, TouchableOpacity, Image} from 'react-native';
import React from 'react';
import tw from '../../../lib/tailwind';
import {useNavigation} from '@react-navigation/native';
import {useDispatch, useSelector} from 'react-redux';
import {addHeNotification} from '../../../lib/HeNotifications/HeNotificationList';
import {updateTodos} from '../../../redux/slices/appDataSlice';
import axios from 'axios';
import {useEffect} from 'react';
import {Swipeable} from 'react-native-gesture-handler';
import CheckIcon from '../../../assets/icons/todo-active.png';
import DeleteIcon from '../../../assets/icons/close.png';
import {decrypt} from '../../../lib/eTe';
import dateFormat from 'dateformat';
import alert from '../../../lib/alert';

export default function Archive() {
	const navigation = useNavigation();

	const todos = useSelector((state) => state.appData.todos);

	const userInfo = useSelector((state) => state.appData.userData);

	const token = useSelector((state) => state.appData.token);

	const dispatch = useDispatch();

	useEffect(() => {
		getTodos();

		navigation.addListener('focus', () => {
			getTodos();
		});

		return () => {
			navigation.removeListener('focus');
		};
	}, []);

	async function getTodos() {
		try {
			let res = await axios.get(
				process.env.API_URL + '/' + userInfo.workspace + '/todo',
				{
					headers: {
						Authorization: 'Bearer ' + token,
					},
				}
			);

			dispatch(updateTodos(res.data.todos));
		} catch (error) {
			console.log(error);
		}
	}

	async function deleteTodo(id) {
		try {
			let response = await axios.delete(
				process.env.API_URL + '/' + userInfo.workspace + '/todo/' + id,
				{
					headers: {
						Authorization: 'Bearer ' + token,
					},
				}
			);

			dispatch(updateTodos(response.data.todos));

			addHeNotification({
				title: 'Aufgaben',
				message: response.data.message,
				type: 'success',
				duration: 5000,
			});
		} catch (error) {
			if (typeof error.response !== 'undefined') {
				addHeNotification({
					title: 'Aufgaben',
					message: error.response.data.message,
					type: 'error',
					duration: 5000,
				});
				console.log(error.response.data);
			} else {
				addHeNotification({
					title: 'Aufgaben',
					message: 'Verbindung zum Server konnte nicht hergestellt werden',
					type: 'error',
					duration: 5000,
				});
				console.log(error.response);
			}
		}
	}

	async function updateTodoStatus(todo) {
		try {
			let response = await axios.put(
				process.env.API_URL + '/' + userInfo.workspace + '/todo/' + todo._id,
				{
					...todo,
					status: todo.status == 'open' ? 'closed' : 'open',
				},
				{
					headers: {
						Authorization: 'Bearer ' + token,
					},
				}
			);

			dispatch(updateTodos(response.data.todos));

			addHeNotification({
				title: 'Aufgaben',
				message: response.data.message,
				type: 'success',
				duration: 5000,
			});
		} catch (error) {
			if (typeof error.response !== 'undefined') {
				addHeNotification({
					title: 'Aufgaben',
					message: error.response.data.message,
					type: 'error',
					duration: 5000,
				});
				console.log(error.response.data);
			} else {
				addHeNotification({
					title: 'Aufgaben',
					message: 'Verbindung zum Server konnte nicht hergestellt werden',
					type: 'error',
					duration: 5000,
				});
				console.log(error.response);
			}
		}
	}

	return (
		<View style={tw.style('flex-1 bg-white')}>
			<ScrollView
				contentContainerStyle={tw.style('w-full max-w-[900px] mx-auto')}
			>
				<Text style={tw.style('text-primary text-4xl font-semibold py-6 px-8')}>
					Archiv
				</Text>
				<View style={tw.style('flex-1')}>
					{todos.filter((todo) => todo.status != 'open').length > 0 ? (
						<View style={tw.style('flex-1')}>
							{todos
								.filter((todo) => todo.status != 'open')
								.map((todo) => (
									<Swipeable
										enabled
										renderRightActions={(progress, dragX) => {
											return (
												<TouchableOpacity
													activeOpacity={0.8}
													style={tw.style(
														'bg-accent w-1/3 flex items-center justify-center'
													)}
													onPress={() => {
														updateTodoStatus(todo);
													}}
												>
													<Image
														source={CheckIcon}
														style={tw.style('h-6 w-6 tint-white')}
														resizeMode="contain"
													/>
													<Text style={tw.style('text-white mt-2')}>
														Wiedereröffnen
													</Text>
												</TouchableOpacity>
											);
										}}
										key={todo._id}
										renderLeftActions={(progress, dragX) => {
											return (
												<View
													style={tw.style(
														(todo.createdBy == userInfo._id
															? 'w-1/2'
															: 'w-1/4') + ' h-full flex flex-row'
													)}
												>
													<TouchableOpacity
														activeOpacity={0.8}
														style={tw.style(
															(todo.createdBy == userInfo._id
																? 'w-1/2'
																: 'w-full') +
																' bg-yellow-500 flex items-center justify-center'
														)}
														onPress={() => {
															navigation.navigate('addTodo', {
																todo,
															});
														}}
													>
														<Image
															source={require('../../../assets/icons/settings.png')}
															style={tw.style('h-6 w-6 tint-white')}
															resizeMode="contain"
														/>
														<Text style={tw.style('text-white mt-2')}>
															Bearbeiten
														</Text>
													</TouchableOpacity>

													{todo.createdBy == userInfo._id && (
														<TouchableOpacity
															activeOpacity={0.8}
															style={tw.style(
																'bg-red-500 w-1/2 flex items-center justify-center'
															)}
															onPress={() => {
																alert(
																	'Löschen',
																	'Soll diese Aufgabe wirklich gelöscht werden? Diese Aktion kann nicht rückgängig gemacht werden.',
																	[
																		{
																			text: 'Abbrechen',
																			style: 'cancel',
																		},
																		{
																			text: 'Löschen',
																			onPress: () => {
																				deleteTodo(todo._id);
																			},
																			style: 'destructive',
																		},
																	]
																);
															}}
														>
															<Image
																source={require('../../../assets/icons/delete-default.png')}
																style={[tw.style('h-6 w-6 tint-white')]}
																resizeMode="contain"
															/>
															<Text style={tw.style('text-white mt-2')}>
																Löschen
															</Text>
														</TouchableOpacity>
													)}
												</View>
											);
										}}
										containerStyle={tw.style('border-b border-gray-200')}
									>
										<TouchableOpacity
											onPress={() => navigation.navigate('TodoDetail', {todo})}
											style={tw.style(
												'flex-row items-center justify-between bg-white py-6 px-8'
											)}
										>
											<View
												style={tw.style(
													'flex flex-row justify-between items-center flex-1'
												)}
											>
												<View style={tw.style('w-4/5')}>
													<Text
														style={tw.style(
															'text-gray-600 text-xl font-semibold'
														)}
														ellipsizeMode="tail"
													>
														{decrypt(todo.title, userInfo.workspace)}
													</Text>
													<Text
														style={tw.style(
															'text-gray-400 text-sm font-medium'
														)}
														numberOfLines={2}
													>
														{decrypt(todo.description, userInfo.workspace)
															? decrypt(todo.description, userInfo.workspace)
															: 'Keine Beschreibung'}
													</Text>
												</View>
												<Text style={tw.style('text-gray-400 text-sm')}>
													{todo.date
														? dateFormat(todo.date, 'dd.mmm')
														: 'Kein Datum'}
												</Text>
											</View>
										</TouchableOpacity>
									</Swipeable>
								))}
						</View>
					) : (
						<Text
							style={tw.style('text-gray-400 text-lg font-semibold py-6 px-8')}
						>
							Keine Aufgaben vorhanden
						</Text>
					)}
				</View>
			</ScrollView>
		</View>
	);
}
