import {
	View,
	Text,
	TouchableWithoutFeedback,
	Keyboard,
	TextInput,
	TouchableOpacity,
	Image,
	FlatList,
	Platform,
	ActivityIndicator,
	KeyboardAvoidingView,
} from 'react-native';
import React, {useEffect} from 'react';
import tw from '../lib/tailwind';
import User from '../assets/icons/user.png';
import Calender from '../assets/icons/calender.png';
import CloseIcon from '../assets/icons/close.png';
import CheckIcon from '../assets/icons/todo-active.png';

import dateFormat from 'dateformat';
import {useNavigation} from '@react-navigation/native';
import {BottomSheetModal} from '@gorhom/bottom-sheet';
import {useDispatch, useSelector} from 'react-redux';

import DateTimePicker from '@react-native-community/datetimepicker';
import socket from '../lib/socket';
import {updateAllUsers, updatePinboards} from '../redux/slices/appDataSlice';
import {addHeNotification} from '../lib/HeNotifications/HeNotificationList';
import {useHeaderHeight} from '@react-navigation/elements';
import axios from 'axios';

import * as ImagePicker from 'expo-image-picker';
import {decrypt, encrypt} from '../lib/eTe';
import {KeyboardAwareScrollView} from 'react-native-keyboard-aware-scroll-view';
import {ResizeMode, Video} from 'expo-av';
import * as DocumentPicker from 'expo-document-picker';

export default function AddPost({route}) {
	const {pinboardID, post} = route.params;

	const navigation = useNavigation();

	const headerHeight = useHeaderHeight();

	const userInfo = useSelector((state) => state.appData.userData);
	const token = useSelector((state) => state.appData.token);

	const [image, setImage] = React.useState();
	const [uploadUri, setUploadUri] = React.useState(
		post ? post.headerImage : ''
	);
	const [description, setDescription] = React.useState(
		post ? decrypt(post?.description, pinboardID) : ''
	);

	const [uploadImageLoading, setUploadImageLoading] = React.useState(false);

	async function selectImage() {
		const {status} = await ImagePicker.requestMediaLibraryPermissionsAsync();
		if (status !== 'granted') {
			alert('Sorry, we need camera roll permissions to make this work!');
		} else {
			let result = await ImagePicker.launchImageLibraryAsync({
				mediaTypes: ImagePicker.MediaTypeOptions.All,
				quality: 1,
			});

			if (!result.canceled) {
				setImage(result.assets[0]);
				uploadImage(result.assets[0]);
			}
		}
	}

	async function openFilePicker() {
		let result = await DocumentPicker.getDocumentAsync({
			copyToCacheDirectory: true,
			multiple: false,
		});

		if (!result.canceled) {
			setImage(result.assets[0]);
			uploadImage(result.assets[0]);
		}
	}

	function dataURLtoFile(dataurl, filename) {
		var arr = dataurl.split(','),
			mime = arr[0].match(/:(.*?);/)[1],
			bstr = atob(arr[1]),
			n = bstr.length,
			u8arr = new Uint8Array(n);

		while (n--) {
			u8arr[n] = bstr.charCodeAt(n);
		}

		if (!filename) {
			// get file extension from base64
			let extension = mime.split('/')[1];
			// create filename
			filename = 'file.' + extension;
		}
		return new File([u8arr], filename, {type: mime});
	}

	function getFileType(image) {
		if (!image) return;
		const FileType = {
			FILE: 'file',
			VIDEO: 'video',
			IMAGE: 'image',
		};

		const imageExtensions = [
			'jpg',
			'jpeg',
			'png',
			'gif',
			'bmp',
			'webp',
			'tiff',
			'psd',
			'svg',
			'heif',
			'image',
		];
		const videoExtensions = [
			'mp4',
			'mov',
			'avi',
			'flv',
			'wmv',
			'm4v',
			'mkv',
			'webm',
			'mpg',
			'mpeg',
			'3gp',
			'video',
		];

		if (Platform.OS == 'web') {
			let extension =
				image.mimeType?.split('/')[1]?.toLowerCase() ||
				image.uri.split('.').pop().toLowerCase();
			if (imageExtensions.includes(extension)) {
				return FileType.IMAGE;
			} else if (videoExtensions.includes(extension)) {
				return FileType.VIDEO;
			} else {
				return FileType.FILE;
			}
		}

		let extension = image.uri.split('.').pop().toLowerCase();
		console.log('extension', extension);

		if (imageExtensions.includes(extension)) {
			return FileType.IMAGE;
		} else if (videoExtensions.includes(extension)) {
			return FileType.VIDEO;
		} else {
			return FileType.FILE;
		}
	}

	function checkForRightFileType(file) {
		if (!file) return false;

		const AllowedFileTypes = [
			'jpg',
			'jpeg',
			'png',
			'gif',
			'heif',
			'bmp',
			'tiff',
			'svg',
			'mp4',
			'avi',
			'mov',
			'wmv',
			'flv',
			'mkv',
			'pdf',
			'txt',
			'md',
			'rtf',
			'odt',
			'doc',
			'docx',
			'xls',
			'xlsx',
			'ppt',
			'pptx',
			'mp3',
			'wav',
			'aac',
			'flac',
			'ogg',
			'm4a',
		];

		if (Platform.OS == 'web') {
			let extension =
				file.mimeType?.split('/')[1]?.toLowerCase() ||
				file.uri.split('/')[1].split(';')[0].toLowerCase() ||
				file.uri.split('.')?.pop()?.toLowerCase();
			return AllowedFileTypes.includes(extension);
		}

		let extension = file.uri.split('.').pop().toLowerCase();

		return AllowedFileTypes.includes(extension);
	}

	async function uploadImage(paramImage) {
		let upImg = paramImage || image;

		if (upImg.fileSize > 75000000 || upImg.size > 75000000) {
			addHeNotification({
				title: 'Medien hochladen',
				message:
					'Die Datei ist zu groß. Bitte wähle eine Datei aus, die kleiner als 75MB ist.',
				type: 'error',
				duration: 5000,
			});
			setUploadImageLoading(false);
			setImage(null);
			return;
		}

		if (!checkForRightFileType(upImg)) {
			addHeNotification({
				title: 'Medien hochladen',
				message:
					'Die Datei hat ein nicht erlaubtes Format. Bitte wähle eine Datei aus, die ein erlaubtes Format hat.',
				type: 'error',
				duration: 5000,
			});
			console.log(upImg);
			setUploadImageLoading(false);
			setImage(null);
			return;
		}

		try {
			setUploadImageLoading(true);
			const data = new FormData();
			data.append(
				'file',
				Platform.OS != 'web'
					? {
							uri: upImg.uri,
							type: upImg.mimeType
								? upImg.mimeType
								: `${upImg.type}/${upImg.uri
										.split('.')
										.pop()
										.toLowerCase()
										.replace('jpeg', 'jpg')}`,
							name: upImg.uri.split('/').pop(),
					  }
					: upImg.webkitRelativePath == ''
					? upImg
					: dataURLtoFile(upImg.uri, upImg.name)
			);

			console.log(data);

			const response = await axios.post(
				process.env.API_URL + '/' + userInfo.workspace + '/pinboard/upload',
				data,
				{
					headers: {
						Authorization: 'Bearer ' + token,
						'Content-Type': 'multipart/form-data',
					},
				}
			);

			setUploadUri(response.data.url);
			console.log(
				process.env.API_URL + '/' + response.data.url + '?token=' + token,
				getFileType(image)
			);
			setUploadImageLoading(false);
			return response.data.url;
		} catch (error) {
			console.log('Error', error);
			setUploadImageLoading(false);
		}
	}

	async function updatePost() {
		if (!uploadUri && image?.uri && description === '') {
			addHeNotification({
				title: 'Post',
				message: 'Bitte gib eine Beschreibung ein oder wähle ein Bild aus',
				type: 'error',
				duration: 2500,
			});
			return;
		}

		try {
			const response = await axios.put(
				process.env.API_URL +
					'/' +
					userInfo.workspace +
					'/pinboard/' +
					pinboardID +
					'/posts/' +
					post._id,
				{
					headerImage: image?.uri ? await uploadImage() : uploadUri,
					type: getFileType({uri: uploadUri} || image),
					description: encrypt(description, pinboardID),
				},
				{
					headers: {
						Authorization: 'Bearer ' + token,
					},
				}
			);

			//dispatch(updatePinboards(response.data.pinboards));

			navigation.goBack();

			addHeNotification({
				title: 'Post',
				message: response.data.message,
				type: 'success',
				duration: 5000,
			});
		} catch (error) {
			if (typeof error.response !== 'undefined') {
				addHeNotification({
					title: 'Post',
					message: error.response.data.message,
					type: 'error',
					duration: 5000,
				});
				console.log(error.response.data);
			} else {
				addHeNotification({
					title: 'Post',
					message: 'Verbindung zum Server konnte nicht hergestellt werden',
					type: 'error',
					duration: 5000,
				});
				console.log(error);
			}
		}
	}

	async function createPost() {
		if (
			uploadUri == '' &&
			image?.uri == undefined &&
			description.trim() == ''
		) {
			console.log('UploadUri: ' + uploadUri);
			console.log('Description: ' + description);
			addHeNotification({
				title: 'Post',
				message: 'Bitte schreibe eine Beschreibung oder wähle ein Bild aus',
				type: 'error',
				duration: 2500,
			});
			return;
		}
		try {
			const response = await axios.post(
				process.env.API_URL +
					'/' +
					userInfo.workspace +
					'/pinboard/' +
					pinboardID +
					'/posts',
				{
					headerImage:
						image?.uri && !uploadUri ? await uploadImage() : uploadUri,
					description: encrypt(description, pinboardID),
					type: getFileType({uri: uploadUri} || image),
				},
				{
					headers: {
						Authorization: 'Bearer ' + token,
					},
				}
			);

			//dispatch(updatePinboards(response.data.pinboards));

			navigation.goBack();

			addHeNotification({
				title: 'Post',
				message: response.data.message,
				type: 'success',
				duration: 5000,
			});
		} catch (error) {
			if (typeof error.response !== 'undefined') {
				console.log(error.response.data);
			} else {
				addHeNotification({
					title: 'Post',
					message: 'Verbindung zum Server konnte nicht hergestellt werden',
					type: 'error',
					duration: 5000,
				});
				console.log(error);
			}
		}
	}

	return (
		<KeyboardAvoidingView
			behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
			keyboardVerticalOffset={headerHeight}
			style={tw.style('flex-1 bg-white')}
		>
			<KeyboardAwareScrollView
				style={tw.style('flex-1 bg-white')}
				contentContainerStyle={tw.style('w-full max-w-[900px] mx-auto')}
				enableAutomaticScroll
				enableOnAndroid
				extraScrollHeight={headerHeight}
			>
				<TouchableWithoutFeedback onPress={() => {}}>
					<View style={tw.style('flex-1 bg-white max-w-[900px]')}>
						<TouchableOpacity onPress={() => selectImage()}>
							<View
								style={tw.style(
									'w-full aspect-video max-h-[80vh] mx-auto bg-gray-400 mt-8 flex items-center justify-center'
								)}
							>
								{uploadUri &&
									!uploadImageLoading &&
									getFileType({uri: uploadUri}) == 'image' && (
										<Image
											source={{
												uri:
													process.env.API_URL +
													'/' +
													uploadUri +
													'?token=' +
													token,
											}}
											style={tw.style('flex-1 w-full h-64 ')}
											resizeMode={Platform.OS == 'web' ? 'contain' : 'contain'}
										/>
									)}
								{uploadUri &&
									!uploadImageLoading &&
									getFileType({uri: uploadUri}) == 'video' && (
										<Video
											resizeMode={ResizeMode.CONTAIN}
											source={{
												uri:
													process.env.API_URL +
													'/' +
													uploadUri +
													'?token=' +
													token,
											}}
											style={tw.style('flex-1 w-full h-full')}
											useNativeControls
											posterStyle={tw.style('w-full aspect-video')}
											videoStyle={tw.style('w-full aspect-video')}
										/>
									)}
								{uploadUri &&
									!uploadImageLoading &&
									getFileType({uri: uploadUri}) == 'file' && (
										<Text
											style={tw.style(
												'text-white w-2/3 h-full text-center mt-10'
											)}
											numberOfLines={1}
											lineBreakMode="tail"
										>
											Hochgeladen:{' '}
											{image
												? image?.name || image?.uri?.split('/').pop()
												: uploadUri?.split('/').pop()}
										</Text>
									)}
								{!uploadUri && !image?.uri && (
									<Text style={tw.style('text-white')}>
										Kein Bild ausgewählt. Klicke hier um ein Bild auszuwählen.
									</Text>
								)}

								{uploadImageLoading ? (
									<ActivityIndicator size="large" color="#fff" />
								) : null}
							</View>
						</TouchableOpacity>

						<TouchableOpacity
							style={tw.style('px-8 py-6 flex flex-row items-center')}
							onPress={() => {
								openFilePicker();
							}}
						>
							<Image
								source={require('../assets/icons/Klammer.png')}
								style={tw.style('h-6 w-5 mr-4')}
								resizeMode="contain"
							/>
							<Text style={tw.style('text-base text-gray-400')}>
								oder Datei auswählen
							</Text>
						</TouchableOpacity>

						<View style={tw.style('flex-1 bg-white p-8 flex justify-between')}>
							<View>
								<Text style={tw.style('text-accent text-2xl font-bold')}>
									{post ? '' : 'neuer'} Post {post ? 'bearbeiten' : ''}
								</Text>
								<TextInput
									multiline
									style={tw.style(
										'text-base leading-tight py-3 border-b border-gray-300 mt-8'
									)}
									placeholder={'Beschreibung'}
									onChangeText={setDescription}
									value={description}
									numberOfLines={4}
								/>
							</View>

							<View style={tw.style('flex flex-row justify-end mt-4')}>
								<TouchableOpacity
									style={tw.style('bg-accent p-4 px-6 rounded-full')}
									onPress={() => {
										if (uploadImageLoading) {
											addHeNotification({
												title: 'Post',
												message:
													'Bitte warte bis wir das Bild hochgeladen haben.',
												type: 'error',
												duration: 2500,
											});
											return;
										}

										if (post) {
											updatePost();
										} else {
											createPost();
										}
									}}
								>
									<Text style={tw.style('font-semibold text-base text-white')}>
										{post ? 'Speichern' : 'Erstellen'}
									</Text>
								</TouchableOpacity>
							</View>
						</View>
					</View>
				</TouchableWithoutFeedback>
			</KeyboardAwareScrollView>
		</KeyboardAvoidingView>
	);
}
