import {
	View,
	Text,
	TextInput,
	TouchableOpacity,
	Image,
	Alert,
	Keyboard,
	TouchableWithoutFeedback,
	FlatList,
	ScrollView,
	Platform,
} from 'react-native';
import React, {useEffect} from 'react';
import tw from '../../../lib/tailwind';
import QRCode from 'react-native-qrcode-svg';
import {useDispatch, useSelector} from 'react-redux';
import axios from 'axios';
import {addHeNotification} from '../../../lib/HeNotifications/HeNotificationList';
import socket from '../../../lib/socket';
import {
	updateAllUsers,
	updateToken,
	updateUserData,
	updateWorkspaceData,
} from '../../../redux/slices/appDataSlice';

import CloseIcon from '../../../assets/icons/delete-default.png';
import {BottomSheetModal, BottomSheetTextInput} from '@gorhom/bottom-sheet';
import User from '../../../assets/icons/user.png';

import VisibiltyIcon from '../../../assets/icons/visibility.png';
import VisibiltyOffIcon from '../../../assets/icons/visibility-off.png';

import CheckIcon from '../../../assets/icons/todo-active.png';

import {useNavigation} from '@react-navigation/native';
import * as Linking from 'expo-linking';
import * as SecureStore from 'expo-secure-store';
import alert from '../../../lib/alert';

export default function WorkspaceInfo() {
	const workspaceData = useSelector((state) => state.appData.workspaceData);

	const token = useSelector((state) => state.appData.token);

	const allUsers = useSelector((state) => state.appData.allUsers);

	const prefix = Linking.createURL('/');

	const dispatch = useDispatch();

	const navigation = useNavigation();

	const alertPolyfill = (title, description, options, extra) => {
		const result = window.confirm(
			[title, description].filter(Boolean).join('\n')
		);

		if (result) {
			const confirmOption = options.find(({style}) => style !== 'cancel');
			confirmOption && confirmOption.onPress();
		} else {
			const cancelOption = options.find(({style}) => style === 'cancel');
			cancelOption && cancelOption.onPress();
		}
	};

	const workspaceNameRef = React.useRef();
	const [workspaceName, setWorkspaceName] = React.useState(workspaceData.name);
	const [editWorkspaceName, setEditWorkspaceName] = React.useState(false);

	const [email, setEmail] = React.useState('');
	const [emailError, setEmailError] = React.useState(false);
	const [password, setPassword] = React.useState('');
	const [passwordError, setPasswordError] = React.useState(false);

	const [passwordVisible, setPasswordVisible] = React.useState(false);

	const userData = useSelector((state) => state.appData.userData);

	const sheetRef = React.useRef(null);
	const roleSheetRef = React.useRef(null);

	const [selectedUser, setSelectedUser] = React.useState(null);

	const handlePresentModalPress = React.useCallback(() => {
		sheetRef.current?.present();
	}, []);

	const handleDismissModalPress = React.useCallback(() => {
		sheetRef.current?.close();
	}, []);

	useEffect(() => {
		socket.on('allUser', (users) => {
			handleDismissModalPress();
			dispatch(updateAllUsers(users));

			// find user where user._id == userData._id
			let me = users.find((user) => user._id == userData._id);

			dispatch(
				updateUserData({
					...userData,
					role: me.role,
				})
			);
		});

		return () => {
			socket.off('allUser');
		};
	}, [userData]);

	useEffect(() => {
		navigation.addListener('focus', () => {
			socket.emit('getUsers', {
				token,
				workspace: userData.workspace,
				userID: userData._id,
			});
		});

		return () => {
			navigation.removeListener('focus');
		};
	}, []);

	async function saveChangedWorkspaceName() {
		try {
			let res = await axios.patch(
				process.env.API_URL + `/workspace/${workspaceData._id}`,
				{
					workspaceName: workspaceName,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			);

			console.log(res.data);

			dispatch(updateWorkspaceData(res.data.workspace));
			setWorkspaceName(res.data.workspace.name);

			addHeNotification({
				title: 'Organisation aktualiesieren',
				message: res.data.message,
				type: 'success',
				duration: 5000,
			});
		} catch (error) {
			setWorkspaceName(workspaceData.name);

			if (typeof error.response !== 'undefined') {
				addHeNotification({
					title: 'Organisation aktualiesieren',
					message: error.response.data.message,
					type: 'error',
					duration: 5000,
				});
				console.log(error.response.data);
			} else {
				addHeNotification({
					title: 'Organisation aktualiesieren',
					message: 'Verbindung zum Server konnte nicht hergestellt werden',
					type: 'error',
					duration: 5000,
				});
				console.log(error);
			}
		}
	}

	async function updateRole(userID, role) {
		try {
			let res = await axios.patch(
				process.env.API_URL + `/user/role`,
				{
					userID,
					role: role,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			);

			dispatch(updateAllUsers(res.data.users));

			roleSheetRef.current?.close();

			addHeNotification({
				title: 'Rolle aktualiesieren',
				message: res.data.message,
				type: 'success',
				duration: 5000,
			});
		} catch (error) {
			if (typeof error.response !== 'undefined') {
				addHeNotification({
					title: 'Organisation aktualiesieren',
					message: error.response.data.message,
					type: 'error',
					duration: 5000,
				});
				console.log(error.response.data);
			} else {
				addHeNotification({
					title: 'Organisation aktualiesieren',
					message: 'Verbindung zum Server konnte nicht hergestellt werden',
					type: 'error',
					duration: 5000,
				});
				console.log(error);
			}
		}
	}

	async function removeUserFromWorkspace(user) {
		try {
			let res = await axios.delete(process.env.API_URL + `/user/${user}`, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});

			dispatch(updateAllUsers(res.data.users));

			addHeNotification({
				title: 'Benutzer entfernen',
				message: res.data.message,
				type: 'success',
				duration: 5000,
			});
		} catch (error) {
			if (typeof error.response !== 'undefined') {
				addHeNotification({
					title: 'Benutzer entfernen',
					message: error.response.data.message,
					type: 'error',
					duration: 5000,
				});
				console.log(error.response.data);
			} else {
				addHeNotification({
					title: 'Benutzer entfernen',
					message: 'Verbindung zum Server konnte nicht hergestellt werden',
					type: 'error',
					duration: 5000,
				});
				console.log(error);
			}
		}
	}

	async function inviteUser() {
		if (Platform.OS != 'web') {
			Keyboard.dismiss();
		}

		if (!email.match('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')) {
			setEmailError(true);
			return;
		}

		try {
			let res = await axios.post(
				process.env.API_URL + `/user/invite`,
				{
					email,
					workspaceID: workspaceData._id,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			);

			setEmail('');

			dispatch(updateAllUsers(res.data.users));

			console.log(res.data);

			handleDismissModalPress();

			addHeNotification({
				title: 'Benutzer einladen',
				message: res.data.message,
				type: 'success',
				duration: 5000,
			});
		} catch (error) {
			if (typeof error.response !== 'undefined') {
				addHeNotification({
					title: 'Benutzer erstellen',
					message: error.response.data.message,
					type: 'error',
					duration: 5000,
				});
				console.log(error.response.data);
			} else {
				addHeNotification({
					title: 'Benutzer erstellen',
					message: 'Verbindung zum Server konnte nicht hergestellt werden',
					type: 'error',
					duration: 5000,
				});
				console.log(error);
			}
		}
	}

	async function createUser() {
		if (Platform.OS != 'web') {
			Keyboard.dismiss();
		}

		if (email.trim() === '') {
			setEmailError(true);
			return;
		}

		if (password.trim() === '') {
			setPassword('');
			setPasswordError(true);
			return;
		}

		try {
			const response = await axios.post(
				process.env.API_URL + '/user/newUserForWorkspace',
				{
					username: email,
					password,
					workspaceID: workspaceData._id,
				}
			);

			dispatch(updateAllUsers(response.data.users));
			handleDismissModalPress();

			setEmail('');
			setPassword('');

			addHeNotification({
				title: 'Benutzer erstellen',
				message: response.data.message,
				type: 'success',
				duration: 5000,
			});
		} catch (error) {
			if (typeof error.response !== 'undefined') {
				addHeNotification({
					title: 'Benutzer erstellen',
					message: error.response.data.message,
					type: 'error',
					duration: 5000,
				});
				console.log(error.response.data);
			} else {
				addHeNotification({
					title: 'Benutzer erstellen',
					message: 'Verbindung zum Server konnte nicht hergestellt werden',
					type: 'error',
					duration: 5000,
				});
				console.log(error);
			}
		}
	}

	async function handleDeleteWorkspace() {
		try {
			const response = await axios.delete(
				`${process.env.API_URL}/workspace/${workspaceData._id}`,
				{
					headers: {
						Authorization: 'Bearer ' + token,
					},
				}
			);

			dispatch(updateToken(null));

			navigation.navigate('App');

			dispatch(updateUserData(null));

			if (Platform.OS === 'web') {
				localStorage.removeItem('token');
				localStorage.removeItem('user');
				localStorage.removeItem('workspace');
				localStorage.removeItem('lastMessages');
			} else {
				await SecureStore.deleteItemAsync('token');
				await SecureStore.deleteItemAsync('user');
				await SecureStore.deleteItemAsync('workspace');
				await SecureStore.deleteItemAsync('lastMessages');
			}

			console.log(response.data);

			addHeNotification({
				title: 'Organisation löschen',
				message: response.data.message,
				type: 'success',
				duration: 5000,
			});
		} catch (error) {
			if (typeof error.response !== 'undefined') {
				addHeNotification({
					title: 'Organisation löschen',
					message: error.response.data.message,
					type: 'error',
					duration: 5000,
				});
				console.log(error.response.data);
			} else {
				addHeNotification({
					title: 'Organisation löschen',
					message: 'Verbindung zum Server konnte nicht hergestellt werden',
					type: 'error',
					duration: 5000,
				});
				console.log(error);
			}
		}
	}

	return (
		<TouchableWithoutFeedback onPress={() => {}}>
			<ScrollView nestedScrollEnabled style={tw.style('flex-1 bg-white')}>
				<View
					style={tw.style('flex-1 bg-white p-8 w-full max-w-[900px] mx-auto')}
				>
					<View
						style={tw.style('p-6 shadow-lg self-center bg-white rounded-xl')}
					>
						<QRCode
							value={prefix + 'Register?code=' + workspaceData.accessCode}
							size={200}
							color="black"
							backgroundColor="white"
						/>
						<Text style={tw.style('font-bold text-2xl pt-4 text-center')}>
							{workspaceData.accessCode}
						</Text>
					</View>
					<Text style={tw.style('mt-2 font-light text-center text-gray-400')}>
						Teile diesen Code mit deinen Kollegen, um sie in deinen Workspace
						einzuladen.
					</Text>

					<View style={tw.style('mt-8')}>
						<View
							style={tw.style('flex flex-row items-center justify-between')}
						>
							<Text style={tw.style('text-gray-400 font-semibold text-sm')}>
								Organisationsname
							</Text>
							<TouchableOpacity
								onPress={() => {
									if (editWorkspaceName) {
										saveChangedWorkspaceName();
									}
									setEditWorkspaceName(!editWorkspaceName);
								}}
							>
								<Text style={tw.style('text-sm text-gray-600')}>
									{editWorkspaceName ? 'Speichern' : 'Bearbeiten'}
								</Text>
							</TouchableOpacity>
						</View>
						<TextInput
							ref={workspaceNameRef}
							value={workspaceName}
							onChangeText={(text) => {
								setWorkspaceName(text);
							}}
							style={tw.style(
								'text-lg font-bold pb-1 border-b leading-tight' +
									(editWorkspaceName
										? ' border-gray-300'
										: ' border-transparent')
							)}
							editable={editWorkspaceName}
						/>
					</View>

					<View style={tw.style('mt-8')}>
						<View
							style={tw.style('flex flex-row items-center justify-between')}
						>
							<Text style={tw.style('text-gray-400 font-semibold text-sm')}>
								Nutzer
							</Text>
							<TouchableOpacity
								onPress={() => {
									handlePresentModalPress();
								}}
							>
								<Text style={tw.style('text-sm text-gray-600')}>
									Hinzufügen
								</Text>
							</TouchableOpacity>
						</View>
						{allUsers && (
							<FlatList
								data={allUsers}
								renderItem={({item: user}) => (
									<TouchableOpacity
										onPress={() => {
											setSelectedUser(user);
											roleSheetRef.current.present();
										}}
									>
										<View
											key={user._id}
											style={tw.style(
												'flex flex-row items-center justify-between mt-2'
											)}
										>
											<View style={tw.style('flex flex-row items-center')}>
												<View style={tw.style('flex-row items-center mr-4')}>
													<Image
														source={
															user.extendedData?.avatar
																? {
																		uri:
																			process.env.API_URL +
																			'/' +
																			user.extendedData.avatar +
																			'?width=64&height=64&token=' +
																			token,
																  }
																: User
														}
														style={tw.style(
															'w-10 h-10 bg-[#F2F2F2] rounded-full '
														)}
														resizeMode={
															user.extendedData?.avatar ? 'cover' : 'contain'
														}
													/>
												</View>
												<View>
													<Text style={tw.style('ml-2 text-lg')}>
														{user.extendedData.firstName === ''
															? user.username
															: user.extendedData.firstName +
															  ' ' +
															  user.extendedData.lastName}
													</Text>
													<Text style={tw.style('ml-2 text-xs')}>
														{user.stillInvited
															? 'Einladung ausstehend'
															: user.role === 'admin'
															? 'Administrator'
															: 'Nutzer'}
													</Text>
												</View>
											</View>
											{user._id == userData._id ? (
												<Text style={tw.style('text-sm text-gray-600')}>
													Du
												</Text>
											) : (
												<TouchableOpacity
													onPress={() => {
														if (Platform.OS === 'web') {
															alertPolyfill(
																'Nutzer entfernen',
																`Möchtest du ${
																	user.extendedData.firstName === ''
																		? user.username
																		: user.extendedData.firstName +
																		  ' ' +
																		  user.extendedData.lastName
																} wirklich aus deinem Workspace entfernen?`,
																[
																	{
																		text: 'Entfernen',
																		onPress: () => {
																			removeUserFromWorkspace(user._id);
																		},
																		style: 'destructive',
																	},
																	{
																		text: 'Abbrechen',
																		style: 'cancel',
																		onPress: () => {},
																	},
																]
															);
														} else {
															alert(
																'Nutzer entfernen',
																`Möchtest du ${
																	user.extendedData.firstName === ''
																		? user.username
																		: user.extendedData.firstName +
																		  ' ' +
																		  user.extendedData.lastName
																} wirklich aus deinem Workspace entfernen?`,
																[
																	{
																		text: 'Entfernen',
																		onPress: () => {
																			removeUserFromWorkspace(user._id);
																		},
																		style: 'destructive',
																	},
																	{
																		text: 'Abbrechen',
																		style: 'cancel',
																	},
																]
															);
														}
													}}
												>
													<Image
														source={CloseIcon}
														style={tw.style('w-4 h-4')}
														resizeMode="contain"
													/>
												</TouchableOpacity>
											)}
										</View>
									</TouchableOpacity>
								)}
								keyExtractor={(item) => item._id}
							/>
						)}

						<TouchableOpacity
							onPress={() =>
								alert(
									'Workspace löschen',
									'Möchtest du deinen Workspace wirklich löschen?',
									[
										{
											text: 'Löschen',
											style: 'destructive',
											onPress: () => {
												handleDeleteWorkspace();
											},
										},
										{
											text: 'Abbrechen',
											style: 'cancel',
										},
									]
								)
							}
							style={tw.style('my-12')}
						>
							<Text style={tw.style('text-red-500 text-base text-center')}>
								Workspace löschen
							</Text>
						</TouchableOpacity>
					</View>
					<BottomSheetModal
						ref={roleSheetRef}
						snapPoints={['45%']}
						style={tw.style('shadow-lg')}
						index={0}
						backdropComponent={() => {
							return (
								<TouchableOpacity
									style={tw.style(
										'flex-1 absolute top-0 left-0 right-0 bottom-0'
									)}
									onPress={() => {
										roleSheetRef.current?.close();
									}}
								/>
							);
						}}
						enableContentPanningGesture={
							Platform.OS != 'android' && Platform.OS != 'web'
						}
						enableHandlePanningGesture={Platform.OS != 'web'}
					>
						<ScrollView
							style={tw.style('flex-1 bg-white')}
							contentContainerStyle={tw.style('w-full max-w-[900px] mx-auto')}
						>
							<View style={tw.style('p-6')}>
								<Text
									style={tw.style('text-primary text-4xl font-semibold mb-4')}
								>
									Nutzerdaten
								</Text>
								<View style={tw.style('flex')}>
									<View
										style={tw.style('flex flex-row items-center gap-4 mb-2')}
									>
										<Text style={tw.style('font-bold mr-4')}>Username:</Text>
										<Text>{selectedUser && selectedUser.username}</Text>
									</View>
									<View
										style={tw.style('flex flex-row items-center gap-4 mb-2')}
									>
										<Text style={tw.style('font-bold mr-4')}>E-Mail:</Text>
										<Text>
											{selectedUser &&
												(!selectedUser?.username.match(
													/^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
												)
													? !selectedUser?.extendedData.additionalEmail?.match(
															/^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
													  )
														? 'Keine E-Mail angegeben'
														: selectedUser.extendedData.additionalEmail
													: selectedUser.username)}
										</Text>
									</View>
									<View
										style={tw.style('flex flex-row items-center gap-4 mb-2')}
									>
										<Text style={tw.style('font-bold mr-4')}>Name:</Text>
										<Text>
											{selectedUser &&
											!selectedUser?.extendedData.firstName &&
											!selectedUser?.extendedData.lastName
												? 'Kein Name angegeben'
												: selectedUser?.extendedData.firstName +
												  ' ' +
												  selectedUser?.extendedData.lastName}
										</Text>
									</View>
								</View>
								<Text
									style={tw.style('text-primary text-4xl font-semibold mt-6')}
								>
									Rolle ändern
								</Text>
								<Text style={tw.style('text-gray-400 text-base mb-2')}>
									Ändere die Rolle des Nutzers
								</Text>

								<View style={tw.style('flex')}>
									<TouchableOpacity
										onPress={() => {
											updateRole(selectedUser._id, 'admin');
											roleSheetRef.current?.close();
										}}
										style={tw.style(
											'flex flex-row items-center justify-between w-full py-4'
										)}
									>
										<Text style={tw.style('text-lg font-bold')}>
											Administrator
										</Text>
										{selectedUser &&
											(selectedUser.role === 'admin' ? (
												<Image
													source={CheckIcon}
													style={tw.style('w-5 h-5')}
													resizeMode="contain"
												/>
											) : (
												<View style={tw.style('w-5 h-5')} />
											))}
									</TouchableOpacity>
									<TouchableOpacity
										onPress={() => {
											updateRole(selectedUser._id, 'user');
											roleSheetRef.current?.close();
										}}
										style={tw.style(
											'flex flex-row items-center justify-between w-full py-4'
										)}
									>
										<Text style={tw.style('text-lg font-bold')}>Nutzer</Text>
										{selectedUser &&
											(selectedUser.role === 'user' ? (
												<Image
													source={CheckIcon}
													style={tw.style('w-5 h-5')}
													resizeMode="contain"
												/>
											) : (
												<View style={tw.style('w-5 h-5')} />
											))}
									</TouchableOpacity>
								</View>
							</View>
						</ScrollView>
					</BottomSheetModal>

					<BottomSheetModal
						ref={sheetRef}
						snapPoints={['50%']}
						style={tw.style('shadow-lg')}
						index={0}
						backdropComponent={() => {
							return (
								<TouchableOpacity
									style={tw.style(
										'flex-1 absolute top-0 left-0 right-0 bottom-0'
									)}
									onPress={() => {
										sheetRef.current?.close();
									}}
								/>
							);
						}}
						enableContentPanningGesture={Platform.OS != 'android'}
						enableHandlePanningGesture={Platform.OS != 'web'}
					>
						<View
							style={tw.style(
								'flex-1 bg-white p-8 w-full max-w-[900px] mx-auto'
							)}
						>
							<Text style={tw.style('text-primary text-4xl font-semibold')}>
								Neuen Nutzer hinzufügen
							</Text>
							<Text style={tw.style('text-gray-400 text-base mb-6')}>
								Möchtest du einen Nutzer einladen, dann gib bei nutzernamen
								seine E-Mail an und klicke dann auf einladen.
							</Text>
							<View style={tw.style('w-full mt-6')}>
								<View
									style={tw.style(
										' w-full border-b border-opacity-35 pb-3' +
											(emailError ? ' border-red-500' : ' border-gray-400')
									)}
								>
									<BottomSheetTextInput
										keyboardType="default"
										placeholder="nutzername/e-mail-adresse"
										style={tw.style('text-lg text-gray-700 leading-tight')}
										onChangeText={(text) => {
											setEmail(text);
											setEmailError(false);
										}}
										value={email}
										placeholderTextColor={tw.color(
											emailError ? 'red-300' : 'gray-300'
										)}
										textContentType="username"
										spellCheck={false}
										autoCapitalize="none"
										clearButtonMode="while-editing"
									/>
								</View>
								<View
									style={tw.style(
										'w-full flex flex-row border-b border-opacity-35 pb-3 pt-6' +
											(passwordError ? ' border-red-500' : ' border-gray-400')
									)}
								>
									<BottomSheetTextInput
										keyboardType="default"
										secureTextEntry={!passwordVisible}
										placeholder="passwort"
										style={tw.style(
											'flex-1 leading-tight text-lg text-gray-700'
										)}
										onChangeText={(text) => {
											setPassword(text);
											setPasswordError(false);
										}}
										placeholderTextColor={tw.color(
											passwordError ? 'red-300' : 'gray-300'
										)}
										value={password}
										clearButtonMode="while-editing"
									/>
									<TouchableOpacity
										onPress={() => setPasswordVisible(!passwordVisible)}
										style={tw.style('justify-center items-center')}
									>
										<Image
											source={
												passwordVisible ? VisibiltyOffIcon : VisibiltyIcon
											}
										/>
									</TouchableOpacity>
								</View>
							</View>
							<View style={tw.style('flex flex-row justify-between')}>
								<TouchableOpacity
									activeOpacity={0.4}
									style={tw.style(' mt-14 py-3 self-end')}
									onPress={() => inviteUser()}
								>
									<Text style={tw.style('text-gray-700 text-lg')}>
										Nutzer einladen
									</Text>
								</TouchableOpacity>
								<TouchableOpacity
									activeOpacity={0.8}
									style={tw.style(
										' mt-14 rounded-full py-3 px-5 bg-accent self-end'
									)}
									onPress={() => createUser()}
								>
									<Text
										style={tw.style(
											'text-primary text-center text-xl font-bold'
										)}
									>
										Nutzer erstellen
									</Text>
								</TouchableOpacity>
							</View>
						</View>
					</BottomSheetModal>
				</View>
			</ScrollView>
		</TouchableWithoutFeedback>
	);
}
